import {Component, Input, OnInit} from '@angular/core';
import * as he from 'he';
import {ChangeTableValues} from "../../actions/tables.actions";
import {GRP} from "../../models/tables";
import {Attributes} from "../../models/attributes";
import {SeasonPage} from "../../pages/season/season.page";

@Component({
    selector: 'auto-adaptive-comfort',
    templateUrl: './auto-adaptive-comfort.component.html',
    styleUrls: ['./auto-adaptive-comfort.component.scss'],
})

export class AutoAdaptiveComfortComponent implements OnInit {

    @Input()
    grp: GRP;

    @Input()
    attrs: Attributes;

    @Input()
    seasonPage: SeasonPage;

    public minActivationH: number;
    public maxActivationH: number;
    public minActivationC: number;
    public maxActivationC: number;
    public minActivationOff: number;
    public maxActivationOff: number;

    constructor() {
    }

    ngOnInit() {

        this.minActivationH = this.attrs['GRP']['TEC_AacDiffH']['Min']
        this.maxActivationH = this.attrs['GRP']['TEC_AacDiffH']['Max']
        this.minActivationC = this.attrs['GRP']['TEC_AacDiffC']['Min']
        this.maxActivationC = this.attrs['GRP']['TEC_AacDiffC']['Max']
        this.minActivationOff = this.attrs['GRP']['TEC_AacDiffOff']['Min']
        this.maxActivationOff = this.attrs['GRP']['TEC_AacDiffOff']['Max']
    }

    async changeValue(attr) {

        const val = this.grp["" + attr + ""];
        const min = this.attrs["GRP"][attr]["Min"];
        const max = this.attrs["GRP"][attr]["Max"];
        let values = {};

        let popup = await this.seasonPage.alertController.create({
            header: this.seasonPage.translate.instant('CHANGE_VALUE_FOR') + " " + this.attrName(attr),
            message: this.seasonPage.translate.instant('RANGE_VALOR') + " [" + min + "," + max + "]",
            inputs: [{name: 'val', placeholder: val}],
            buttons: [

                {
                    text: this.seasonPage.translate.instant('CHANGE'),
                    handler: data => {
                        if (!data.val || data.val == "") {
                            this.presentToast(this.seasonPage.translate.instant('RANGE_ERROR'));
                            return false;
                        }
                        if (data.val < min || data.val > max) {
                            this.presentToast(this.seasonPage.translate.instant('RANGE_ERROR'));
                            return false;
                        }

                        values['' + attr + ''] = data.val
                        this.seasonPage.store.dispatch(new ChangeTableValues("GRP", this.grp.id, values))
                    }

                },
                {
                    text: this.seasonPage.translate.instant('CANCEL'),
                    role: 'cancel'
                }
            ]
        });
        await popup.present();
    }

    attrName(attr: string) {

        if (this.attrs) {

            if (this.attrs['GRP']) {

                const attrName = this.attrs['GRP'][attr]["Name"];
                const attrNameTranslated = this.seasonPage.translate.instant(attrName);
                return he.decode(attrNameTranslated);
            }

            return attr;
        }
    }

    /**
     * Error toast message
     */
    async presentToast(toastMessage: string, time?) {

        await this.seasonPage.presentToast(this.seasonPage.translate.instant(toastMessage))
    }
}
