import {Component, Input, OnInit} from '@angular/core';
import {GRP, MCU, RMS} from "../../models/tables";
import * as _ from 'lodash';
import {SeasonPage} from "../../pages/season/season.page";

@Component({
    selector: 'aac-zone-weights',
    templateUrl: './aac-zone-weights.component.html',
    styleUrls: ['./aac-zone-weights.component.scss'],
})
export class AacZoneWeightsComponent implements OnInit {

    @Input()
    RMS: RMS[] = [];

    @Input()
    MCU: MCU;

    @Input()
    grp: GRP;

    @Input()
    seasonPage: SeasonPage;

    public showRooms: boolean;
    public filteredRMS: RMS[] = []; // only the RMS for that particular are shown

    constructor() {
    }

    ngOnInit() {

        this.filteredRMS = _.filter(this.RMS, rms => rms.CFG_IdxGRP === this.grp.id);
        console.warn('this.filteredRMS', this.filteredRMS);
    }
}
