import {ActionSheetController, AlertController, Platform, ToastController} from '@ionic/angular';
import { Router } from '@angular/router';
import {interval, Observable, of, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {AppState} from '../services/app-state.service';
import {Config, DUAL_MODE, Season} from '../models/config';
import {Socket} from '../models/socket';
import {ChangeConfigValue, ReloadConfig, SaveAppCachedVersionConfig} from '../actions/config.actions';
import {EMA, MCU, MCZ, RMS, Table, TableNames, Tables} from '../models/tables';
import {ChangeTableValues, LoadTable} from '../actions/tables.actions';
import {Attributes} from '../models/attributes';
import {LoadAttributes, LoadAttributesSuccess} from '../actions/attributes.actions';
import {Backup} from '../models/backups';
import {LoadBackup} from '../actions/backup.actions';
import {first, switchMap} from 'rxjs/operators';

import {ActionsStatus} from '../models/actions-status';
import {AlarmModeOff, AlarmModeOn, ExecuteAction, LoadStatus} from '../actions/actions.actions';
import {AbstractBaseComponent} from '../components/abstract-base-component';
import {SystemStatus} from '../models/system-status';
import {WeatherService} from '../services/weather.service';
import * as _ from 'lodash';

import {Moment} from "moment/moment";
import {CurrentDateTime} from "../models/current-date-time";
import {SupportService} from "../services/support.service";
import {SystemHydronicPage} from "./system-hydronic/system-hydronic.page";
import {SystemFncPage} from "./system-fnc/system-fnc.page";
import {SystemTankPage} from "./system-tank/system-tank.page";
import {SystemDhwPage} from "./system-dhw/system-dhw.page";
import {SystemArsPage} from "./system-ars/system-ars.page";
import {SystemEnrPage} from "./system-enr/system-enr.page";
import {MCZ_Extended, RMS_Extended} from "../models/tables-extended";
import {DualSetpointOverrideMinimumDifferenceViolation} from "../models/variousGroupedModels";
import {Constants} from "../commons/const";

// declare var NativeStorage: any

class CentralizedData {

    initializated = {};
}

export abstract class AbstractConfigPage extends AbstractBaseComponent {
    // List of tables used by the page
    public TABLES: string[] = [];

    private loading_tables: string[] = [];

    protected subscriptions: any;
    protected loadedTables: { [name: string]: boolean } = {};
    protected callbacks: { tables: string[], callback: () => any }[] = [];
    protected observables: any; // { [name: string]: Observable<any>} = {};
    public attrs: Attributes;
    public config: Config = {} as Config;
    public backups: Backup[];
    public systemStatus: SystemStatus;
    public connected = false;
    private lastClickedRadio: string; // last clicked radio button
    public useBottomTabsNavigation: boolean;

    private supportedIconChanger: boolean;

    private socketObs: Observable<Socket>;
    private socketSub: Subscription;
    public defaultCallback: () => any;
    private subscribedTables: string[];
    ios: boolean;

    public CURRENT_VERSION = "2.10.48";
    public API_VERSION = "0.8.0";

    private static centralizedData = new CentralizedData();

    private static readonly currentDateTime: CurrentDateTime = new CurrentDateTime(); // This variable keeps the date updated as a private static variable. Don't call it in your code directly, but use his "this.currentDateTime" reference instead
    public readonly currentDateTime: CurrentDateTime = AbstractConfigPage.currentDateTime; // Use this variable to have the current moment object date, using the property "dateTimeAsMoment" or use it in your html content as text, using the property "dateTimeAsString" (example: Homepage) -> it's always updated with the correct current time! -> it has its reference to the readonly private static currentDateTime

    public alarmMode: boolean = false;
    private static newUpdateChecked = false;

    constructor(protected store: Store<AppState>,
                protected platform: Platform,
                protected alertController: AlertController,
                protected actionSheetController: ActionSheetController,
                protected translate: TranslateService,
                protected toastController?: ToastController,
                protected weatherService?: WeatherService) {

        super(store, platform, alertController, actionSheetController, translate, toastController);

        // -------------------------------------------------------------------------------------------------------------
        // --- CONSTRUCTOR ---------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        this.supportedIconChanger = false;

        this.observables = {
            config: store.select('config') as Observable<Config>,
            tables: store.select('tables') as Observable<Tables>,
            attrs: store.select('attrs') as Observable<Attributes>,
            backups: store.select('backups') as Observable<Backup[]>,
            socket: store.select('socket') as Observable<Socket>,
            actions: store.select('actions') as Observable<ActionsStatus>
        };

        this.ios = platform.is('ios');
    }

    public ngOnInit(): void {

        const className: string = this.constructor.toString().match(/\w+/g)[1];
        // console.log(`%cCHIAMATA ngOnInit DI ABSTRACT CONFIG PAGE SU ${className}`,'font-size:1.5rem;color:Gold');
        // console.log(this.TABLES);

        this.subscriptions = new Map();

        /*timer(1500).subscribe(() => {
            if (this.TABLES != [] && this.TABLES != undefined && this.TABLES != null) {
                this.load(this.TABLES);
            }
        });*/
    }

    public ionViewDidEnter() {

        if (this.TABLES && this.TABLES.length) {

            this.load(this.TABLES);
        }
    }

    public ngOnDestroy() {
        const className: string = this.constructor.toString().match(/\w+/g)[1];
        // console.log(`%cngOnDestroy ${className}`,'font-size:4rem;color:red;');
        this.cleanSubscriptions();
    }

    _slowDown(data) {
        return interval(15000).pipe(switchMap(data => of(data))
        );
    }

    /**
     * Load the tables
     */
    protected load(tables: string[], callback: () => any = this.defaultCallback): void {

        if (this.subscriptions === undefined || this.subscriptions == null) {

            this.subscriptions = new Map();
        }

        this.observables.tables.pipe(first()).subscribe((data: Tables) => {

            for (const table of Object.keys(this.loadedTables)) {

                this.loadedTables[table] = false;
            }

            this.loading_tables = [...tables];

            // Register the callback (before loading :)
            if (callback) {

                this.onTablesLoaded(tables, callback);
            }

            for (const table of tables) {

                if (table == 'Attributes') {

                    this.store.dispatch(new LoadAttributes());

                } else if (table == 'Config') {

                    this.store.dispatch(new ReloadConfig());

                    // Note: the attributes retrieved from local.storage must be fired from 'Config', because they are stored there.
                    // If I haven't downloaded the Attributes yet (so they are not saved yet in the centralizedData) -> I call a new LoadAttributesSuccess() with Attributes from local.storage (if present)
                    // After that, new LoadAttributes() will load the updated Attributes and set them in centralizedData. Having Attributes in local.storage is useful to reduce loading times of Attributes.
                    if (this.config.attributes && !AbstractConfigPage.isCentralizedTableInitialized('attrs')) {

                        console.warn('Carico attributes da localStorage', this.config.attributes);
                        this.store.dispatch(new LoadAttributesSuccess(this.config.attributes));
                    }

                } else if (table == 'Backups') {

                    this.store.dispatch(new LoadBackup());

                } else if (table == 'SystemStatus') {

                    this.store.dispatch(new LoadStatus());

                } else {

                    const loaded = data.loaded[TableNames[table]];

                    if (!loaded) {

                        this.store.dispatch(new LoadTable(table));
                    }
                }
            }
        });

        // Subscriptions
        if (!this.subscriptions.has('tables')) {

            this.subscriptions.set('tables', this.observables.tables.subscribe((data: Tables/* | ChangedTableValues*/) => {

                for (const table of this.loading_tables) {

                    AbstractConfigPage.centralizedData[table] = data[table] || AbstractConfigPage.centralizedData[table];

                    if (table == 'MCU') { // single instance

                        this[table] = AbstractConfigPage.centralizedData[table][0];

                    } else {

                        this[table] = AbstractConfigPage.centralizedData[table];
                    }

                    if (data[table]) {

                        this.loadedTables[table] = true;
                    }
                }

                // _dev_ main implementation of ChangedTableValues (I should put an istanceof Tables above too)
                // The idea is that, instead of changing the entire Tables (es: this[table] = data[table] || this[table]) I only update the exact properties received from the sensors
                // This ChangedTableValues data is created in the reducer of "ChangeTableValues" (only if I receive remote data)

                /*if (data instanceof ChangedTableValues && _.includes(this.loading_tables, data.table)) {

                    for (let update of data.changedValues) {

                        if (data.table === "MCU") {

                            this[data.table][update.property] = update.value;
                        }

                        else if (this[data.table]) {

                            this[data.table][data.id][update.property] = update.value;

                        }
                    }

                    this.loadedTables[data.table] = true;
                }*/

                this.checkCallbacks();
            }));
        }

        if (!this.subscriptions.has('attrs')) {

            this.subscriptions.set('attrs', this.observables.attrs.subscribe(data => {

                AbstractConfigPage.centralizedData['attrs'] = data.content || AbstractConfigPage.centralizedData['attrs'];
                this.attrs = AbstractConfigPage.centralizedData['attrs'];
                this.loadedTables.Attributes = true;
                this.checkCallbacks();
            }));
        }

        if (!this.subscriptions.has('config')) {

            this.subscriptions.set('config', this.observables.config.subscribe(async (data: Config) => {

                this.config = data || this.config;
                this.currentDateTime.setLanguage(this.config.language);
                this.loadedTables.Config = true;
                this.useBottomTabsNavigation = data.useBottomTabsNavigation;
                this.checkCallbacks();
                await this.checkNewUpdate();
            }));
        }

        if (!this.subscriptions.has('socket')) {

            this.subscriptions.set('socket', this.observables.socket.subscribe(data => {

                this.connected = data.connected;
            }));
        }

        if (!this.subscriptions.has('actions')) {

            this.subscriptions.set('actions', this.observables.actions.subscribe(data => {

                this.alarmMode = data.alarmMode;

                if (data.systemStatus) {

                    this.systemStatus = data.systemStatus;
                    this.checkCallbacks();
                }
            }));
        }
    }

    public ionViewDidLeave() {
        this.cleanSubscriptions();
    }

    /**
     * Register a callback executed when a table is loaded
     */
    protected onTableLoaded(table: string, callback: () => any): void {
        this.onTablesLoaded([table], callback);
    }

    /**
     * Register a callback executed when tables are loaded
     */
    protected onTablesLoaded(tables: string[], callback: () => any): void {
        // console.log(` this.callbacks.push({ tables: ${tables}, callback: ${callback });`)
        this.callbacks.push({tables, callback});
        // console.log(this.callbacks);
    }

    protected checkCallbacks(): void {
        // console.log(`this.callbacks è lungo ${this.callbacks.length}`);
        /*console.log('loggo this.loadedTables')
        console.log(this.loadedTables);*/


        for (const callback of this.callbacks) {
            if (callback.tables.every(table => {

                // console.error(`la checkCallbacks esegue la funzione per la tabella ${table} se qui vale  ${this.loadedTables[table]} chiamo il la callback()`);


                return this.loadedTables[table];


            })) {

                // console.log('il vettore this.callbacks vale')
                // console.log(this.callbacks);

                // console.error(`chiamo `)
                // console.error(callback.callback());
                /*console.log(`%cTutto il vettore loadedTables deve valere true se sono qui, infatti vale`)
                console.log(this.loadedTables);
                console.log(`%cEseguo callback`,'font-size:1.5rem;color:Chartreuse');*/
                callback.callback();
            } /*else {
                 console.log(`%cNON Tutto il vettore loadedTables vale true, infatti vale`)
                console.log(this.loadedTables);
                console.log(`%cNON Eseguo callback`,'font-size:1.5rem;color:Chartreuse');
            }*/
        }
    }

    /**
     * Check if all the required tables are loaded
     */
    public allTablesLoaded(): boolean {
        //// console.log("abstract-config-page allTablesLoaded()");
        const result = true;
        for (const table of this.TABLES) {
            if (this.loadedTables[table] === null || this.loadedTables[table] === undefined) {
                return false;
            }
        }
        return result;
    }


    /**
     * Remote the store subscriptions
     */
    protected cleanSubscriptions(): void {
        // console.error('cleanSubscriptions!');
        // this.socketSub.unsubscribe();

        // const className: string = this.constructor.toString().match(/\w+/g)[1];
        // console.log(`%cCHIAMATA cleanSubscriptions DI ABSTRACT CONFIG PAGE SU ${className}`,'font-size:1.5rem;color:Red');
        // console.log(this.TABLES);

        if (this.subscriptions != null) {
            for (const subscription of this.subscriptions) {
                // console.log(`chiamerò unsubscrive su ${subscription}`,'font-size:2rem:color:blue;');
            }

            for (const subscription of this.subscriptions.values()) {
                subscription.unsubscribe();
            }
            this.subscriptions.clear();
            this.subscriptions = null;

        }

        // this.subscriptions = [];
        this.callbacks = [];
        this.subscribedTables = [];

        // console.warn("cleanSubscriptions() completed!")
    }

    /**
     * Update a configuration field on the store
     */
    public configUpdated = (option: string): void => {
        // this.store.dispatch(ConfigActions.changeValue(option, this.config[option]));
        this.store.dispatch(new ChangeConfigValue(option, this.config[option]));
    }

    /**
     * Change a settings
     */
    public onChange = (table: string, id: number, var_: string, valueByParam?: any): void => {

        // console.warn('onChange ' + table + ' ' + id + ' ' + var_ + ' '+valueByParam);
        if (var_ == 'PAR_AccuWeatherKey') {

            this.weatherService.resetTimeout();
            this.weatherService.reset();
        }

        if (table == 'Config') {

            this.store.dispatch(new ChangeConfigValue(var_, this.config[var_]));

        } else {

            const values = {};

            if (table == 'MCU') {

                values[var_] = this[table][var_];

            } else if (valueByParam == undefined) {

                values[var_] = this[table][id][var_];

            } else if (valueByParam != undefined) {

                values[var_] = valueByParam;
            }

            console.log(new ChangeTableValues(table, id, values));
            this.store.dispatch(new ChangeTableValues(table, id, values));
        }
    }

    /**
     * Workaround for detecting changes on radio buttons
     */
    public onClickRadio(table: string, id: number, var_: string) {
        // console.log(`onClickRadio table ${table}, id ${id}, var ${var_}`);
        this.lastClickedRadio = table + '_' + id + '_' + var_;
    }

    public onClickRadioAndDispatch(table, element, property, value) {

        element[property] = value
        console.log(new ChangeTableValues(table, element.id, {[property]: element[property]}));
        this.store.dispatch(new ChangeTableValues(table, element.id, {[property]: element[property]}));
    }

    /**
     * Workaround for detecting changes on radio buttons
     */
    public onChangeRadio(table: string, id: number, var_: string) {
        // console.log(`onChangeRadio table ${table}, id ${id}, var ${var_}`);
        if (this.lastClickedRadio == table + '_' + id + '_' + var_) {

            this.lastClickedRadio = null;
            this.onChange(table, id, var_);
        }
    }

    static setCentralizedTableAsInitialized(tableName: string) {

        AbstractConfigPage.centralizedData.initializated[tableName] = true;
    }

    static isCentralizedTableInitialized(tableName) {

        return AbstractConfigPage.centralizedData.initializated[tableName];
    }

    static getCentralizedTableData(table) {

        return AbstractConfigPage.centralizedData[table] as Table[];
    }

    static resetCentralizedTableData() {

        const attrs = AbstractConfigPage.centralizedData.initializated["attrs"];
        AbstractConfigPage.centralizedData = new CentralizedData();
        AbstractConfigPage.centralizedData.initializated["attrs"] = attrs;
    }

    static resetCentralizedAttributesData() {

        AbstractConfigPage.centralizedData.initializated["attrs"] = false;
    }

    // Used if someone external wants to access the private static currentDateTime
    getCurrentDateTime() {

        return this.currentDateTime;
    }

    static setCurrentDateTime(currentDateTime: Moment, supportService: SupportService) {

        console.log('updated data: ', currentDateTime);
        this.currentDateTime.dateTimeAsMoment = currentDateTime;
        this.currentDateTime.setSupportService(supportService);
    }

    static resetCurrentDateTime() {

        this.currentDateTime.resetCurrentDateTime();
    }

    goBack() {

        window.history.back();
    }

      /*
    * Go to Home Page
    */
      goHome(router:Router){
        router.navigateByUrl('/home');
      }

  openAlertWindow() {

        this.store.dispatch(new AlarmModeOn());
    }

    closeAlertWindow() {

        this.store.dispatch(new AlarmModeOff());
    }

    getMcuProjectNumber(MCU: MCU) {

        const yearCode = MCU.CFG_Code.substring(0, 4); // for example: 2023
        const codeSeparatedWithDashes = MCU.CFG_Code.split('-');

        // Normally, in (fore example) "2023-102" and "2023-102-01" MCU codes, the second part of the code is always after the first "-" (hence the [1])
        // Unfortunately, some MCU codes are weird, for example the Messana Office MCU code is "0001" so I just return that
        if (codeSeparatedWithDashes[1]) {

            const sequentialCode = codeSeparatedWithDashes[1].padStart(4, '0'); // take the second part (for example: 103 or 1003) and add a 0 if the length is less than 4 (for example: 103 becomes 0103)
            return `${yearCode}-${sequentialCode}`;
        } else {

            return codeSeparatedWithDashes[0];
        }
    }

    /**
     * Check if new name is a valid name for ema
     */
    validNameForNewEMA = (name: string, EMA: EMA[], currentEmaId = null): boolean => {

        return name.trim() != "" && _.find(EMA.filter(ema => ema.CFG_Valid && ema.id !== currentEmaId), x => x.CFG_Name.toUpperCase() == name.trim().toUpperCase()) == null;
    }

    openSpecificUnit(context: SystemHydronicPage | SystemFncPage | SystemTankPage | SystemDhwPage | SystemArsPage | SystemEnrPage) {

        // If I've opened (for example) Ray Magic hys from a link -> It will have an "idToOpen" in the queryParams (so it will open system-hydronic and then open the element with id:0, in this case)
        if (this.subscriptions && !this.subscriptions.has('queryParamsSubscription')) {

            this.subscriptions.set('queryParamsSubscription', context.route.queryParams.subscribe(params => {

                console.warn('params: ', params);

                if (params.idToOpen >= 0) {

                    context.closeAllSectionsAndOpenLinkedOne(params.idToOpen); // close all sections, then only open the one we are interested in
                }
            }));
        }
    }

    scrollToElement(pageId: string, elementId: number, numberOfAttemps = 0) {

        const scrollIntoView = () => {

            const element = document.querySelector(`#system-${pageId}-container-${elementId}`);

            if (element) {

                element.scrollIntoView({block: 'start', behavior: 'smooth'});
            }

            // I try to "scrollIntoView" 5 times, within 500ms. If I can't, there could be something wrong (Like the element is missing somehow) and I give up scrolling to the element
            else if (numberOfAttemps < 5) {

                setTimeout(scrollIntoView, 100, numberOfAttemps + 1);
            }
        };

        setTimeout(scrollIntoView, 100, numberOfAttemps);
    }

    async openRestart(onlyWarnings = null) {

        let header = this.translate.instant('ALERT');
        let message = this.translate.instant('RESTART_CONFIRM');
        let toastText = this.translate.instant('RESTART_ONGOING');

        // onlyWarnings is based on "onlyWarnings" of home.html -> so it can be either true or false, or null if it comes from another page
        if (onlyWarnings !== null) {

            const ALARMS = onlyWarnings ? 'WARNINGS_HOME' : 'ALARMS_HOME';
            header = `${this.translate.instant('RESET')} ${this.translate.instant(ALARMS)}`;
            message = `${this.translate.instant('RESET_ALARM_WARNING_POPUP_TEXT')} ${this.translate.instant(ALARMS)}?`;
            toastText = this.translate.instant('RESET_ALARMS_TOAST');
        }

        const popup = await this.alertController.create({

            cssClass: 'alert-yes-no',
            header: header,
            message: message,
            buttons: [

                {
                    text: this.translate.instant('YES'),
                    handler: () => {

                        const navTransition = popup.dismiss();

                        navTransition.then(() => {

                            this.restart(toastText);
                        });
                    }
                },

                {
                    text: this.translate.instant('NO'),
                    handler: () => {
                    }
                }
            ]
        });

        await popup.present();

    }

    async restart(message) {

        this.closeAlertWindow();
        await this.executeAction('restart', message);
    }

    public async executeAction(action: string, message: string) {

        this.store.dispatch(new ExecuteAction(action));

        const toast = await this.toastController.create({
            message: message,
            duration: 5000
        });

        await toast.present();
    }

    // If a macro-zone has an override, but there aren't any zones with an active override -> reset the macro-zone override.
    public checkAndCleanOverrides(mcz: MCZ, RMS: RMS[], PAR_SpoPeriod: string, RTU_SpoRemain: string) {

        let zonesMcz: RMS[];

        if (mcz.id === 0) {

            zonesMcz = RMS;

        } else {

            zonesMcz = RMS.filter(rms => rms.CFG_IdxMCZ === mcz.id);
        }

        // returns true if all zones have RTU_SpoRemain equal to 0 (which means they are not in override)
        const allZonesAreWithoutAnOverride = _.every(zonesMcz, {[RTU_SpoRemain]: 0});

        // In case I have cancelled all zones with an override -> I cancel the macro-zone's override too.
        if (allZonesAreWithoutAnOverride) {

            this.store.dispatch(new ChangeTableValues('MCZ', mcz.id, {[PAR_SpoPeriod]: 0, [RTU_SpoRemain]: 0}));
        }
    }

    computeMin(season: number, mcz: MCZ, MCU: MCU, rmsOrMczExtended: RMS_Extended | MCZ_Extended, isAutoModeStyleDualSetpoint: boolean) {

        if (season == Season.Heating) {

            return mcz ? mcz.TEC_SetTempMinH : undefined;
        }

        if (season == Season.Cooling && !isAutoModeStyleDualSetpoint) {

            return mcz ? mcz.TEC_SetTempMinC : undefined
        }

        if (season == Season.Cooling && isAutoModeStyleDualSetpoint) {

            const minDifference = MCU.PAR_UM === 0 ? 2 : 1;

            // new -> when there is an active heating setpoint override -> The cooling setpoint min now depends on the heating override.
            const heatingIsInOverride = rmsOrMczExtended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride;

            if (heatingIsInOverride) {

                return rmsOrMczExtended.PAR_SpoSetTempH + minDifference;
            }

            return mcz ? mcz.TEC_SetTempMinC + minDifference : undefined;
        }
    }

    // Compute Min and Max Setpoint temperatures of zones (especially useful for calculating Maxs and Mins in auto-dual setpoint, and in override, which is complex)
    computeMax(season: number, mcz: MCZ, MCU: MCU, rmsOrMczExtended: RMS_Extended | MCZ_Extended, isAutoModeStyleDualSetpoint: boolean) {

        if (season == Season.Cooling) {

            return mcz ? mcz.TEC_SetTempMaxC : undefined;
        }

        if (season == Season.Heating && !isAutoModeStyleDualSetpoint) {

            return mcz ? mcz.TEC_SetTempMaxH : undefined;
        }

        if (season == Season.Heating && isAutoModeStyleDualSetpoint) {

            const minDifference = MCU.PAR_UM === 0 ? 2 : 1;

            // new -> when there is an active cooling setpoint override -> The heating setpoint max now depends on the cooling override.
            const coolingIsInOverride = rmsOrMczExtended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride;

            if (coolingIsInOverride) {

                return rmsOrMczExtended.PAR_SpoSetTempC - minDifference;
            }

            return mcz ? mcz.TEC_SetTempMaxH - minDifference : undefined;
        }
    }

    // "otherDualSetpointModeIsInOverrideForText" is only used / set when you create a new override in the popup, to show the text explaining that 2 overrides will start if the 2° requirement is not respected
    getDualSetpointOverrideMinimumDifferenceViolation(otherDualSetpointModeIsInOverrideForText: boolean, dualSetpointMode: DUAL_MODE, MCU: MCU, SpoSetTemp: number, rmsOrMczExtended: RMS_Extended | MCZ_Extended, isCancelOverride = false, isUpdateTimeOverride = false, updateTimeOverrideText = '') {

        const violation = new DualSetpointOverrideMinimumDifferenceViolation();
        const minDifference = MCU.PAR_UM === 0 ? 2 : 1; // fahrenheit or celsius

        // I don't want to show the text when both setpoints (or at least 1) are already in override. But I ignore this parameter, so I pass it as false, when saving or cancelling an override or editing the time.
        if (otherDualSetpointModeIsInOverrideForText) {

            return violation;
        }

        switch (dualSetpointMode) {

            case DUAL_MODE.heating:

                // It's not a violation (obviously) if there isn't even a cooling terminal in this RMS
                if (rmsOrMczExtended instanceof RMS_Extended && rmsOrMczExtended.autoModeRmsModeNotPresent(DUAL_MODE.cooling)) {

                    return violation;
                }

                // In the cancel override I want to check that the base setpoint (because that will be the value once cancelled) is congruent withing the 2° difference with the other SetTemp override, if present
                if (isCancelOverride || isUpdateTimeOverride) {

                    if (SpoSetTemp > rmsOrMczExtended.PAR_SpoSetTempC - minDifference && rmsOrMczExtended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride) {

                        violation.present = true;
                        violation.text = 'DUAL_SETPOINT_CANCEL_HEATING_OVERRIDE_WARNING';

                        if (isUpdateTimeOverride) {

                            violation.text = updateTimeOverrideText;
                        }
                    }
                }

                else if (SpoSetTemp > rmsOrMczExtended.PAR_SetTempC - minDifference) {

                    violation.present = true;
                    violation.text = 'DUAL_SETPOINT_HEATING_OVERRIDE_HIGH_TEMP_WARNING';
                }

                break;

            case DUAL_MODE.cooling:

                // It's not a violation (obviously) if there isn't even a heating terminal in this RMS
                if (rmsOrMczExtended instanceof RMS_Extended && rmsOrMczExtended.autoModeRmsModeNotPresent(DUAL_MODE.heating)) {

                    return violation;
                }

                if (isCancelOverride || isUpdateTimeOverride) {

                    if (SpoSetTemp < rmsOrMczExtended.PAR_SpoSetTempH + minDifference && rmsOrMczExtended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride) {

                        violation.present = true;
                        violation.text = 'DUAL_SETPOINT_CANCEL_COOLING_OVERRIDE_WARNING';

                        if (isUpdateTimeOverride) {

                            violation.text = updateTimeOverrideText;
                        }
                    }
                }

                else if (SpoSetTemp < rmsOrMczExtended.PAR_SetTempH + minDifference) {

                    violation.present = true;
                    violation.text = 'DUAL_SETPOINT_COOLING_OVERRIDE_LOW_TEMP_WARNING';
                }

                break;
        }

        return violation;
    }

    async checkNewUpdate() {

        const saveNewVersionInCache = () => {

            this.store.dispatch(new SaveAppCachedVersionConfig(this.CURRENT_VERSION));
        }

        if (!AbstractConfigPage.newUpdateChecked) {

            AbstractConfigPage.newUpdateChecked = true;

            if (this.CURRENT_VERSION !== this.config.appCurrentVersionCached) {

                const title = `${this.translate.instant('NEW_VERSION')}: ${this.CURRENT_VERSION}`;
                const message = `${this.translate.instant('NEW_VERSION_DESCRIPTION')}`; // The "NEW_VERSION_DESCRIPTION" property's value is changed everytime there is a new update

                // For minor releases, you can keep the "NEW_VERSION_DESCRIPTION" property empty. In this case, no popups will appear.
                if (message !== '') {

                    await this.confirmPopup(null, title, message, 'OK', saveNewVersionInCache, ()=>{}, '', 'alert-yes-no text-align-left alert-show-scrollable-arrows');
                }
            }
        }
    }

    fahrenheitToCelsius(fahrenheit: number | string, fractionDigits = 1): number {

        if (typeof fahrenheit === "string") {

            fahrenheit = parseFloat(fahrenheit);
        }

        if (fahrenheit === Constants.NO_VALUE) {

            return fahrenheit;
        }

        const celsius = (fahrenheit - 32) * 5 / 9;
        return parseFloat(celsius.toFixed(fractionDigits));
    }
}
