import {ARS, ATU, AUTO_MODE_STYLE, DHW, ENR, FNC, FWA, GRP, HYS, MCU, MCZ, RMS, SCH, TMR, TNK, ZON} from "./tables";
import * as _ from 'lodash';
import {BitArray} from "../commons/bitarray";
import * as moment from "moment";
import {FlagsGroup, FlagsINT} from "../commons/flags";
import {DUAL_MODE, SchedulationType, Season} from './config';
import {ChangeTableValues} from "../actions/tables.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../services/app-state.service";
import {TranslateService} from "@ngx-translate/core";
import {AlertController} from "@ionic/angular";
import {HeatPumpModels} from "./enum-misc";

// ---------------------------------------------------------------------------------------------------------------------
// --- RMS - MCZ - COMMON ----------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

class RmsMczCommonMethods {

    // -----------------------------------------------------------------------------------------------------------------
    // --- setpointCounter: useful to decide which counter to use ------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    // Not used for calculate in dual setpoint
    setpointCounterIsPAR_SetTempH(mczOrRms: MCZ_Extended | RMS_Extended) {

        return mczOrRms.grp.RTU_SeasonRef == 0 && (!mczOrRms.PAR_SchedOnH || (mczOrRms.PAR_SchedOnH && mczOrRms.sch?.CFG_Type !== SchedulationType.Variable && mczOrRms.sch?.CFG_Type !== SchedulationType.DualVariable));
    }

    // -----------------------------------------------------------------------------------------------------------------

    // Not used for calculate in dual setpoint (for now - it must be fixed otherwise)
    setpointCounterIsPAR_SetTempC(mczOrRms: MCZ_Extended | RMS_Extended) {

        return mczOrRms.grp.RTU_SeasonRef == 1 && (!mczOrRms.PAR_SchedOnC || (mczOrRms.PAR_SchedOnC && mczOrRms.sch?.CFG_Type !== SchedulationType.Variable && mczOrRms.sch?.CFG_Type !== SchedulationType.DualVariable));
    }

    // -----------------------------------------------------------------------------------------------------------------

    // Not used for calculate in dual setpoint (for now - it must be fixed otherwise)
    setpointCounterIsRTU_SetPoint(mczOrRms: MCZ_Extended | RMS_Extended, checkDualSetpointMode: DUAL_MODE) {

        if (mczOrRms.PAR_SchedOnH && ((mczOrRms.sch?.CFG_Type === SchedulationType.DualVariable && checkDualSetpointMode === DUAL_MODE.heating) || (mczOrRms.grp.RTU_SeasonRef === 0 && mczOrRms.sch?.CFG_Type === SchedulationType.Variable))) {

            if (mczOrRms.sch.CFG_Type === SchedulationType.DualVariable) {

                return 'RTU_SetPointH';
            } else {

                return 'RTU_SetPoint';
            }
        }

        if (mczOrRms.PAR_SchedOnC && ((mczOrRms.sch?.CFG_Type === SchedulationType.DualVariable && checkDualSetpointMode === DUAL_MODE.cooling) || (mczOrRms.grp.RTU_SeasonRef === 1 && mczOrRms.sch?.CFG_Type === SchedulationType.Variable))) {

            if (mczOrRms.sch.CFG_Type === SchedulationType.DualVariable) {

                return 'RTU_SetPointC';
            } else {

                return 'RTU_SetPoint';
            }
        }

        return false;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- Override helper functions -----------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    // --- Get the correct "SpoSetTemp" override value ---
    getCorrectOverridePropertyValues(mczOrRms: MCZ_Extended | RMS_Extended, dualSetpointMode: DUAL_MODE = null) {

        switch (dualSetpointMode) {

            case DUAL_MODE.heating:

                return {PAR_SpoSetTemp: mczOrRms.PAR_SpoSetTempH, PAR_SpoPeriod: mczOrRms.PAR_SpoPeriodH};

            case DUAL_MODE.cooling:

                return {PAR_SpoSetTemp: mczOrRms.PAR_SpoSetTempC, PAR_SpoPeriod: mczOrRms.PAR_SpoPeriodC};

            default:

                return {PAR_SpoSetTemp: mczOrRms.PAR_SpoSetTemp, PAR_SpoPeriod: mczOrRms.PAR_SpoPeriod};
        }
    }

    // --- Get the correct override properties ---
    getCorrectOverrideProperties(dualSetpointMode: DUAL_MODE = null) {

        switch (dualSetpointMode) {

            case DUAL_MODE.heating:

                return {PAR_SpoSetTemp: 'PAR_SpoSetTempH', PAR_SpoPeriod: 'PAR_SpoPeriodH', PAR_SpoStart: 'PAR_SpoStartH'};

            case DUAL_MODE.cooling:

                return {PAR_SpoSetTemp: 'PAR_SpoSetTempC', PAR_SpoPeriod: 'PAR_SpoPeriodC', PAR_SpoStart: 'PAR_SpoStartC'};

            default:

                return {PAR_SpoSetTemp: 'PAR_SpoSetTemp', PAR_SpoPeriod: 'PAR_SpoPeriod', PAR_SpoStart: 'PAR_SpoStart'};
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    // If I pass dualSetpointMode as 2 (heatingAndCooling) I only want to check if there is an override either in heating or in cooling
    getSetpointOverrideInfo(mczOrRms: MCZ_Extended | RMS_Extended, SpoPeriod: number = null, dualSetpointMode: DUAL_MODE | Season = null) {

        // -------------------------------------------------------------------------------------------------------------
        // --- CLASS: SetpointOverrideDateTime -------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        class SetpointOverrideDateTime {

            public days;
            public hours;
            public minutes;
            public seconds;

            constructor(days, hours, minutes, seconds) {

                this.days = days;
                this.hours = hours;
                this.minutes = minutes;
                this.seconds = seconds;
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- CLASS: SetpointOverride ---------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        class SetpointOverride {

            public isOverride;
            public arc;
            public ray;
            public overrideDateTimeSet: SetpointOverrideDateTime;
            public currentOverrideDateTime: SetpointOverrideDateTime;

            constructor(isOverride, arc, ray, overrideDateTimeSet, currentOverrideDateTime) {

                this.isOverride = isOverride;

                if (this.isOverride) {

                    this.arc = arc;
                    this.ray = ray;
                }

                this.overrideDateTimeSet = overrideDateTimeSet;
                this.currentOverrideDateTime = currentOverrideDateTime;

            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        const getSetpointOverrideObject = (PAR_SpoPeriod: string, PAR_SpoStart: string, RTU_SpoRemain: string) => {

            // array di proprieta
            const totalMinutes = mczOrRms[PAR_SpoPeriod];
            const start = moment(mczOrRms[PAR_SpoStart] * 1000, "x");
            const end = start.add(totalMinutes, 'minutes');
            const remainingMinutes = moment.duration(end.diff(moment())).asMinutes();
            const arc = 360 - (remainingMinutes / totalMinutes * 360);
            const ray = 31.4 - (remainingMinutes / totalMinutes * 31.4);

            // -------------------------------------------------------------------------------------------------------------

            let overrideDateTimeSet;

            if (SpoPeriod >= 0) {

                const duration = moment.duration(SpoPeriod, 'minutes');
                overrideDateTimeSet = getSetpointOverrideDateTime(duration);
            }

            const currentOverrideDateTime = getSetpointOverrideDateTime(moment.duration(remainingMinutes, 'minutes'));

            // -------------------------------------------------------------------------------------------------------------

            let isOverride = remainingMinutes > 0;

            if (isOverride && mczOrRms instanceof MCZ_Extended) {

                isOverride = _.some(mczOrRms.rmsArrayOfMcz, rms => rms[RTU_SpoRemain] > 0);
            }

            // -------------------------------------------------------------------------------------------------------------

            return new SetpointOverride(isOverride, arc, ray, overrideDateTimeSet, currentOverrideDateTime);
        }

        switch (dualSetpointMode) {

            case DUAL_MODE.heating:

                return getSetpointOverrideObject('PAR_SpoPeriodH', 'PAR_SpoStartH', 'RTU_SpoRemainH');

            case DUAL_MODE.cooling:

                return getSetpointOverrideObject('PAR_SpoPeriodC', 'PAR_SpoStartC', 'RTU_SpoRemainC');

            // If I pass both, it's only because I need the "isOverride" property (for example for the icon there must be either an override in heating or cooling)
            case DUAL_MODE.heatingAndCooling:

                const setpointOverrideHeating = getSetpointOverrideObject('PAR_SpoPeriodH', 'PAR_SpoStartH', 'RTU_SpoRemainH');
                const setpointOverrideCooling = getSetpointOverrideObject('PAR_SpoPeriodC', 'PAR_SpoStartC', 'RTU_SpoRemainC');

                return new SetpointOverride(setpointOverrideHeating.isOverride || setpointOverrideCooling.isOverride, null, null, null, null);

            // not in dual setpoint
            default:

                return getSetpointOverrideObject('PAR_SpoPeriod', 'PAR_SpoStart', 'RTU_SpoRemain');
        }

        // -------------------------------------------------------------------------------------------------------------

        function getSetpointOverrideDateTime(duration): SetpointOverrideDateTime {

            const days = duration.days();
            duration.subtract(moment.duration(days, 'days'));
            const hours = duration.hours();
            duration.subtract(moment.duration(hours, 'hours'));
            const minutes = duration.minutes();
            duration.subtract(moment.duration(minutes, 'minutes'));
            const seconds = duration.seconds();
            return new SetpointOverrideDateTime(days, hours, minutes, seconds);
        }
    }

    isAutoModeStyleDualSetpoint(grp: GRP) {

        return grp?.PAR_AutoModeStyle === AUTO_MODE_STYLE.DUAL_SETPOINT && grp?.PAR_Season !== 0 && grp?.PAR_Season !== 1 && grp?.PAR_Season !== -1;
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- MCZ -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class MCZ_Extended extends MCZ implements RmsMczCommonMethods {

    public PAR_State = 1; // this property doesn't exist in the Paramc Doc for MCZ -> I created it in order to not get an error when a property is both an RMS or MCZ

    public grp: GRP_Extended;
    public sch: SCH;
    public rmsArrayOfMcz: RMS_Extended[];
    public isMcz = true; // to distinguish if it's an RMS_Extended or MCZ_Extended, when a variable or property can be type of both.

    private rmsMczCommonMethods: RmsMczCommonMethods;

    constructor() {

        super();
        this.rmsMczCommonMethods = new RmsMczCommonMethods();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- MERGE ADDITIONAL PROPERTIES FOR RMS -------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedMCZ(mcz: MCZ, RMS: RMS_Extended[], grpList: GRP[], schList: SCH[]): MCZ_Extended {

        const mcz_extended = new MCZ_Extended();
        Object.assign(mcz_extended, mcz);

        // Zones of that MCZ
        mcz_extended.rmsArrayOfMcz = RMS.filter(zone => zone.CFG_IdxMCZ == mcz.id);

        // Group
        mcz_extended.grp = getGroupMCZ();

        // Schedule
        mcz_extended.sch = getScheduleMCZ();

        return mcz_extended;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- GRP -----------------------------------------------------------------------------------------------------
        function getGroupMCZ(): GRP_Extended {

            const grp_mcz = grpList[mcz.CFG_IdxGRP];

            return GRP_Extended.createExtendedGRP(grp_mcz);
        }

        // --- SCH -----------------------------------------------------------------------------------------------------
        function getScheduleMCZ(): SCH {

            if (mcz_extended.grp.RTU_SeasonRef === 0) {

                return schList[mcz.PAR_IdxSCH_H];
            }

            return schList[mcz.PAR_IdxSCH_C];
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- METHODS -----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    getSetpointOverrideInfo(SpoPeriod?: any, dualSetpointMode: DUAL_MODE | Season = null) {

        return this.rmsMczCommonMethods.getSetpointOverrideInfo(this, SpoPeriod, dualSetpointMode);
    }

    setpointCounterIsPAR_SetTempC(): boolean {

        return this.rmsMczCommonMethods.setpointCounterIsPAR_SetTempC(this);
    }

    setpointCounterIsPAR_SetTempH(): boolean {

        return this.rmsMczCommonMethods.setpointCounterIsPAR_SetTempH(this);
    }

    setpointCounterIsRTU_SetPoint(checkDualSetpointMode) {

        return this.rmsMczCommonMethods.setpointCounterIsRTU_SetPoint(this, checkDualSetpointMode);
    }

    getCorrectOverridePropertyValues(dualSetpointMode?) {

        return this.rmsMczCommonMethods.getCorrectOverridePropertyValues(this, dualSetpointMode);
    }

    getCorrectOverrideProperties(dualSetpointMode?) {

        return this.rmsMczCommonMethods.getCorrectOverrideProperties(dualSetpointMode);
    }

    isAutoModeStyleDualSetpoint() {

        return this.rmsMczCommonMethods.isAutoModeStyleDualSetpoint(this.grp);
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- RMS -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class RMS_Extended extends RMS implements RmsMczCommonMethods {

    public PAR_Mode = 2; // this property doesn't exist in the Paramc Doc for RMS -> I created it in order to not get an error when a property is both an RMS or MCZ

    public grp: GRP_Extended;
    public zonList: ZON_Extended[];
    public mcz: MCZ;
    public sch: SCH;
    public floorWarmer: FWA_Extended;
    public fncList: FNC_Extended[];
    public tmrList: TMR_Extended[];
    public ars: ARS_Extended;
    public secondaryArs: ARS_Extended;
    public arsList: ARS_Extended[] = [];
    public probeName: string = ''; // The probe used in this RMS. For example: mSense

    public ionListExpanded: boolean = false; // used if the card can be expanded or collapsed by clicking its header

    public schList: SCH[];
    private rmsMczCommonMethods: RmsMczCommonMethods;
    public isMcz = false; // to distinguish if it's an RMS_Extended or MCZ_Extended, when a variable or property can be type of both.

    private hasVariableSchedule = false; // property copied from zone.page now that I want rms schedules on system-zone too (so it's shared) -> Used only in "onChangeSchedule"

    constructor() {

        super();
        this.rmsMczCommonMethods = new RmsMczCommonMethods();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- MERGE ADDITIONAL PROPERTIES FOR RMS -------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedRMS(rmsList: RMS_Extended[] | RMS[], rms: RMS, grpList: GRP[], zonList: ZON_Extended[] | ZON[], mcz: MCZ[], schList: SCH[], fncList: FNC[], hysList: HYS[], tmrList: TMR[], arsList: ARS[], atuList: ATU[], enrList: ENR[], tnkList: TNK[], floorWarmerList: FWA[]): RMS_Extended {

        let rms_extended = new RMS_Extended();
        Object.assign(rms_extended, rms);

        // Group
        rms_extended.grp = getGroupRMS();

        // Zones
        rms_extended.zonList = getZonesRMS();

        // Macro-Zone
        rms_extended.mcz = getMacroZoneRMS();

        // Schedule
        rms_extended.sch = getScheduleRMS();
        rms_extended.hasVariableSchedule = rms_extended.sch?.CFG_Type === SchedulationType.Variable || rms_extended.sch?.CFG_Type === SchedulationType.DualVariable;

        // Fan Coils
        rms_extended.fncList = getFanCoilsRMS();

        // Timers
        rms_extended.tmrList = getTimersRMS();

        // Air System
        rms_extended.ars = getAirSystemRMS();

        if (rms_extended.ars) {

            rms_extended.arsList.push(rms_extended.ars);
        }

        // Air System (Secondary)
        rms_extended.secondaryArs = getSecondaryAirSystemRMS();

        if (rms_extended.secondaryArs) {

            rms_extended.arsList.push(rms_extended.secondaryArs);
        }

        rms_extended.floorWarmer = getFloorWarmer();

        // When I merge the old rms data with the new rms data => I want to keep the old_rms.ionListExpanded
        const previousRMS = _.find(rmsList, (rmsExtended) => rmsExtended.id == rms.id) as RMS_Extended;

        if (previousRMS !== undefined) {

            rms_extended.ionListExpanded = previousRMS.ionListExpanded;
        }

        rms_extended.schList = schList;

        // Get the probe name used in this RMS (for example: mSense)
        rms_extended.probeName = getProbeName();

        return rms_extended;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- GRP -----------------------------------------------------------------------------------------------------
        function getGroupRMS(): GRP_Extended {

            let grp_rms = grpList[rms.CFG_IdxGRP];

            return GRP_Extended.createExtendedGRP(grp_rms);
        }

        // --- ZON[] ---------------------------------------------------------------------------------------------------
        function getZonesRMS(): ZON_Extended[] {

            const zonListFiltered = _.filter(zonList, (zon: ZON_Extended) => {

                return zon.CFG_IdxRMS == rms.id;

            }) as ZON_Extended[];

            return ZON_Extended.createExtendedZONList(zonListFiltered, hysList, grpList, enrList, tnkList);
        }

        // --- MCZ -----------------------------------------------------------------------------------------------------
        function getMacroZoneRMS(): MCZ {

            return mcz[rms.CFG_IdxMCZ];
        }

        // --- SCH -----------------------------------------------------------------------------------------------------
        function getScheduleRMS(): SCH {

            if (rms_extended.grp.RTU_SeasonRef === 0) {

                return schList[rms.PAR_IdxSCH_H];
            }

            return schList[rms.PAR_IdxSCH_C];
        }

        // --- FNC[] ---------------------------------------------------------------------------------------------------
        function getFanCoilsRMS(): FNC_Extended[] {

            // Filter all the Array of FNC -> add the FNC to the array if (in its array of CFG_IdxRMS[]) you find the FNC is applied to the RMS
            const fncListFiltered = _.filter(fncList, (fnc: FNC) => {

                // If a Fan Coil is applied to a RMS, it returns the index -> if it's not applied is undefined
                return _.find(fnc.CFG_IdxRMS, (idxRms) => idxRms == rms.id) !== undefined;

            }) as FNC_Extended[];

            return FNC_Extended.createExtendedFNCList(fncListFiltered, grpList);
        }

        // --- TMR[] ---------------------------------------------------------------------------------------------------

        function getTimersRMS(): TMR_Extended[] {

            const timersList = [];

            const timer_1 = tmrList[rms.CFG_IdxTMR1];
            const timer_2 = tmrList[rms.CFG_IdxTMR2];

            if (timer_1) {

                timersList.push(TMR_Extended.createExtendedTMR(timer_1, schList));
            }

            if (timer_2) {

                timersList.push(TMR_Extended.createExtendedTMR(timer_2, schList));
            }

            return timersList;
        }

        // --- ARS -----------------------------------------------------------------------------------------------------
        function getAirSystemRMS(): ARS_Extended {

            const ars_rms = arsList[rms.CFG_IdxARS];

            if (ars_rms) {

                return ARS_Extended.createExtendedARS(ars_rms, atuList, grpList, schList, hysList);
            }
        }

        // --- ARS (secondary) -----------------------------------------------------------------------------------------
        function getSecondaryAirSystemRMS() {

            const ars_rms = arsList[rms.CFG_IdxSecondaryARS];

            if (ars_rms) {

                return ARS_Extended.createExtendedARS(ars_rms, atuList, grpList, schList, hysList, true);
            }
        }

        // --- FWA (Floor Warmer) --------------------------------------------------------------------------------------
        function getFloorWarmer() {

            // The _.includes() method is used to find the value is in the collection or not
            const fwa = _.find(floorWarmerList, (fwa) => _.includes(fwa.CFG_IdxRMSs, rms.id));

            if (fwa) {

                return FWA_Extended.createExtendedFWA(fwa, hysList, grpList, enrList, tnkList);
            }
        }

        // I don't need to translate the names of the probes
        function getProbeName() {

            switch (rms_extended.CFG_Type) {

                case 2:

                    return 'Sense Magic (Display - Dave)';

                case 3:

                    return 'Sense Magic (Blind - Dave)';

                case 4:

                    return 'Sense Magic (Blind - &mu;Fox)';

                case 5:

                    return 'Sense Magic (Dial - &mu;Fox)';

                case 6:

                    return 'Sense Magic (Dial &deg;F - &mu;Fox)';

                case 7:

                    return 'mSense';

                case 8:

                    return 'mTouch + mSense';

                case 9:

                    return 'SenseAir';

                case 15:

                    return 'Vector TRI2';

                case 16:

                    return 'SyxthSense';

                case 17:

                    return 'Generic thermostat';

                case 18:

                    return 'NewtOhm';
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- SUPPORT CLASS METHODS ---------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    getParStateIsActive() {

        // DEPRECATED --> Now it doesn't matter if a RMS doesn't have a single ZON
        // it's exactly as PAR_State -> but if there are no zones in this room sensor, it's false even if PAR_State is 1
        // return this.zonList.length > 0 && this.PAR_State !== 0; // to be true: PAR_State must be 1 and there must be at least 1 Zone

        return this.PAR_State !== 0;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- getSingleRTU_Flags: It can be useful to call the <icon component with a reduced RTU_Flags (for example: calling icons with rms.RTU_FLags shows all the icons, but maybe  I just want to show the the thermal function - ceiling icon)
    // -----------------------------------------------------------------------------------------------------------------

    getSingleRTU_Flags(selectedIndex = null, zon: ZON = null) {

        if (zon) {

            // If I want the radiant icon of a Zone -> if it's Floor, the RTU_Flag is 15, otherwise if it's ceiling it's 0
            selectedIndex = zon.CFG_Floor ? 15 : 0;
        }

        const newStatus = new Array(this.RTU_Flags.length);
        newStatus[selectedIndex] = this.RTU_Flags[selectedIndex];
        return newStatus;
    }

    // -----------------------------------------------------------------------------------------------------------------

    dualSetpointCounterIsRTU_SetPoint(checkHeating, checkCooling) {

        if (checkHeating) {

            const sch = this.schList[this.PAR_IdxSCH_H];

            // I don't check if the RTU is valid anymore. Previously, if it was --.- the user could change the setpoint since I showed him the PAR_SetTempH, but it's not the preferred solution anymore
            // const RTU_SetPoint = sch?.CFG_Type === SchedulationType.Variable ? sch?.RTU_SetPoint : sch?.RTU_SetPointH;
            // const valid = Constants.RTU_SetPointIsValid(sch, RTU_SetPoint);

            if (this.PAR_SchedOnH && (sch?.CFG_Type === SchedulationType.Variable || sch?.CFG_Type === SchedulationType.DualVariable)) {

                return true;
            }
        } else if (checkCooling) {

            const sch = this.schList[this.PAR_IdxSCH_C];

            // I don't check if the RTU is valid anymore. Previously, if it was --.- the user could change the setpoint since I showed him the PAR_SetTempC, but it's not the preferred solution anymore
            // const RTU_SetPoint = sch?.CFG_Type === SchedulationType.Variable ? sch?.RTU_SetPoint : sch?.RTU_SetPointC;
            // const valid = Constants.RTU_SetPointIsValid(sch, RTU_SetPoint);

            if (this.PAR_SchedOnC && (sch?.CFG_Type === SchedulationType.Variable || sch?.CFG_Type === SchedulationType.DualVariable)) {

                return true;
            }
        }

        return false;
    }

    // Check if a zone (while in auto mode dual setpoint) can really handle heating or cooling. For example, by default, I show both setpoints in dual setpoint mode, but if a zone can't really do heating or cooling, I need to disable it.
    autoModeRmsModeNotPresent(season: Season | DUAL_MODE) {

        if (this.isAutoModeStyleDualSetpoint()) {

            // --- I check the zones first ---

            // If the zone can do heating and cooling all setpoints will always be present
            if (this.zonList.some(zon => zon.PAR_Type === 3)) {

                return false;
            }

            // If the season is heating, and the zone can do heating -> show the heating setpoint (otherwise it will be shown as disabled)
            if (season === Season.Heating && this.zonList.some(zon => zon.PAR_Type === 1)) {

                return false;
            }

            // If the season is cooling, and the zone can do cooling -> show the cooling setpoint (otherwise it will be shown as disabled)
            if (season === Season.Cooling && this.zonList.some(zon => zon.PAR_Type === 2)) {

                return false;
            }

            // --- Now I check the fan coils ---
            if (season === Season.Heating) {

                // If the season is heating, check if at least one fancoil in this rms can do heating or heating integration (even though it's superfluous the heating integration check)
                const hasHeatingMode = this.fncList.some(fnc => {

                    const bitArray = new BitArray(25).fromNumber(fnc.PAR_ModeFC);
                    return bitArray.get(0);
                });

                if (hasHeatingMode) {

                    return false;
                }
            }

            if (season === Season.Cooling) {

                // If the season is cooling, check if at least one fancoil in this rms can do cooling or cooling integration (even though it's superfluous the cooling integration check)
                const hasCoolingMode = this.fncList.some(fnc => {

                    const bitArray = new BitArray(25).fromNumber(fnc.PAR_ModeFC);
                    return bitArray.get(1);
                });

                if (hasCoolingMode) {

                    return false;
                }
            }

            return true;
        }
    }

    // --- Common Methods with MCZ ---

    getSetpointOverrideInfo(SpoPeriod?, dualSetpointMode: DUAL_MODE | Season = null) {

        return this.rmsMczCommonMethods.getSetpointOverrideInfo(this, SpoPeriod, dualSetpointMode);
    }

    setpointCounterIsPAR_SetTempC(): boolean {

        return this.rmsMczCommonMethods.setpointCounterIsPAR_SetTempC(this);
    }

    setpointCounterIsPAR_SetTempH(): boolean {

        return this.rmsMczCommonMethods.setpointCounterIsPAR_SetTempH(this);
    }

    setpointCounterIsRTU_SetPoint(checkDualSetpointMode) {

        return this.rmsMczCommonMethods.setpointCounterIsRTU_SetPoint(this, checkDualSetpointMode);
    }

    getCorrectOverridePropertyValues(dualSetpointMode?) {

        return this.rmsMczCommonMethods.getCorrectOverridePropertyValues(this, dualSetpointMode);
    }

    getCorrectOverrideProperties(dualSetpointMode?) {

        return this.rmsMczCommonMethods.getCorrectOverrideProperties(dualSetpointMode);
    }

    isAutoModeStyleDualSetpoint() {

        return this.rmsMczCommonMethods.isAutoModeStyleDualSetpoint(this.grp);
    }

    // ------------------------------------------------------------------------------------------------------------------------
    // --- Used in both zones and system-zones --------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------------------

    // Update zone item sch style
    zonScheduleStyle = () => {

        let style = 'thermal-disabled';

        if (this.RTU_RsIR) {

            style = 'thermal-in-range';
        }

        return style;
    }

    // onChangeSCH = (table: string, item: string, values: any) -> Change Schedule from Schedule Component
    onChangeSchedule = async (event, MCU: MCU, store: Store<AppState>, translate: TranslateService, alertController: AlertController) => {

        const season = this.grp.RTU_SeasonRef
        const table = event.table;
        const values = event.values;

        if (!table) {

            return
        }

        let scheduleIdx;

        if (season === 0 || MCU.TEC_SPMode) {

            scheduleIdx = values['PAR_IdxSCH_H'];

        } else {

            scheduleIdx = values['PAR_IdxSCH_C'];
        }

        if (this.isAutoModeStyleDualSetpoint()) {

            // When I click the toggle to turn ON or OFF a schedule, only "PAR_SchedOnH" or "PAR_SchedOnC" will be set as true or false by design.
            // On Dual setpoint mode though, I want both of them to turn ON or OFF, when I click the toggle.
            // This workaround make sure that when one is turned ON and the other is off, it will be turned ON too,and vice-versa.
            let schedulationTurnedOn = undefined;

            if (event?.values?.PAR_SchedOnH !== undefined) {

                schedulationTurnedOn = event.values.PAR_SchedOnH;
            }

            if (event?.values?.PAR_SchedOnC !== undefined) {

                schedulationTurnedOn = event.values.PAR_SchedOnC;
            }

            // if I actually receive the correct values (should be everytime)
            if (schedulationTurnedOn !== undefined) {

                // this is the case if I've turned ON a schedule, and it turned on either PAR_SchedOnH or PAR_SchedOnC
                if (schedulationTurnedOn) {

                    store.dispatch(new ChangeTableValues("RMS", this.id, {PAR_SchedOnH: true, PAR_SchedOnC: true}));
                }

                // this is the case if I've turned OFF a schedule, and it turned off either PAR_SchedOnH or PAR_SchedOnC
                else {

                    store.dispatch(new ChangeTableValues("RMS", this.id, {PAR_SchedOnH: false, PAR_SchedOnC: false}));
                }
            }
        }

        await this.checkScheduleSeason(scheduleIdx, season, MCU, store, translate, alertController);
    }

    /*
    * Check and fix season difference Schedulation
    */
    async checkScheduleSeason(currentScheduleIdx, season: number, MCU: MCU, store: Store<AppState>, translate: TranslateService, alertController: AlertController) {

        if (MCU.TEC_SPMode) {

            this.PAR_IdxSCH_C = this.PAR_IdxSCH_H = currentScheduleIdx;

            store.dispatch(new ChangeTableValues("RMS", this.id,
                {
                    PAR_IdxSCH_H: currentScheduleIdx,
                    PAR_IdxSCH_C: currentScheduleIdx
                }));

            return;
        }

        const otherSeason = (season == 0) ? translate.instant('COOLING_MODE') : translate.instant('HEATING_MODE');

        const changeScheduleCancelButton = this.changeScheduleCancelButton(translate);
        const changeScheduleDisableButton = this.changeScheduleDisableButton(currentScheduleIdx, translate, store);
        const changeScheduleApplyButton = this.changeScheduleApplyButton(currentScheduleIdx, translate, store);

        if (this.hasVariableSchedule && currentScheduleIdx === -1 && this.PAR_IdxSCH_H !== this.PAR_IdxSCH_C) {

            const popup = await alertController.create({
                header: translate.instant('SCHEDULE_SEASON_CHANGE'),
                message: translate.instant('SCHEDULE_SEASON_DISABLED') + otherSeason + translate.instant('SCHEDULE_SEASON_CHANGE3') + "?",
                buttons: [changeScheduleDisableButton, changeScheduleCancelButton]
            });

            await popup.present();
        }

        if (currentScheduleIdx !== -1 && this.sch) {

            const value: BitArray = new BitArray(2).fromNumber(this.sch.PAR_Season);

            if (!(value.get(0) && value.get(1))) {

                return;
            }

            if (this.sch.CFG_Type !== SchedulationType.Variable && this.sch.CFG_Type !== SchedulationType.DualVariable) {

                this.hasVariableSchedule = false;

            }

            else {

                // current schedulation not is disabled or not is an on/ff schedule and
                if (this.PAR_IdxSCH_C != this.PAR_IdxSCH_H) {

                    // Old state have variabile schedule, but now is disabled
                    const popup = await alertController.create({
                        header: translate.instant('SCHEDULE_SEASON_CHANGE'),
                        message: translate.instant('SCHEDULE_SEASON_CHANGE1') + this.sch.CFG_Name + translate.instant('SCHEDULE_SEASON_CHANGE2') + otherSeason + translate.instant('SCHEDULE_SEASON_CHANGE3') + "?",
                        buttons: [changeScheduleApplyButton, changeScheduleCancelButton]
                    });

                    await popup.present();

                }

                else {

                    this.hasVariableSchedule = true;
                }
            }
        }
    }


    // Cancel Button
    changeScheduleCancelButton(translate: TranslateService) {

        return {
            text: translate.instant('NO'),

            handler: ids => {

                this.hasVariableSchedule = this.sch && (this.sch.CFG_Type === SchedulationType.Variable || this.sch.CFG_Type === SchedulationType.DualVariable);
            }
        }
    }

    /*
    * Disable Schedulation Button
    */
    changeScheduleDisableButton(currentScheduleIdx, translate: TranslateService, store: Store<AppState>) {
        return {

            text: translate.instant('YES'),

            handler: ids => {

                store.dispatch(new ChangeTableValues("RMS", this.id,
                    {
                        PAR_IdxSCH_H: currentScheduleIdx,
                        PAR_IdxSCH_C: currentScheduleIdx
                    }));

                this.hasVariableSchedule = false;
            }
        }
    }

    /*
    * Apply Schedulation Button
    */
    changeScheduleApplyButton(currentScheduleIdx, translate: TranslateService, store: Store<AppState>) {

        return {

            text: translate.instant('APPLY_SCHEDULE'),

            handler: ids => {

                store.dispatch(new ChangeTableValues("RMS", this.id,
                    {
                        PAR_IdxSCH_H: currentScheduleIdx,
                        PAR_IdxSCH_C: currentScheduleIdx
                    }));

                this.hasVariableSchedule = true;
            }
        }
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- ARS -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class ARS_Extended extends ARS {

    public atuList: ATU[];
    public grp: GRP_Extended;
    public sch: SCH;
    public isSecondaryARS: boolean;
    public realCanHum: boolean;
    public realCanNtd: boolean;

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------
    static createExtendedARS(ars: ARS, atuList: ATU[], grpList: GRP[], schList: SCH[], hysList: HYS[], isSecondaryARS = false): ARS_Extended {

        let arsExtended = new ARS_Extended();
        Object.assign(arsExtended, ars);

        // --- Add: ATUs to ARS ----------------------------------------------------------------------------------------
        arsExtended.atuList = getListATU();

        // --- Add: Group to ARS ---------------------------------------------------------------------------------------
        arsExtended.grp = getAirUnitGRP();

        // --- Add: Schedule to ARS ------------------------------------------------------------------------------------
        arsExtended.sch = getSchedule();

        // --- isSecondaryARS ------------------------------------------------------------------------------------------
        arsExtended.isSecondaryARS = isSecondaryARS;

        // --- realCanHum ----------------------------------------------------------------------------------------------
        arsExtended.realCanHum = getRealCanHumNtd(arsExtended.atuList).realCanHum;

        // --- realCanNtd ----------------------------------------------------------------------------------------------
        arsExtended.realCanNtd = getRealCanHumNtd(arsExtended.atuList).realCanNtd;

        return arsExtended;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- ATU[] ---------------------------------------------------------------------------------------------------
        function getListATU(): ATU[] {

            return _.filter(atuList, (atu: ATU) => {

                return atu.CFG_IdxARS == ars.id;
            });
        }

        // --- GRP -----------------------------------------------------------------------------------------------------
        function getAirUnitGRP(): GRP_Extended {

            return GRP_Extended.createExtendedGRP(grpList[ars.CFG_IdxGRP]);
        }

        // --- SCH -----------------------------------------------------------------------------------------------------
        function getSchedule(): SCH {

            if (ars.PAR_IdxSCH_Ci !== -1) {

                return schList[ars.PAR_IdxSCH_Ci];
            }
        }

        // --- getRealCanHumNtd ----------------------------------------------------------------------------------------
        function getRealCanHumNtd(connectedATUs: ATU[]) {

            let realCanHum = false;
            let realCanNtd = false;

            //season 0 H; 1 C;
            for (let i = 0; i < connectedATUs.length; i++) {

                let season = 0;

                if (connectedATUs[i].CFG_IdxHYS != -1) {

                    season = grpList[hysList[connectedATUs[i].CFG_IdxHYS].CFG_IdxGRP].RTU_SeasonExe;

                } else if (connectedATUs[i].CFG_IdxARS != -1) {

                    season = grpList[ars.CFG_IdxGRP].RTU_SeasonExe;
                }

                const bitarrayCanHum = new BitArray(24).fromNumber(connectedATUs[i].CFG_CanHum);
                const bitarrayCanNtd = new BitArray(24).fromNumber(connectedATUs[i].CFG_CanNtd);
                realCanHum = bitarrayCanHum.get(season) || realCanHum;
                realCanNtd = bitarrayCanNtd.get(season) || realCanNtd;
            }

            return {realCanHum: realCanHum, realCanNtd: realCanNtd};
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- METHODS -----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    getIntegrationStyle() {

        switch (this.RTU_Flags[FlagsGroup.INT]) {

            case FlagsINT.H:
                return 'messana-orange';

            case FlagsINT.C:
                return 'messana-blue';

            default:
                return 'messana-disabled';
        }
    }

    integrationIsOn() {

        return this.getIntegrationStyle() !== 'messana-disabled';
    }

    scheduleIsInRange() {

        return this.sch && this.sch.RTU_On && this.PAR_CiSchedOn;
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- ENR -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class ENR_Extended extends ENR {

    public isModbusHP = false; // Spacepak - Phoenix - SpheraEvo2 - Solar Thermal Plus - Viessmann
    public isAdvancedModbusHP = false; // Phoenix - SpheraEvo2 - Solar Thermal Plus - Viessmann
    public hasAdvancedSetup = false;  // Phoenix - Viessmann

    public tnkList: TNK_Extended[];
    public dhwList: DHW[];
    public RTU_OutputStatus_BitArray: BitArray;
    public RTU_StateStatus_BitArray: BitArray;
    public TEC_ManualControl_BitArray: BitArray;
    public readonly bitShort = 16;

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedENR(enr: ENR, tnkList: TNK[], dhwList: DHW[], grpList: GRP[]): ENR_Extended {

        let enrExtended = new ENR_Extended();
        Object.assign(enrExtended, enr);

        enrExtended.RTU_OutputStatus_BitArray = new BitArray(enrExtended.bitShort).fromNumber(enr.RTU_OutputStatus);
        enrExtended.RTU_StateStatus_BitArray = new BitArray(enrExtended.bitShort).fromNumber(enr.RTU_StateStatus);
        enrExtended.TEC_ManualControl_BitArray = new BitArray(enrExtended.bitShort).fromNumber(enr.TEC_ManualControl);

        // Tank List
        enrExtended.tnkList = getEnrTankList();

        // Dhw List
        enrExtended.dhwList = getEnrDhwList();

        // Modbus HP settings
        enrExtended.isModbusHP = ENR_Extended.isModbusHP(enr) // Phoenix - SpheraEvo2 - Spacepak - Ritter Thermal Plus
        enrExtended.isAdvancedModbusHP = ENR_Extended.isAdvancedModbusHP(enr); // Phoenix - SpheraEvo2 - Spacepak
        enrExtended.hasAdvancedSetup = ENR_Extended.hasAdvancedSetup(enr); // Phoenix only

        return enrExtended;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- getEnrTankList ------------------------------------------------------------------------------------------
        function getEnrTankList(): TNK_Extended[] {

            // Filter the TNK[] tnkList -> add a TNK in the enrExtended.tnkList if you find the IdxENR
            const tnkListFiltered = _.filter(tnkList, (tnk: TNK) => {

                // If an ENR is applied to a TNK, it returns the index -> if it's not applied is undefined
                return _.find(tnk.CFG_IdxENR, (idxEnr) => idxEnr == enrExtended.id) !== undefined;

            }) as TNK_Extended[];

            return TNK_Extended.createExtendedTNKList(tnkListFiltered, [], grpList);
        }

        // --- getEnrDhwList ------------------------------------------------------------------------------------------
        function getEnrDhwList(): DHW[] {

            // Filter the DHW[] dhwList -> add a DHV in the enrExtended.dhvList if you find the IdxENR
            return _.filter(dhwList, (dhw: DHW) => {

                // If an ENR is applied to a DHW, it returns the index -> if it's not applied is undefined
                return _.find(dhw.CFG_IdxENR, (idxEnr) => idxEnr == enrExtended.id) !== undefined;
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION (LIST - UNUSED) ------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedENRList(enrList: ENR_Extended[], tnkList: TNK[], dhwList: DHW[], grpList: GRP[]): ENR_Extended[] {

        for (let i = 0; i < enrList.length; i++) {

            enrList[i] = ENR_Extended.createExtendedENR(enrList[i], tnkList, dhwList, grpList);
        }

        return enrList;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- Static methods ----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static isModbusHP(enr: ENR) {

        return enr.CFG_Ctrl === 1 && (enr.CFG_Model === HeatPumpModels.SpacePakSIM || enr.CFG_Model === HeatPumpModels.PhinxHeroPlus || enr.CFG_Model === HeatPumpModels.SpheraEvo2 || enr.CFG_Model === HeatPumpModels.SolarThermalPlus || enr.CFG_Model === HeatPumpModels.Viessmann);
    }

    // -----------------------------------------------------------------------------------------------------------------

    static isAdvancedModbusHP(enr: ENR) {

        return enr.CFG_Ctrl === 1 && (enr.CFG_Model === HeatPumpModels.PhinxHeroPlus || enr.CFG_Model === HeatPumpModels.SpheraEvo2 || enr.CFG_Model === HeatPumpModels.SolarThermalPlus || enr.CFG_Model === HeatPumpModels.Viessmann);
    }

    // -----------------------------------------------------------------------------------------------------------------

    static hasAdvancedSetup(enr: ENR) {

        return enr.CFG_Ctrl === 1 && (enr.CFG_Model === HeatPumpModels.PhinxHeroPlus || enr.CFG_Model === HeatPumpModels.Viessmann);
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- FNC -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class FNC_Extended extends FNC {

    public grp: GRP_Extended;
    public othersRmsList: RMS_Extended[] = []; // in which RMSs this fan coil is present, except the current one (used in the integration operative modes checks)

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedFNC(fnc: FNC, grp: GRP[]): FNC_Extended {

        let fncExtended = new FNC_Extended();
        Object.assign(fncExtended, fnc);

        // --- Add: Group to FNC ---------------------------------------------------------------------------------------
        fncExtended.grp = getFancoilGRP();

        return fncExtended;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- GRP -----------------------------------------------------------------------------------------------------
        function getFancoilGRP(): GRP_Extended {

            return GRP_Extended.createExtendedGRP(grp[fnc.CFG_IdxGRP]);
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION (LIST) ---------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedFNCList(fncList: FNC_Extended[], grp: GRP[]): FNC_Extended[] {

        for (let i = 0; i < fncList.length; i++) {

            fncList[i] = FNC_Extended.createExtendedFNC(fncList[i], grp);
        }

        return fncList;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- MODE FC -----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    operativeThermalModesFncIsDisabled() {

        return this.getOperativeThermalModesFncText() === 'NOT_AVAILABLE';
    }

    getOperativeThermalModesFncText() {

        let modeBitArray = new BitArray(25).fromNumber(this.CFG_ModeFC);

        let heatingMode = modeBitArray.get(0);// || modeBitArray.get(2);
        let coolingMode = modeBitArray.get(1);// || modeBitArray.get(3);

        if (heatingMode && coolingMode) {

            return 'H_AND_C_SHORT';
        }

        if (heatingMode) {

            return 'H_SHORT';
        }

        if (coolingMode) {

            return 'C_SHORT';
        }

        return 'NOT_AVAILABLE';
    }

    // -----------------------------------------------------------------------------------------------------------------

    // isHeating (if false = cooling) - isCFG is CFG (if false = PAR)
    hasIntegrationMode(isHeating, isCFG) {

        let modeBitArray = new BitArray(25).fromNumber(this.PAR_ModeFC);

        if (isCFG) {

            modeBitArray = new BitArray(25).fromNumber(this.CFG_ModeFC);
        }

        if (isHeating) {

            // heating
            return modeBitArray.get(2);
        }

        // cooling
        return modeBitArray.get(3);
    }

    // -----------------------------------------------------------------------------------------------------------------

    canShowIntegrationMode() {

        return this.hasIntegrationMode(true, true) || this.hasIntegrationMode(false, true)
    }

    // -----------------------------------------------------------------------------------------------------------------

    integrationModeIsDisabled(isHeating) {

        // If integration mode isn't present -> show the item as disabled (Explanation (example): If I've the COOLING integration mode active, I've to show the heating integration mode regardless if it exists. If it doesn't exists I've to show it disabled)
        return !this.hasIntegrationMode(isHeating, true) || !this.PAR_On;
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- GRP -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class GRP_Extended extends GRP {

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedGRP(grp: GRP): GRP_Extended {

        let grp_extended = new GRP_Extended();
        Object.assign(grp_extended, grp);
        return grp_extended;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CSS METHODS -------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------
    getParSeasonIcon() {

        let icon = 'fa-circle ';

        switch (this.RTU_SeasonExe) {

            case 0:
                return icon += 'messana-orange';

            case 1:
                return icon += 'messana-blue';
        }
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- HYS -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class HYS_Extended extends HYS {

    public grp: GRP_Extended;
    public enrList: ENR[];
    public tnk_H: TNK_Extended;
    public tnk_C: TNK_Extended;

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedHYS(hys: HYS, grpList: GRP[], enrList: ENR[], tnkList: TNK[]): HYS_Extended {

        let grp_extended = new HYS_Extended();
        Object.assign(grp_extended, hys);

        // Group
        grp_extended.grp = getGroupHYS();

        // ENR
        grp_extended.enrList = getEnrList();

        // TNK_H
        grp_extended.tnk_H = getTnkHeating();

        // TNK_C
        grp_extended.tnk_C = getTnkCooling();

        return grp_extended;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- GRP -----------------------------------------------------------------------------------------------------
        function getGroupHYS(): GRP_Extended {

            let grp_hys = grpList[hys.CFG_IdxGRP];

            return GRP_Extended.createExtendedGRP(grp_hys);
        }

        // --- ENR[]----------------------------------------------------------------------------------------------------
        function getEnrList(): ENR[] {

            // Just like the FNC[] --> Cycling every ENR -> if you find that the enr.id is present in the HYS.CFG_IdxENR (which is a short array) -> add that ENR to the HYS
            return _.filter(enrList, (enr: ENR) => {

                return _.find(hys.CFG_IdxENR, (idxENR) => idxENR == enr.id) !== undefined;
            });
        }

        // --- TNK_H ---------------------------------------------------------------------------------------------------
        function getTnkHeating(): TNK_Extended {

            let tnk_h = tnkList[hys.CFG_IdxTNK_H];

            if (tnk_h) {

                return TNK_Extended.createExtendedTNK(tnk_h, enrList, grpList);
            }
        }

        // --- TNK_C ---------------------------------------------------------------------------------------------------
        function getTnkCooling(): TNK_Extended {

            let tnk_c = tnkList[hys.CFG_IdxTNK_C];

            if (tnk_c) {

                return TNK_Extended.createExtendedTNK(tnk_c, enrList, grpList);
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- EXTRA FUNCTIONS ---------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    getCounterOperativeModesArray() {

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT CLASS -------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        class OperativeMode {

            constructor(public id, public mode) {
            }

            static createOperativeModeH(): OperativeMode {

                return new OperativeMode(1, 'H_SHORT'); // translated: H (en) C (it)
            }

            static createOperativeModeC(): OperativeMode {

                return new OperativeMode(2, 'C_SHORT'); // translated: C (en) F (it)
            }

            static createOperativeModeHAndC(): OperativeMode {

                return new OperativeMode(3, 'H_AND_C_SHORT'); // translated: H & C (en) C & F (IT)
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- GET OPERATIVE MODES -------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // The actual array is [H', 'C', 'H & C'] but since in the Redis Database 1 = heating, 2 = cooling, 3 = H&C and I have to MAP the numbers to the index of the array => I've added an unused 'void' at the beginning of the array (could be useful if Redis accepted 0 as a value?)
        // Warning: you have to use the parameter [min]="1" in the counter -> otherwise you can set CFG_Type of ZON to 0 (which Redis doesn't allow)
        // example array: ['void', 'H', 'C', 'H & C'];

        let operativeModesArray = [];

        switch (this.CFG_Type) {

            case 0:
            case 1:
            case 3:

                pushToArrayFromENR(this.enrList);
                break;

            case 2:
            case 4:

                if (this.tnk_H) {

                    pushToArrayFromENR(this.tnk_H.enrList);
                }

                if (this.tnk_C) {

                    pushToArrayFromENR(this.tnk_C.enrList);
                }
                break;
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- RETURN --------------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        let orderedArray = _.sortBy(operativeModesArray, 'id')
        let uniqueArray = _.uniqBy(orderedArray, 'id');

        // If there is both 'H' and 'C' in the array --> add also 'H & C'
        if (uniqueArray.length >= 2) {

            operativeModesArray.push(OperativeMode.createOperativeModeHAndC());
        }

        let operativeModesArrayStrings = [];

        // The counter accepts an array of string -> so I convert the array of objects into an array of strings
        for (let element of uniqueArray) {

            operativeModesArrayStrings.push(element.mode);
        }

        operativeModesArrayStrings.unshift('void'); // I need to add a "dummy" value for the first index of the array --> see the explanation at the top of the getCounterOperativeModesArray()
        return operativeModesArrayStrings;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        function pushToArrayFromENR(enrList: ENR[]) {

            for (let enr of enrList) {

                switch (enr.CFG_Type) {

                    case 0:
                    case 2:

                        operativeModesArray.push(OperativeMode.createOperativeModeH());
                        break;

                    case 1:

                        operativeModesArray.push(OperativeMode.createOperativeModeC());
                        break;

                    case 3:

                        operativeModesArray.push(OperativeMode.createOperativeModeH());
                        operativeModesArray.push(OperativeMode.createOperativeModeC());
                        operativeModesArray.push(OperativeMode.createOperativeModeHAndC());
                        break;
                }
            }
        }
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- TNK -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class TNK_Extended extends TNK {

    public enrList: ENR[];
    public grp: GRP_Extended;

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedTNK(tnk: TNK, enrList: ENR[], grpList: GRP[]): TNK_Extended {

        let tnk_extended = new TNK_Extended();
        Object.assign(tnk_extended, tnk);

        // Enr List
        tnk_extended.enrList = getEnrList();

        // Group List
        tnk_extended.grp = getGroupTNK();

        return tnk_extended;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- GRP -----------------------------------------------------------------------------------------------------
        function getGroupTNK(): GRP_Extended {

            let grp_tnk = grpList[tnk_extended.CFG_IdxGRP];

            return GRP_Extended.createExtendedGRP(grp_tnk);
        }

        // --- ENR -----------------------------------------------------------------------------------------------------
        function getEnrList(): ENR[] {

            // Just like the FNC[] --> Cycling every ENR -> if you find that the enr.id is present in the tnk.CFG_IdxENR (which is a short array) -> add that ENR to the TNK
            return _.filter(enrList, (enr: ENR) => {

                // If a Fan Coil is applied to an RMS, it returns the index -> if it's not applied is undefined
                return _.find(tnk.CFG_IdxENR, (idxENR) => idxENR == enr.id) !== undefined;
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION (LIST) ---------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedTNKList(tnkList: TNK_Extended[], enrList: ENR[], grpList: GRP[]): TNK_Extended[] {

        for (let i = 0; i < tnkList.length; i++) {

            tnkList[i] = TNK_Extended.createExtendedTNK(tnkList[i], enrList, grpList);
        }

        return tnkList;
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- ZON -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class ZON_Extended extends ZON {

    public hys: HYS_Extended

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedZON(zon: ZON, hysList: HYS[], grpList: GRP[], enrList: ENR[], tnkList: TNK[]): ZON_Extended {

        let zon_extended = new ZON_Extended();
        Object.assign(zon_extended, zon);

        // --- Add: Hydronic System ------------------------------------------------------------------------------------
        zon_extended.hys = getHydronicSystemZON();

        return zon_extended;

        // -------------------------------------------------------------------------------------------------------------
        // --- SUPPORT FUNCTIONS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- HYS -----------------------------------------------------------------------------------------------------
        function getHydronicSystemZON(): HYS_Extended {

            return HYS_Extended.createExtendedHYS(hysList[zon.CFG_IdxHYS], grpList, enrList, tnkList);
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION (LIST) ---------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedZONList(zonList: ZON_Extended[], hysList: HYS[], grpList: GRP[], enrList: ENR[], tnkList: TNK[]): ZON_Extended[] {

        for (let i = 0; i < zonList.length; i++) {

            zonList[i] = ZON_Extended.createExtendedZON(zonList[i], hysList, grpList, enrList, tnkList);
        }

        return zonList;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- SUPPORT FUNCTIONS -------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    getCounterOperativeModesArray() {

        // See the explanation in the old "GET OPERATIVE MODES" (Inside HYS_EXTENDED class) to check why I need to add a "void" in the array
        let operativeModesArray = ['void'];

        switch (this.CFG_Type) {

            case 1:

                operativeModesArray.push('H_SHORT');
                break;

            case 2:

                operativeModesArray.push('C_SHORT');
                break;

            case 3:

                operativeModesArray.push('H_SHORT');
                operativeModesArray.push('C_SHORT');
                operativeModesArray.push('H_AND_C_SHORT');
                break;
        }

        return operativeModesArray;
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- TMR -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class TMR_Extended extends TMR {

    public schedule: SCH;
    public timeHolder: number = null; // Used in Timer component
    public inRangeState: boolean = false; // Used in Timer component

    public readonly intenseOrange = '#e47e30';
    public readonly liteOrange = '#fff7e5';

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedTMR(tmr: TMR, schList: SCH[]): TMR_Extended {

        let tmr_extended = new TMR_Extended();
        Object.assign(tmr_extended, tmr);

        // Schedule
        tmr_extended.schedule = getScheduleTMR();
        return tmr_extended;

        // --- SCH -----------------------------------------------------------------------------------------------------
        function getScheduleTMR(): SCH {

            if (tmr.PAR_IdxSCH > -1) {

                return schList[tmr.PAR_IdxSCH];
            }
        }
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- FWA -------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export class FWA_Extended extends FWA {

    public hys: HYS_Extended;

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CREATION ----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    static createExtendedFWA(fwa: FWA, hysList: HYS[], grpList, enrList, tnkList): FWA_Extended {

        let fwaExtended = new FWA_Extended();
        Object.assign(fwaExtended, fwa);

        // Schedule
        fwaExtended.hys = getFloorWarmerHys();
        return fwaExtended;

        // --- SCH -----------------------------------------------------------------------------------------------------
        function getFloorWarmerHys(): HYS_Extended {

            if (fwa.CFG_IdxHYS > -1) {

                return HYS_Extended.createExtendedHYS(hysList[fwa.CFG_IdxHYS], grpList, enrList, tnkList);
            }
        }
    }
}
