import {Platform} from '@ionic/angular';


import {Observable, from} from 'rxjs';

import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {Config} from '../models/config';
import {AppState} from '../services/app-state.service';
import {TablesActions, ChangeTableValues} from '../actions/tables.actions';
import {Tables, Table, SCH, MCZ, TMP} from '../models/tables';
import {ConfigActions, IdleConfig, LogoutConfig} from '../actions/config.actions';

import {ActionSheetController} from '@ionic/angular';
import {AlertController} from '@ionic/angular';

import {ToastController} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser'


import * as _ from 'lodash';

export interface MenuAction {
    text: string;
    handler: any;
    role?: string;
    icon?: string;
    disabled?: boolean;
}


export class AbstractBaseComponent {


    ios: boolean;
    android: boolean;
    app: boolean;

    public readonly emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    constructor(protected store: Store<AppState>,
                protected platform: Platform,
                protected alertController: AlertController,
                protected actionSheetController: ActionSheetController,
                protected translate: TranslateService,
                protected toastCtrl?: ToastController,
                protected sanitized?: DomSanitizer) {

        this.ios = platform.is('ios');
        this.android = platform.is('android');
        this.app = platform.is('cordova') || platform.is('capacitor');
    }

    /**
     * Error toast message
     */
    async presentToast(toastMessage: string, time?) {
        let toast = await this.toastCtrl.create({
            message: toastMessage,
            duration: (time) ? time : 2000,
            position: 'bottom'
        });
        toast.present();
    }


    /**
     * Generic rename popup and action for Tables
     */
    public rename(table: string, id: number, title: string, message: string, items?: Table[]): void {
        // generally in the pages we have RMS, MCZ (arrays) and in components rms, mvz (Table instances)
        let data = this[table] || this[table.toLowerCase()];
        let name: string;
        if (Array.isArray(data)) {
            name = data[id].CFG_Name as string;
        } else {
            name = data.CFG_Name as string;
        }

        this.renamePopup(null, title || 'RENAME', message || 'ASSIGN_NEW_NAME', name,
            name => this.store.dispatch(new ChangeTableValues(table, id, {CFG_Name: name})),
            items, id
        );
    }

    /**
     * Generic rename popup
     */
    async renamePopup($event, title: string, message: string, value: string, callback: any, items?: Table[], id?) {
        let popup = await this.alertController.create({
            header: this.translate.instant(title),
            message: this.translate.instant(message),
            inputs: [
                {
                    name: 'name',
                    value: value
                },
            ],
            buttons: [

                {
                    text: this.translate.instant('SAVE'),
                    handler: async data => {
                        //console.log(data)
                        await popup.dismiss();
                        if (data.name == "") {
                            this.presentToast(this.translate.instant('USED_NAME'));
                            return false;
                        }
                        if (!this.validName(data.name, items, id)) {
                            this.presentToast(this.translate.instant('USED_NAME'));
                            return false;
                        } else
                            callback(data.name);
                    }
                    // data.name = data.name.trim();
                    // if (data.name) {
                    //     callback(data.name);
                    // }
                    // }
                },
                {
                    text: this.translate.instant('CANCEL')
                }
            ]
        });
        await popup.present();
    }

    confirmPopupWithPromise($event, title: string, message: string, action: string, callback: any, callbackCancel: any, cancelText = 'CANCEL', cssClass = 'alert-yes-no') {

        return new Promise(async (resolve) => {

            return await this.confirmPopup($event, title, message, action,

                async () => {
                    await callback();  // Call the provided confirm callback
                    resolve();  // Resolve the promise with true when confirmed
                },
                async () => {
                    await callbackCancel();  // Call the provided cancel callback
                    resolve();  // Resolve the promise with false when canceled
                },
                cancelText, cssClass);
        });
    }

    async confirmPopup($event, title: string, message: string, action: string, callback: any, callbackCancel?, cancelText = 'CANCEL', cssClass = 'alert-yes-no') {

        if ($event) {

            $event.stopPropagation();
        }

        const buttons = [

            {
                text: this.translate.instant(action),
                role: 'ok',
                handler: () => {
                    // console.warn('chiamata callback()');
                    callback();
                }
            },
        ];

        if (cancelText !== '') {

            buttons.push({
                text: this.translate.instant(cancelText),
                role: 'cancel',
                handler: () => {
                    if (callbackCancel) {

                        // console.warn('chiamata cancel callback()');
                        callbackCancel();
                    }
                }
            });
        }

        else {

            cssClass += ' no-cancel-button'; // This fixes the proportion of buttons when having only the "OK" button.
        }

        const alert = await this.alertController.create({
            cssClass: cssClass,
            header: this.translate.instant(title),
            message: this.translate.instant(message),
            buttons: buttons
        });

        await alert.present();
    }

    async showMenu(title: string, actions: MenuAction[]) {

        //console.log(`chiamato metodo showMenu di abstract-base-compoment title vale ${title} e actions`);
        //console.log(actions)

        let buttons = [];

        for (let action of actions) {

            if (action.disabled != true) {

                buttons.push({
                    text: this.translate.instant(action.text),
                    handler: () => {
                        let navTransition = actionSheet.dismiss();
                        navTransition.then(() => {
                            action.handler();
                        });
                    },
                    icon: action.icon,
                    role: action.role
                });
            }
        }

        buttons.push({
            text: this.translate.instant('CANCEL'),
            role: 'cancel', // will always sort to be on the bottom
            cssClass: 'no-background-button',
            icon: !this.platform.is('ios') ? 'close' : null,
        });

        /*  let actionSheet = this.actionSheetController.create({
              title: title,
              cssClass: 'action-sheets-basic-page',
              buttons: buttons
          });
          actionSheet.present();*/

        const actionSheet = await this.actionSheetController.create({
            header: title,
            cssClass: 'alert-yes-no',
            buttons: /*[{
        text: 'Delete',
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          console.log('Delete clicked');
        }
      }, {
        text: 'Share',
        icon: 'share',
        handler: () => {
          console.log('Share clicked');
        }
      }, {
        text: 'Play (open modal)',
        icon: 'arrow-dropright-circle',
        handler: () => {
          console.log('Play clicked');
        }
      }, {
        text: 'Favorite',
        icon: 'heart',
        handler: () => {
          console.log('Favorite clicked');
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]*/
            buttons
        });
        // console.log('await actionSheet.present();')
        await actionSheet.present();
    }

    /**
     * Check Logic version
     */
    ifLogicVersion = (MCU, requiredVersion) => {
        if (!MCU || !MCU.RTT_SWVersionL) return false;
        var cV = parseInt(MCU.RTT_SWVersionL.replace(".", "").replace(".", ""))
        var rV = parseInt(requiredVersion.replace(".", "").replace(".", ""))

        return cV >= rV
    }

    /**
     * Check Required SW version
     */
    ifSofwareVersionReq = (MCU, currentVersion) => {
        //console.log('ifSofwareVersionReq')
        if (!MCU.RTT_SWFrontEnd) return true
        var reqVersions = MCU.RTT_SWFrontEnd.split(".")
        var currVersions = currentVersion.split(".")
        // console.log(reqVersions, currVersions)
        if (parseInt(currVersions[0]) < parseInt(reqVersions[0])) return false
        if (parseInt(currVersions[0]) > parseInt(reqVersions[0])) return true

        if (parseInt(currVersions[1]) < parseInt(reqVersions[1])) return false
        if (parseInt(currVersions[1]) > parseInt(reqVersions[1])) return true

        return parseInt(currVersions[2]) >= parseInt(reqVersions[2])
        // var cV = parseInt(MCU.RTT_SWVersionA_Req.replace(".","").replace(".",""))
        // var rV = parseInt(currentVersion.replace(".","").replace(".",""))
        // console.log("Required", cV,rV)
        // return cV <= rV
    }

    /**
     * Check Optimal SW version
     */
    ifSofwareVersionAdv = (MCU, currentVersion) => {
        if (!MCU || !MCU.RTT_SWVersionA_Opt) return false;
        var cV = parseInt(MCU.RTT_SWVersionA_Opt.replace(".", "").replace(".", ""))
        var rV = parseInt(currentVersion.replace(".", "").replace(".", ""))
        //console.log("Adviced", cV, rV)
        return cV <= rV
    }

    /**
     * Check if new name is a valid name for tables
     */
    validName = (name: string, items: Table[], id: number): boolean => {
        var filtered: Table[]
        if (items != undefined) {
            var item = items[0]
            if (item instanceof SCH) {
                filtered = _.filter(items as SCH[], item => item.id != id && item.CFG_Valid)
            } else if (item instanceof MCZ) {
                filtered = _.filter(items as MCZ[], item => item.id != id && item.CFG_Valid)
            } else if (item instanceof TMP) {
                filtered = _.filter(items as TMP[], item => item.id != id && item.CFG_Valid)
            } else {
                filtered = _.filter(items, item => item.id != id)
            }
        }

        return name.trim() != "" && _.find(filtered, x => x.CFG_Name.toUpperCase() == name.trim().toUpperCase()) == null
    }


  /**
   * Show logout confim popup up
   */
  logout = (config,$event?): void => {

    console.log($event);

    if ($event) {
      $event.stopPropagation();
    }
    this.store.dispatch(new IdleConfig());

    const message = `${this.translate.instant('LOGOUT_CONFIRM')} ${config.currentUser?.username}?`

    this.confirmPopup($event, 'LOGOUT', message, 'LOGOUT', () => {

      this.store.dispatch(new LogoutConfig());
    });

  }



}
