export const CENSUS_TRANSLATIONS_IT = {
  "NEW_USERNAME": "Nuovo Nome Utente",
  "USERNAME": "Nome Utente",

  "CENSUS_COMPLETED": "Censimento Completato",
  "CENSUS_EXCEPTION": "Processo di Censimento Interrotto da un'eccezione",
  "CENSUS_FAILURE": "Processo di Censimento Non Riuscito",
  "MASTER_NS_SYNCHRONIZED_IS_OTHER_USER": "",
  "MASTER_NS_SYNCHRONIZED_IS_CURRENT_USER": "",
  "VALIDATION_ERROR_ALERT_TITLE": "Errore di Validazione",
  "VALIDATION_CODE_GENERATION": "Errore nella Generazione del Codice di Validazione",
  "ERROR_CREDENTIALS": "Errore Credenziali",

  "CENSUS_HEADER_MASTER_PAGE_01": "Processo di Censimento (1/",
  "CENSUS_HEADER_RES_SER_PAGE_01": "Processo di Censimento (1/",
  "CENSUS_HEADER_RES_SER_PAGE_02": "Processo di Censimento (2/",
  "CENSUS_HEADER_RES_SER_PAGE_03": "Processo di Censimento (3/",
  "CENSUS_HEADER_RES_SER_PAGE_04": "Processo di Censimento (",
  "CENSUS_HEADER_MASTER_PAGE_02": "Processo di Censimento (2/",
  "CENSUS_HEADER_MASTER_PAGE_CONFIRMATION": "Processo di Censimento (2/",
  "CENSUS_HEADER_USER_NOT_DESIGNATED": "Processo di Censimento (2/",
  "CENSUS_HEADER_MASTER_PAGE_VALIDATION": "Processo di Censimento (3/",
  "CENSUS_HEADER_MASTER_PAGE_MAIL_ADDRESS_FORMAT_01": "Processo di Censimento (3/",
  "CENSUS_HEADER_MASTER_PAGE_MAIL_ADDRESS_FORMAT_02": "Processo di Censimento (4/",
  "CENSUS_HEADER_MASTER_PAGE_USERNAME_UPDATED": "Processo di Censimento (5/",
  "CENSUS_HEADER_MASTER_PAGE_DATA_REVIEW": "Processo di Censimento (4/",

  "CENSUS_EXPLAIN_MASTER_DATA_REVIEW_TITLE": "Revisione Dati Personali",
  "UPDATE_PERSONAL_DATA": "Aggiorna Dati Personali",
  "CONFIRM_DATA": "Conferma Dati Personali",

  "CENSUS_EXPLAIN_MASTER_TITLE": "Censimento Utente Master",
  "CENSUS_EXPLAIN_RES_SER_PAGE_01_TITLE": "Censimento Utenti Messana",
  "CENSUS_EXPLAIN_RES_SER_PAGE_02_TITLE": "Processo di Censimento: Utenti di Residenza / Servizio",
  "CENSUS_EXPLAIN_RES_SER_PAGE_03_TITLE": "Processo di Censimento: Raccolta Indirizzo E-mail",
  "CENSUS_EXPLAIN_RES_SER_PAGE_04_TITLE": "Completamento del Processo di Censimento",
  "CENSUS_EXPLAIN_MASTER_CONFIRMATION_TITLE": "Conferma Utente Master",
  "CENSUS_EXPLAIN_MASTER_QUERY_TITLE": "Richiesta Utente Master",
  "CENSUS_EXPLAIN_MASTER_MAIL_ADDRESS_FORMAT_01_TITLE": "Conformità del Formato del Nome Utente",
  "CENSUS_EXPLAIN_MASTER_MAIL_ADDRESS_FORMAT_02_TITLE": "Attivazione dell'Account",
  "CENSUS_EXPLAIN_MASTER_VALIDATION_CODE_VERIFICATION_TITLE": "Verifica del Codice",
  "CENSUS_EXPLAIN_MASTER_VALIDATION_TITLE": "Verifica dell'Account",
  "CENSUS_EXPLAIN_MASTER_USERNAME_UPDATE_TITLE": "Aggiornamento Nome Utente Completato",

  "CENSUS_EXPLAIN_RES_SER_CATEGORY_01": "<p>Siamo lieti di confermare che l'Utente Master per questo sistema è stato identificato con successo. Tuttavia, il tuo account utente non è ancora stato classificato in una categoria specifica.</p>" +

    "<p>Per proseguire con il censimento degli utenti, ti chiediamo gentilmente di selezionare il tipo di utente a cui appartieni. Hai la possibilità di scegliere tra due categorie:</p>" +

    "<ul>" +
    "    <li>Utente Residenza: questa categoria include individui che risiedono regolarmente presso la sede del Sistema di Controllo Messana, come familiari, coinquilini o inquilini.</li>" +
    "    <li>Utente Servizio: questa categoria riguarda appaltatori esterni, tecnici, ingegneri o professionisti che necessitano di accesso al Sistema di Controllo Messana per manutenzione, progettazione, risoluzione di problemi o altre attività legate ai servizi.</li>" +
    "</ul>" +
    "<p>Ti preghiamo di prendere un momento per rivedere e confermare il tipo di utente appropriato compilando il modulo sottostante.</p>",

  "CENSUS_EXPLAIN_RES_SER_01": "<p>Ti contattiamo per informarti di un importante aggiornamento riguardante la nostra piattaforma del Sistema di Controllo e gli utenti associati ad essa. Per garantire una gestione accurata ed efficiente del tuo sistema, stiamo conducendo un censimento degli utenti. Questo processo prevede la verifica e l'aggiornamento dell'elenco degli utenti che hanno accesso al tuo Sistema di Controllo Messana.</p>" +
    "    <p>Come parte di questo censimento, dobbiamo confermare i dettagli di tutti gli utenti, inclusi i seguenti tipi di utenti:</p>\n" +
    "    <ul>" +
    "        <li>" +
    "            <b>Utente Master</b>: L'Utente Master è il contatto principale e amministratore del Sistema di Controllo, con il più alto livello di autorità e responsabilità. Questo include la gestione degli aspetti finanziari e l'autorità esclusiva di aggiungere, modificare o rimuovere sia gli Utenti Residenza che gli Utenti Servizio. Per sbloccare il censimento degli Utenti Residenza e Utenti Servizio, è richiesta la verifica dell'Utente Master.\n" +
    "        </li>" +
    "        <li>" +
    "            <b>Utenti Residenza</b>: Individui che risiedono regolarmente presso la sede del Sistema di Controllo Messana, come familiari, coinquilini o inquilini. Hanno una presenza permanente o semi-permanente in casa e l'accesso è concesso dall'Utente Master.\n" +
    "        </li>\n" +
    "        <li>\n" +
    "            <b>Utenti Servizio</b>: Appaltatori esterni, tecnici, ingegneri o professionisti che necessitano di accesso al Sistema di Controllo Messana per manutenzione, progettazione, risoluzione di problemi o altre attività legate ai servizi. Il loro accesso è gestito e concesso dall'Utente Master.\n" +
    "        </li>\n" +
    "    </ul>" +
    "    <p>Come parte di questo censimento, se necessario, agli utenti sarà richiesto di verificare e aggiornare il loro indirizzo email.</p>"+
    "<p>La tua collaborazione nell'aggiornamento di queste informazioni è molto apprezzata. Ti preghiamo di prendere un momento per rivedere e confermare i seguenti dettagli relativi al tuo stato utente nel nostro sistema:</p>"+
    "<ul>"+
    "<li>Tipo Utente: [Utente Master / Utente Residenza / Utente Servizio]</li>"+
    "<li>Descrizione del Ruolo (se applicabile): familiari, coinquilini o inquilini per Utente Residenza; appaltatore esterno, tecnico, ingegnere, professionista per Utente Servizio.</li>"+
    "</ul>",

  "START_USER_CENSUS": "Inizia Censimento Utente",

  "CENSUS_EXPLAIN_MASTER_01": "<p>Ti contattiamo per informarti di un importante aggiornamento riguardante la nostra piattaforma del Sistema di Controllo e gli utenti associati ad essa.</p>" +
    "   <p>Per garantire una gestione accurata ed efficiente del tuo sistema, stiamo conducendo un censimento degli utenti. Questo processo prevede la verifica e l'aggiornamento dell'elenco degli utenti che hanno accesso al tuo Sistema di Controllo Messana. Come parte di questo censimento, dobbiamo confermare i dettagli di tutti gli utenti, inclusi i seguenti tipi di utenti:</p>"+
    "<ul>"+
    "<li>Utente Master: L'Utente Master è il contatto principale e amministratore del Sistema di Controllo, con il più alto livello di autorità e responsabilità. Questo include la gestione degli aspetti finanziari e l'autorità esclusiva di aggiungere, modificare o rimuovere sia Utenti Residenza che Utenti Servizio. Per sbloccare il censimento per gli Utenti Residenza e Utenti Servizio, è richiesta la verifica dell'Utente Master.</li>"+
    "<li>Utenti Residenza: Individui che risiedono regolarmente presso la sede del Sistema di Controllo Messana, come familiari, coinquilini o inquilini. Hanno una presenza permanente o semi-permanente in casa e l'accesso è concesso dall'Utente Master.</li>"+
    "<li>Utenti Servizio: Appaltatori esterni, tecnici, ingegneri o professionisti che necessitano di accesso al Sistema di Controllo Messana per manutenzione, progettazione, risoluzione di problemi o altre attività legate ai servizi. Il loro accesso è gestito e concesso dall'Utente Master.</li>"+
    "</ul>"+
    "<p>Come parte di questo censimento, se necessario, agli utenti sarà richiesto di verificare e <b>aggiornare il proprio nome utente per conformarsi al formato dell'indirizzo email</b> richiesto dal nostro sistema.</p>"+
    "<p>La tua collaborazione nell'aggiornamento di queste informazioni è molto apprezzata. Ti preghiamo di prendere un momento per rivedere e confermare i seguenti dettagli relativi al tuo stato utente nel nostro sistema:</p>"+
    "<ul>"+
    "<li>Tipo Utente: [Utente Master / Utente Residenza / Utente Servizio]</li>"+
    "<li>Descrizione del Ruolo (se applicabile): familiari, coinquilini o inquilini per Utente Residenza; appaltatore esterno, tecnico, ingegnere, professionista per Utente Servizio.</li>"+
    "</ul>",

  "CENSUS_EXPLAIN_MASTER_02": "Se hai domande riguardanti il tuo stato utente, non esitare a contattare il ",
   "OUR_SUPPORT_PAGE":"nostro team di supporto",
  "CENSUS_EXPLAIN_MASTER_QUERY_DEAR_USER": "Caro ",

  "CENSUS_EXPLAIN_MASTER_QUERY_01_A": "Attualmente stiamo cercando di identificare l'Utente Master per il Sistema di Controllo ",


  "CENSUS_EXPLAIN_USER_NOT_DESIGNATED": "<p>In base al nostro sistema, sembra che tu non sia designato come Utente Master per questo specifico Sistema di Controllo. L'Utente Master detiene il più alto livello di autorità e responsabilità all'interno del sistema, incluso il ruolo di principale punto di contatto, la gestione degli aspetti finanziari e l'autorità esclusiva sulla gestione degli utenti.</p>"+
    "<p>Se non sei l'Utente Master designato per questo sistema, ti preghiamo di avvisare la persona designata per avviare il processo di conferma.</p>"+
    "<p>Per qualsiasi domanda o chiarimento sul tuo ruolo o responsabilità, non esitare a contattare il nostro team di supporto tramite la nostra <a href='https://messana.tech/open-a-support-case/' target='_blank'>pagina di ticket</a>.</p>",

  "CENSUS_EXPLAIN_MASTER_QUERY_01_B": "Secondo i nostri record, questo ruolo non è ancora stato designato.",

  "CENSUS_EXPLAIN_MASTER_QUERY_01_C": "<br><p>Poiché sei attualmente connesso, ti chiediamo gentilmente di confermare se sei l'Utente Master designato per questo specifico Sistema di Controllo. L'Utente Master detiene il più alto livello di autorità e responsabilità all'interno del sistema, inclusi il ruolo di principale punto di contatto, la gestione degli aspetti finanziari e l'autorità esclusiva sulla gestione degli utenti.<br>" +
    "<br>La tua conferma ci aiuterà a mantenere record accurati e ad assicurare che i nostri servizi siano allineati con le tue esigenze. Se non sei l'Utente Master per questo sistema, ti preghiamo di informare l'Utente Master designato per avviare il processo di censimento.</p>",

  "CENSUS_EXPLAIN_MASTER_QUERY_01_D": "Se hai domande o hai bisogno di ulteriori chiarimenti riguardo al tuo ruolo o alle tue responsabilità, non esitare a contattare il nostro team di supporto tramite la nostra ",

  "SUPPORT_CASE_PAGE": "pagina di assistenza",

  "QUIT_USER_CENSUS": "Esci dal Censimento Utente",

  "THANKS_COOP": "Grazie per la tua collaborazione.",

  "START_MASTER_USER_CONFIRMATION": "Conferma Utente Master",

  "SEND_VERIFICATION_CODE": "Invia Codice di Verifica",

  "RESEND_VERIFICATION_CODE": "Reinvia Codice di Verifica",

  "CENSUS_EXPLAIN_MAIL_ADDRESS_FORMAT_01": "<p>Abbiamo notato che il tuo attuale nome utente non è nel formato di un indirizzo email, che è richiesto dagli standard del nostro sistema per l'autenticazione e la comunicazione. Per procedere, ti chiediamo gentilmente di aggiornare il tuo nome utente selezionando un indirizzo email valido.</p>" +
    "<p>Nota che dovrai inserire la tua <b>attuale</b> password nel campo della password per verificare la tua identità durante questo processo di aggiornamento.</p>"+
    "<p>Una volta aggiornato il tuo nome utente, invieremo un codice di verifica a 6 cifre all'indirizzo email fornito per scopi di autenticazione. Questo codice sarà utilizzato per validare e confermare la tua identità in modo sicuro.</p>",

  "CENSUS_EXPLAIN_MAIL_ADDRESS_FORMAT_02": "<p>Una email è stata appena inviata all'indirizzo email che hai fornito: {{e-mail-address}}. All'interno di questa email troverai un codice di verifica a 6 cifre richiesto per completare il processo in corso.</p>" +
    "<p>Controlla la tua casella di posta (e anche la cartella spam/junk se necessario) per l'email contenente il codice di verifica. Copia il codice di verifica a 6 cifre e incollalo nel campo designato qui sotto per procedere:</p>",

  "CENSUS_EXPLAIN_MAIL_ADDRESS_FORMAT_02_NOT_RECEIVED": "<p>Se non ricevi l'email entro pochi minuti, puoi richiedere di reinviare il codice di verifica.</p>",

  "CENSUS_EXPLAIN_MASTER_VALIDATION": "<p>Per garantire la sicurezza del tuo account e verificare la tua identità, ti chiediamo gentilmente di autenticarti nuovamente. Una volta inserite le tue credenziali e premuto il pulsante 'Invia Codice di Verifica', un codice di verifica a 6 cifre sarà inviato all'indirizzo email associato al tuo nome utente.</p>" +
    "<p>Controlla la tua casella di posta (e la cartella spam/junk se necessario) per l'email contenente il codice di verifica. Dopo aver ricevuto il codice, inseriscilo nei campo designato qui sotto per procedere con la verifica.</p>",

  "CENSUS_EXPLAIN_MASTER_VALIDATION_VALID_ACCOUNT_01": "<p>Una email è stata appena inviata all'indirizzo email che hai fornito:</p>",

  "CENSUS_EXPLAIN_MASTER_VALIDATION_VALID_ACCOUNT_02": "<p>All'interno di questa email troverai un codice di verifica a 6 cifre richiesto per completare il processo in corso.</p>",

  "CENSUS_EXPLAIN_MASTER_VALIDATION_VALID_ACCOUNT_03": "<br><p>Controlla la tua casella di posta (e anche la cartella spam/junk se necessario) per l'email contenente il codice di verifica. Copia il codice di verifica a 6 cifre e incollalo nel campo designato qui sotto per procedere:</p>",

  "CENSUS_EXPLAIN_MASTER_VALIDATION_VALID_ACCOUNT_04": "<p>Se non ricevi l'email entro pochi minuti, puoi richiedere di reinviare il codice di verifica.</p>",

  "CENSUS_EXPLAIN_MASTER_PAGE_USERNAME_UPDATED": "<p>Il tuo nuovo nome utente è stato aggiornato con successo.</p>"+
    "<p>Per finalizzare questa modifica e procedere con la procedura in corso, ti disconnetteremo ora dal tuo account.</p>"+
    "</p>Effettua nuovamente l'accesso utilizzando il tuo nome utente e password aggiornati per continuare con il processo in corso.</p>",

  "CENSUS_EXPLAIN_MASTER_CONFIRMATION_01": "Secondo i nostri record, sei attualmente identificato come l'Utente Master per il Sistema di Controllo selezionato",

  "CENSUS_EXPLAIN_MASTER_CONFIRMATION_02": "<p>Come Utente Master, detieni il più alto livello di autorità e accesso all'interno di questo sistema. Le tue responsabilità includono agire come principale punto di contatto, gestire gli aspetti finanziari relativi al sistema e avere l'autorità esclusiva sulla gestione degli utenti.</p>" +
    "<p>Per garantire precisione e sicurezza, ti chiediamo gentilmente di confermare queste informazioni. Facci sapere se riconosci il tuo ruolo come Utente Master per il sistema specificato. La tua conferma ci aiuterà a mantenere record accurati e a garantire che i nostri servizi siano allineati con le tue esigenze.</p>" +
    "<p>Se hai domande o hai bisogno di ulteriori chiarimenti riguardo al tuo ruolo o responsabilità, non esitare a <a href='https://messana.tech/open-a-support-case/' target='_blank'>contattare</a> il nostro team di supporto.</p>",




    "USER_TYPE": "Tipo di utente",
    "RESIDENCE_USER": "Utente Residenziale",
    "SERVICE_USER": "Utente Service",
    "RESIDENCE_TYPE": "Residenziale",
    "FAMILY_MEMBER": "Membro della Famiglia",
    "HOUSEMATE": "Coinquilino",
    "RENTER": "Affittuario",
    "SERVICE_TYPE": "Service",
    "EXTERNAL_CONTRACTOR": "Appaltatore Esterno",
    "TECHNICIAN": "Tecnico",
    "ENGINEER": "Ingegnere",
    "PROFESSIONAL": "Professionista",
    "INSTALLER:":"Installatore",
    "NULL_KEY":"",
    "CHOOSE_USER_TYPE": "Scegli tipo utenza",
    "CHOOSE_RESIDENCE_TYPE": "Scegli il dettaglio",
    "CHOOSE_SERVICE_TYPE": "Scegli il dettaglio",
  "CONFIRM_SERVICE_USER_CATEGORY": "Conferma categoria utente servizio",
  "CONFIRM_RESIDENCE_USER_CATEGORY": "Conferma categoria utente residenziale",
  "CENSUS_EXPLAIN_RES_SER_MAIL_ADDRESS_FORMAT_01": "<p>Secondo i nostri record, non abbiamo un indirizzo email associato al tuo account." +
    "Abbiamo bisogno di un indirizzo email per motivi di sicurezza e per facilitare la funzionalità di reimpostazione della password." +
    "Fornendo il tuo indirizzo email, lo valideremo inviandoti un codice di verifica a 6 cifre." +
    "Dopo aver cliccato il pulsante 'Invia Codice di Verifica', controlla la tua casella di posta (inclusa la cartella spam/junk se necessario) per l'email contenente il codice di verifica." +
    "Una volta ricevuto il codice, inseriscilo negli spazi designati qui sotto.</p>",

  "CENSUS_EXPLAIN_RES_SER_COMPLETION": "<p>Siamo lieti di informarti che il processo di classificazione è stato completato.</p>" +
    "<p>Il tuo account utente è stato ora classificato nella categoria appropriata in base alla tua selezione. Se hai domande o necessiti di ulteriore assistenza riguardo il tuo stato utente o l'accesso al sistema, non esitare a contattare il nostro team di supporto.</p>" +
    "<p>Grazie ancora per la tua partecipazione nel garantire la corretta gestione del tuo Messana Control System.</p>",

  "CENSUS_ERROR_INSTRUCTIONS": "<p>Per aiutarci a comprendere meglio il problema che stai riscontrando, ti invitiamo a fare uno screenshot della pagina attuale e inviarcelo aprendo un ticket di supporto. Questo permetterà al nostro team di supporto di investigare meglio il problema e fornirti una soluzione tempestiva.</p>" +
    "<p>Apprezziamo la tua collaborazione e ci scusiamo per eventuali disagi causati.</p>",

  "CODE_SENT": "Codice di verifica inviato.",
  "CODE_NOT_SENT": "Si è verificato un errore durante l'invio del codice di verifica.",
  "RESIDENCE_STATUS_COMPLETE": "La tua registrazione come utente residenziale è completa.",
  "SERVICE_STATUS_COMPLETE": "La tua registrazione come utente servizio è completa.",

  "CENSUS_EXPLAIN_DATA_REVIEW_01": "<p>Se il tuo account è associato a un'azienda o entità commerciale, fornisci il nome e cognome del rappresentante legale autorizzato.<br>" +
    "Questa persona dovrebbe essere in grado di gestire pagamenti e obblighi per conto dell'azienda.</p>" +
    "<p>Assicurati che il nome e il cognome forniti corrispondano ai dati personali della persona autorizzata a utilizzare una carta di pagamento aziendale/personale o altri metodi di pagamento.</p>",

  "FIRST_NAME": "Nome",
  "LAST_NAME": "Cognome",
  "COMPANY_NAME": "Nome azienda",
  "COMPANY_RELATED": "Questo account è associato a un'azienda o entità commerciale?",
  "ROLE_NAME": "Posizione o ruolo in azienda (es. CEO, CFO, Direttore Generale)",

  "CENSUS_EXPLAIN_DATA_REVIEW_PERSON_02": "<p>Assicurati che il nome e il cognome forniti <b>corrispondano</b> al nome sulla carta di debito o credito che verrà utilizzata per i pagamenti associati a questo account.</p>",

  "UPDATE_COMPLETED": "Aggiornamento completato",
  "UPDATE_EXCEPTION": "Eccezione durante l'aggiornamento",
  "EXCEPTION_CONTACT_WITHOUT_CUSTOMER": "[eccezione] contatto senza cliente;",
  "CLIENT_ANOMALY": "[eccezione] anomalia del cliente;",
  "MASTER_ATTEMPT": "Nel tentativo di associare un utente master, si sono verificati i seguenti eventi:",
  "CURRENT_USER_DATA_UPDATED": "Dati personali aggiornati con successo.",
  "CURRENT_USER_DATA_CONFIRMED":"Dati personali confermati con successo.",
  "PHONE_NUMBER": "Numero di telefono"
}
