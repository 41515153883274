import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicStorageModule} from '@ionic/storage';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {I18NLoader} from './commons/i18n/loader';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

/* import {Ng2PageScrollModule} from 'ng2-page-scroll'; */
import {FormsModule} from '@angular/forms';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {ConfigActions} from './actions/config.actions';
import {BackHomeService} from "./services/back-home.service";
import {ConfigService} from './services/config.service';
import {ConfigEffects} from './effects/config-effects';
import {configReducer} from './reducers/config.reducer';
import {Config, DEFAULT_LANGUAGE} from './models/config';

import {TablesActions} from './actions/tables.actions';
import {TablesService} from './services/tables.service';
import {TablesEffects} from './effects/tables-effects';
import {tablesReducer} from './reducers/tables.reducer';
import {Tables, MCU} from './models/tables';

import {AttributesService} from './services/attributes.service';
import {AttributesEffects} from './effects/attributes-effects';
import {attributesReducer} from './reducers/attributes.reducer';

import {ActionsService} from './services/actions.service';
import {ActionsEffects} from './effects/actions-effects';
import {actionsReducer} from './reducers/actions.reducer';

import {BackupsService} from './services/backups.service';
import {BackupsEffects} from './effects/backups-effects';
import {backupsReducer} from './reducers/backup.reducer';

import {SocketService} from './services/socket.service';
import {SocketEffects} from './effects/socket-effects';
import {socketReducer} from './reducers/socket.reducer';

import {WeatherService} from './services/weather.service';
import {AuthHttpService} from './services/auth-http.service';
import {WindowRefService} from './services/window.service';

import {httpStatusReducer} from './reducers/http-status.reducer';
import {HttpStatusEffects} from './effects/http-status.effects';

import {HTTP} from '@ionic-native/http/ngx';

import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {NewMacrozoneComponent} from './components/new-macrozone/new-macrozone.component';
import {AcceptanceComponent} from './components/acceptance/acceptance.component';
import {NoScheduleComponent} from './components/no-schedule/no-schedule.component';
import {NoScheduleModule} from './components/no-schedule/no-schedule.module';

import {SelectAllComponent} from './components/select-all/select-all.component';
import {SelectAllModule} from './components/select-all/select-all.module';

import {NewMacrozoneModule} from './components/new-macrozone/new-macrozone.module';
import {AcceptanceModule} from './components/acceptance/acceptance.module';
import {PopoverPageSCH} from './pages/schedules/popover-page-sch';
import {DaysSelectorComponent} from './pages/schedule/days-selector/days-selector.component';
import {DaysSelectorModule} from './pages/schedule/days-selector/days-selector.module';
import {PopoverPageSCHModule} from './pages/schedules/popover-page-sch.module';
import {PopOverComponent} from './pages/schedule/pop-over/pop-over.component';
import {PopOverModule} from './pages/schedule/pop-over/pop-over.module';
import {NewScheduleComponent} from './pages/schedule/new-schedule/new-schedule.component';
import {NewScheduleModule} from './pages/schedule/new-schedule/new-schedule.module';

import {AdvancedSettingsGraphComponent} from './components/advanced-settings-graph/advanced-settings-graph.component';
import {AdvancedSettingsGraphModule} from './components/advanced-settings-graph/advanced-settings-graph.module';
/*
* quando faccio una MODALE:
* in entryComponents ci metto le definizioni delle modali che carico a runtime
* devo inclucedere qui in app.module.ts anche il modulo della modale
* devo mettere il modulo negli imports di @NgModule
*
*/

import { environment } from '../environments/environment';

import {StoreDevtoolsModule} from '@ngrx/store-devtools';

/** roba native **/

import {MobileAccessibility} from '@ionic-native/mobile-accessibility/ngx';
import {Device} from '@ionic-native/device/ngx';
import {File} from '@ionic-native/file/ngx';

import {NetworkInterface} from '@ionic-native/network-interface/ngx';


import {Zeroconf} from '@ionic-native/zeroconf/ngx';


import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';

import {RelatedEntitiesComponent} from './components/related-entities/related-entities.component';
import {OverrideSetpointComponent} from "./pages/zone/override-setpoint/override-setpoint.component";
import {OverrideSetpointModule} from "./pages/zone/override-setpoint/override-setpoint.module";
import {CircleCountdownComponent} from "./components/circle-countdown/circle-countdown.component";
import {HpControlModeComponent} from "./components/hp-control-mode/hp-control-mode.component";
import {ToggleRowComponent} from "./components/toggle-row/toggle-row.component";
import {BackButtonCustomComponent} from "./components/back-button-custom/back-button-custom.component";
import { AccordionModule } from "./components/accordion/accordion.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MacroZoneSetpointsComponent} from "./pages/macro-zone/macro-zone-setpoints/macro-zone-setpoints.component";
import {ShowZoneOverrideComponent} from "./components/show-zone-override/show-zone-override.component";
import {AutoAdaptiveComfortComponent} from "./components/auto-adaptive-comfort/auto-adaptive-comfort.component";


@NgModule({
    declarations: [AppComponent],
    entryComponents: [AdvancedSettingsGraphComponent, NoScheduleComponent, SelectAllComponent, NewMacrozoneComponent, AcceptanceComponent, PopoverPageSCH, DaysSelectorComponent, PopOverComponent, NewScheduleComponent, RelatedEntitiesComponent, OverrideSetpointComponent, CircleCountdownComponent, HpControlModeComponent, ToggleRowComponent, BackButtonCustomComponent, MacroZoneSetpointsComponent, ShowZoneOverrideComponent, AutoAdaptiveComfortComponent],
    imports:
        [FormsModule,BrowserAnimationsModule,AccordionModule, HttpClientModule, NoScheduleModule, AdvancedSettingsGraphModule, SelectAllModule, NewMacrozoneModule, AcceptanceModule, PopoverPageSCHModule, DaysSelectorModule, PopOverModule, NewScheduleModule, OverrideSetpointModule,
            StoreModule.forRoot({
                config: configReducer,
                tables: tablesReducer,
                attrs: attributesReducer,
                actions: actionsReducer,
                backups: backupsReducer,
                socket: socketReducer,
                httpStatus: httpStatusReducer
            }),
          !environment.production ? StoreDevtoolsModule.instrument({
                name: 'NgRx Demo App'
            }) : [],
            EffectsModule.forRoot([WindowRefService, ConfigEffects, TablesEffects, AttributesEffects, ActionsEffects, BackupsEffects, SocketEffects, HttpStatusEffects]),
            BrowserModule, IonicModule.forRoot(), IonicStorageModule.forRoot(), AppRoutingModule,
            TranslateModule.forRoot(
                {
                    loader: {
                        provide: TranslateLoader,
                        useClass: I18NLoader
                    }

                })
        ],
    providers: [
        HTTP,
        MobileAccessibility,
        Zeroconf,
        NetworkInterface,
        Device,
        File,
        InAppBrowser,
        ScreenOrientation,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpService,
            multi: true
        }
    ],
    exports: [TranslateModule],
    bootstrap: [AppComponent]
})
export class AppModule {
}
