<div class="padding-left-aac-weight aacSettings">
    <ion-list>
        <ion-item class="no-lines itemPadding">
            <ion-label class="label-title-thin text-justify">
                <p [innerHTML]="seasonPage.translate.instant('ADAPTIVE_COMFORT_SETTINGS_DESCRIPTION')"></p>
            </ion-label>
        </ion-item>
        <hr>
    </ion-list>

    <ion-list class="page-padding-bottom">
        <ion-item class="no-lines">
            <ion-label class="label-title-adaptive-page">{{ 'ORIGINATING_MODE_TEMPORARY_MODE_TITLE' | translate }}</ion-label>
        </ion-item>

        <ion-item class="itemPadding no-lines">
            <ion-label class="label-title-thin text-justify">{{ 'ORIGINATING_MODE_TEMPORARY_MODE_DESCRIPTION' | translate }}</ion-label>
        </ion-item>

        <hr>

        <ion-item class="no-lines">
            <ion-label class="label-title-adaptive-page text-justify">{{ 'SETPOINTS_TEMP_MODE_TITLE' | translate }}</ion-label>
        </ion-item>

        <ion-item class="itemPadding no-lines">
            <ion-label class="label-title-thin text-justify">{{ 'SETPOINTS_TEMP_MODE_DESCRIPTION' | translate }}</ion-label>
        </ion-item>

        <hr>

        <ion-item class="no-lines">
            <ion-label class="label-title-adaptive-page">{{ 'TRIGGER_CONDITIONS' | translate }}</ion-label>
        </ion-item>

        <ion-grid class="padding-right-counter">
            <ion-row>
                <ion-col size="12">
                    <ion-item class="no-lines pb-1">
                        <ion-label class="paddingZero no-lines label-title-thin">{{ 'TRIGGER_CONDITIONS_SUBTITLE' | translate }}</ion-label>
                    </ion-item>
                </ion-col>
            </ion-row>

            <ion-row class="paddingBottom">
                <div class="ordinalCol">
                    <p class="text-right pr-2">a.</p>
                </div>
                <ion-col>
                    <p class="label-title-thin text-justify">{{ 'TRIGGER_CONDITIONS_SUBTITLE_A_1' | translate }} <a (click)="changeValue('TEC_AacPercMax')">{{ attrName('TEC_AacPercMax') }} ( = {{ grp?.TEC_AacPercMax }}% )</a> {{ 'TRIGGER_CONDITIONS_SUBTITLE_A_2' | translate }} <a
                            (click)="changeValue('TEC_AacDiffH')">{{ attrName('TEC_AacDiffH') }} ( = {{ grp?.TEC_AacDiffH }}° )</a>.<br>{{ 'TRIGGER_CONDITIONS_SUBTITLE_A_3' | translate }}</p>
                </ion-col>
            </ion-row>

            <ion-row class="paddingBottom">
                <div class="ordinalCol">
                    <p class="text-right pr-2">b.</p>
                </div>
                <ion-col>
                    <p class="label-title-thin text-justify">{{ 'TRIGGER_CONDITIONS_SUBTITLE_B_1' | translate }} <a (click)="changeValue('TEC_AacPercMin')">{{ attrName('TEC_AacPercMin') }} ( = {{ grp?.TEC_AacPercMin }}% )</a> {{ 'TRIGGER_CONDITIONS_SUBTITLE_A_2' | translate }} <a
                            (click)="changeValue('TEC_AacDiffH')">{{ attrName('TEC_AacDiffH') }} ( = {{ grp?.TEC_AacDiffH }}° ).</a><br>{{ 'TRIGGER_CONDITIONS_SUBTITLE_B_3' | translate }}</p>
                </ion-col>
            </ion-row>
        </ion-grid>

        <ion-grid class="padding-right-counter">
            <ion-row>
                <ion-col>
                    <ion-item class="no-lines pb-1">
                        <ion-label class="label-title-thin text-justify">{{ 'CONDITION_SWITCH_TEMP_HEATING_SUBTITLE' | translate }}</ion-label>
                    </ion-item>
                </ion-col>
            </ion-row>

            <ion-row class="paddingBottom">
                <div class="ordinalCol">
                    <p class="text-right pr-2">a.</p>
                </div>
                <ion-col>
                    <p class="label-title-thin text-justify">{{ 'CONDITION_SWITCH_TEMP_HEATING_A_1' | translate }} <a (click)="changeValue('TEC_AacPercMax')">{{ attrName('TEC_AacPercMax') }} ( = {{ grp?.TEC_AacPercMax }}% )</a> {{ 'CONDITION_SWITCH_TEMP_HEATING_A_2' | translate }} <a
                            (click)="changeValue('TEC_AacDiffC')">{{ attrName('TEC_AacDiffC') }} ( = {{ grp?.TEC_AacDiffC }}° )</a>.<br>{{ 'CONDITION_SWITCH_TEMP_HEATING_A_3' | translate }}</p>
                </ion-col>
            </ion-row>

            <ion-row class="paddingBottom">
                <div class="ordinalCol">
                    <p class="text-right pr-2">b.</p>
                </div>
                <ion-col>
                    <p class="label-title-thin text-justify">{{ 'TRIGGER_CONDITIONS_SUBTITLE_B_1' | translate }} <a (click)="changeValue('TEC_AacPercMin')">{{ attrName('TEC_AacPercMin') }} ( = {{ grp?.TEC_AacPercMin }}% )</a> {{ 'CONDITION_SWITCH_TEMP_HEATING_B_2' | translate }} <a
                            (click)="changeValue('TEC_AacDiffC')">{{ attrName('TEC_AacDiffC') }} ( = {{ grp?.TEC_AacDiffC }}° )</a>.<br>{{ 'CONDITION_SWITCH_TEMP_HEATING_B_3' | translate }}</p>
                </ion-col>
            </ion-row>
        </ion-grid>

        <ion-item class="no-lines">
            <ion-label>
                <p class="label-title-thin text-justify">{{ 'TRIGGER_CONDITIONS_FINAL_SUBTITLE' | translate }}</p>
            </ion-label>
        </ion-item>

        <hr>

        <ion-item class="no-lines">
            <ion-label class="label-title-adaptive-page text-justify">{{ 'TEMP_MODE_SWITCH_TITLE' | translate }}</ion-label>
        </ion-item>

        <ion-item class="itemPadding no-lines">
            <ion-label class="label-title-thin text-justify">{{ 'TEMP_MODE_SWITCH_DESCRIPTION' | translate }}</ion-label>
        </ion-item>

        <hr>

        <ion-item class="no-lines">
            <ion-label class="label-title-adaptive-page text-justify">{{ 'CYCLING_PREVENTION_TITLE' | translate }}</ion-label>
        </ion-item>

        <ion-item class="itemPadding no-lines">
            <ion-label class="label-title-thin text-justify">{{ 'CYCLING_PREVENTION_SUBTITLE_1' | translate }} <a (click)="changeValue('TEC_AacMinTimeTS')">( = {{ grp?.TEC_AacMinTimeTS }} {{ 'hours' | translate }} )</a>. {{ 'CYCLING_PREVENTION_SUBTITLE_2' | translate }} <a
                    (click)="changeValue('TEC_AacMinTimeTS')">(= {{ grp?.TEC_AacMinTimeTS }} {{ 'hours' | translate }} )</a>. {{ 'CYCLING_PREVENTION_SUBTITLE_3' | translate }}
            </ion-label>
        </ion-item>

        <hr>

        <ion-item class="no-lines">
            <ion-label class="label-title-adaptive-page text-justify">{{ 'SWITCH_BACK_ORIGINATING_MODE_TITLE' | translate }}</ion-label>
        </ion-item>

        <ion-grid class="padding-right-counter">
            <ion-row>
                <ion-col>
                    <ion-item class="no-lines pb-1">
                        <ion-label>
                            <p class="bottom label-title-thin text-justify">{{ 'SWITCH_BACK_ORIGINATING_MODE_SUBTITLE' | translate }}</p>
                        </ion-label>
                    </ion-item>
                </ion-col>
            </ion-row>

            <ion-row class="paddingBottom">
                <div class="ordinalCol">
                    <p class="text-right pr-2">a.</p>
                </div>
                <ion-col>
                    <p class="label-title-thin text-justify">{{ 'SWITCH_BACK_ORIGINATING_MODE_A_1' | translate }} <a (click)="changeValue('TEC_AacMinTimeTS')">{{ grp?.TEC_AacMinTimeTS }} {{ 'hours' | translate }}</a>.<br>{{ 'SWITCH_BACK_ORIGINATING_MODE_A_2' | translate }}
                        <br>{{ 'SWITCH_BACK_ORIGINATING_MODE_A_3' | translate }}</p>
                </ion-col>
            </ion-row>

            <ion-row class="paddingBottom">

                <div class="ordinalCol">
                    <p class="text-right pr-2">b.</p>
                </div>
                <ion-col>
                    <p class="label-title-thin text-justify">{{'SWITCH_BACK_ORIGINATING_MODE_B_1' | translate}}<br>{{'SWITCH_BACK_ORIGINATING_MODE_B_2' | translate}}<br>
                        {{'SWITCH_BACK_ORIGINATING_MODE_B_LESS' | translate}} <a (click)="changeValue('TEC_AacPercMin')">{{ attrName('TEC_AacPercMin') }} ( = {{ grp?.TEC_AacPercMin }}% )</a> {{'SWITCH_BACK_ORIGINATING_MODE_B_4' | translate}} <a (click)="changeValue('TEC_AacDiffOff')">{{ attrName('TEC_AacDiffOff') }} ( = {{ grp?.TEC_AacDiffOff }}° )</a>.<br>
                        {{'SWITCH_BACK_ORIGINATING_MODE_B_5' | translate}}<br>{{'SWITCH_BACK_ORIGINATING_MODE_B_6' | translate}}<br>
                        {{'SWITCH_BACK_ORIGINATING_MODE_B_LESS' | translate}} <a (click)="changeValue('TEC_AacPercMin')">{{ attrName('TEC_AacPercMin') }} ( = {{ grp?.TEC_AacPercMin }}% )</a> {{'SWITCH_BACK_ORIGINATING_MODE_B_7' | translate}} <a (click)="changeValue('TEC_AacDiffOff')">{{ attrName('TEC_AacDiffOff') }} ( = {{ grp?.TEC_AacDiffOff }}° )</a>.<br>
                        {{'SWITCH_BACK_ORIGINATING_MODE_B_8' | translate}}
                    </p>
                </ion-col>
            </ion-row>
        </ion-grid>

        <ion-item class="no-lines">
            <ion-label>
                <p class="label-title-thin text-justify">{{'SWITCH_BACK_ORIGINATING_MODE_NOTE_1' | translate}} <a (click)="changeValue('TEC_AacDiffOff')">{{ attrName('TEC_AacDiffOff') }}</a>, {{'SWITCH_BACK_ORIGINATING_MODE_NOTE_2' | translate}} <a (click)="changeValue('TEC_AacDiffOff')">{{ attrName('TEC_AacDiffOff') }}</a> {{'SWITCH_BACK_ORIGINATING_MODE_NOTE_3' | translate}} {{ minActivationOff }}° {{'to' | translate}} {{ maxActivationOff }}°.</p>
            </ion-label>
        </ion-item>
    </ion-list>
</div>