import {Component, OnInit} from '@angular/core';
import {ActionSheetController, AlertController, Platform, ToastController} from '@ionic/angular';
import {BitArray} from '../../commons/bitarray';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {SupportService} from '../../services/support.service';
import {AppState} from '../../services/app-state.service';
import {AbstractConfigPage} from '../abstract-config-page';
import {ChangeTableMultipleItems, ChangeTableValues, ChangeValuesRequest} from '../../actions/tables.actions';

import {ARS, AUTO_MODE_STYLE, DIFF_MODE_SEASON_REF, FNC, GRP, HYS, MCU, MCZ, RMS, SCH, TNK, ZON, TRG} from '../../models/tables';

import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {FlagsGroup, FlagsSEA} from '../../commons/flags';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SchedulationType} from "../../models/config";
import * as moment from "moment";
import {GrpFsmSeason} from "../../models/enum-misc";

enum GetMinTimeDescriptionType {

    AutoTS,
    ManualAacTs,
    ManualAacRs
}

class UnassignSchedulesWarning {

    schedulationTypeToCheck: SchedulationType;
    warningPresent: boolean;
    rmsArrayWithSchedules: RMS[];
    mczArrayWithSchedules: MCZ[];
    warningText: string;

    constructor(schedulationTypeToCheck: SchedulationType, warningPresent: boolean, rmsArrayWithSchedules: RMS[], mczArrayWithSchedules: MCZ[], warningText: string) {

        this.schedulationTypeToCheck = schedulationTypeToCheck;
        this.warningPresent = warningPresent;
        this.rmsArrayWithSchedules = rmsArrayWithSchedules;
        this.mczArrayWithSchedules = mczArrayWithSchedules;
        this.warningText = warningText;
    }
}

@Component({
    selector: 'app-season',
    templateUrl: './season.page.html',
    styleUrls: ['./season.page.scss'],
    animations: [
        trigger('contentExpansion', [
            state('expanded', style({height: '*', opacity: 1, visibility: 'visible'})),
            state('collapsed', style({height: '0px', opacity: 0, visibility: 'hidden'})),
            transition('expanded <=> collapsed', animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
        ]),
    ]
})

export class SeasonPage extends AbstractConfigPage implements OnInit {

    TABLES = ['Config', 'MCU', 'TNK', 'GRP', 'SCH', 'MCZ', 'RMS', 'ZON', 'FNC', 'HYS','TRG'];

    AUTO_MODE_STYLE = AUTO_MODE_STYLE;
    DIFF_MODE_SEASON_REF = DIFF_MODE_SEASON_REF;

    MCZ: MCZ[] = []; // Macro zones
    MCU: MCU;
    GRP: GRP[];
    SCH: SCH[] = [];
    RMS: RMS[] = [];

    HYS: HYS[] = [];

    ZON: ZON[] = [];
    FNC: FNC[] = [];
    TRG: TRG[] = [];

    TNK: TNK[] = [];
    ARS: ARS[] = [];

    manualChangeoverSettingsShow: boolean[] = [];
    scheduleSettingsShow: boolean[] = [];
    autoModeUserMode2SettingsShow: boolean[] = [];
    autoModeUserMode3SettingsShow: boolean[] = [];

    zoneWeightsAAC: boolean[] = [];
    zoneWeightAuto: boolean[] = [];
    aacSettingsShow: boolean[] = [];

    relatedZONShow: boolean[] = [];
    relatedFNCShow: boolean[] = [];
    relatedHYSShow: boolean[] = [];
    relatedARSShow: boolean[] = [];
    relatedTNKShow: boolean[] = [];

    targetHydronicSystems: HYS[][];
    targetRooms: RMS[][];
    targetFancoils: FNC[][];
    targetAirsystems: ARS[][];
    targetTanks: TNK[][];

    seasonSelectedOption: number[]; // 0 = heating, 1 = cooling, -1 = schedulation, 2 = Comfort Range
    preSelectionOption: number[];
    userMode: number[] = []; //
    preUserMode: number[] = []; //
    schReturnId: number = -1 // sch id to selected when returned on page
    grpReturnId: number = -1;
    aacState: boolean[] = [];
    futureAacState: boolean[] = [];
    preSelectionAacState: boolean[] = [];
    validGRP: GRP[]
    validSeasonSCH: SCH[]
    schMode: boolean[] = [];
    prevScheduleId: number;

    validMCZ: MCZ[] = [];

    schLoaded: boolean = false;

    reverting: boolean;
    loadCallback: boolean;

    essentialUpdate: boolean[] = [];

    collapsing = true;

    public dualSetpointOptionPresent = true;
    public GetMinTimeDescriptionType = GetMinTimeDescriptionType;
    public GrpFsmSeason = GrpFsmSeason;

    constructor(
        protected supportService: SupportService,
        public router: Router,
        public store: Store<AppState>,
        protected platform: Platform,
        private http: HttpClient,
        public alertController: AlertController,
        protected actionSheetController: ActionSheetController,
        public translate: TranslateService,
        protected toastController?: ToastController) {
        super(store, platform, alertController, actionSheetController, translate, toastController);
    }

    /**
     *
     */
    get defaultSCH(): SCH {
        return this.SCH.find(function (elm) {

            return elm.CFG_Type === 1 && elm.CFG_Valid;
        });
    }

    /*
  * Change Schedule from Schedule Component
  */
    //onChangeSCH = (table: string, item: string, values: any): void => {
    onChangeSeasonSCH = (event): void => {


        // console.log('onChangeSeasonSCH(event) chiamata')
        // console.log(event)

        let id = event.shceduleId;
        let url = event.url;
        this.prevScheduleId = event.prevScheduleId;
        let grp: GRP = event.grp;
        let index = event.i;

        if (id != -1 || url != 'season') return;


        // console.warn('onChangeSeasonSCH event vale')
        // console.log(event)
        // console.log('forzo il cambio stagione a manuale')
        this.changeMode(grp, 0, index);


        /* this.schMode = false
          var scheduleIdx = -1

         if(this.season == 0 || this.MCU.TEC_SPMode){
             scheduleIdx = values['PAR_IdxSCH_H']
         }
         else{
             scheduleIdx = values['PAR_IdxSCH_C']
         }

         this.checkScheduleSeason(scheduleIdx) */


    }


    ionViewWillEnter() {
        this.reverting = false;
        this.loadCallback = true;
        this.prevScheduleId = -1;


        this.load(this.TABLES, () => {

            if (this.loadCallback) {
                this.validGRP = this.GRP.filter(grp => grp.CFG_SeasonMode % 2 != 0)
                //console.log(this.validGRP,this.GRP)
                this.MCU.radiantSystem = this.supportService.checkRadiantSystem(this.RMS, this.ZON, this.FNC, this.HYS, this.GRP);
                //console.log(` this.MCU.radiantSystem vale ${ this.MCU.radiantSystem}`);
                this.seasonSelectedOption = [];
                this.preSelectionOption = [];
                this.userMode = new Array(this.validGRP.length)
                this.preUserMode = new Array(this.validGRP.length)
                if (this.schMode == undefined || this.schMode == []) {
                    this.schMode = new Array(this.validGRP.length).fill(false);
                }

                this.aacState = new Array(this.GRP.length)
                this.futureAacState = new Array(this.GRP.length).fill(false);
                this.preSelectionAacState = new Array(this.GRP.length)

                if (this.essentialUpdate == [] || this.essentialUpdate == undefined) {

                  this.essentialUpdate = new Array(this.validGRP.length).fill(false);
                }

                if (this.manualChangeoverSettingsShow == [] || this.manualChangeoverSettingsShow == undefined) {

                    this.manualChangeoverSettingsShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.scheduleSettingsShow == [] || this.scheduleSettingsShow == undefined) {

                    this.scheduleSettingsShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.autoModeUserMode2SettingsShow == [] || this.autoModeUserMode2SettingsShow == undefined) {

                    this.autoModeUserMode2SettingsShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.autoModeUserMode3SettingsShow == [] || this.autoModeUserMode3SettingsShow == undefined) {

                    this.autoModeUserMode3SettingsShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.zoneWeightsAAC == [] || this.zoneWeightsAAC == undefined) {

                    this.zoneWeightsAAC = new Array(this.validGRP.length).fill(false);
                }

                if (this.zoneWeightAuto == [] || this.zoneWeightAuto == undefined) {

                    this.zoneWeightAuto = new Array(this.validGRP.length).fill(false);
                }

                if (this.aacSettingsShow == [] || this.aacSettingsShow == undefined) {

                    this.aacSettingsShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.relatedZONShow == [] || this.relatedZONShow == undefined) {

                    this.relatedZONShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.relatedFNCShow == [] || this.relatedFNCShow == undefined) {

                    this.relatedFNCShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.relatedHYSShow == [] || this.relatedHYSShow == undefined) {

                    this.relatedHYSShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.relatedARSShow == [] || this.relatedARSShow == undefined) {

                    this.relatedARSShow = new Array(this.validGRP.length).fill(false);
                }

                if (this.relatedTNKShow == [] || this.relatedTNKShow == undefined) {

                    this.relatedTNKShow = new Array(this.validGRP.length).fill(false);
                }


                this.targetHydronicSystems = new Array(this.validGRP.length);
                this.targetRooms = new Array(this.validGRP.length);
                this.targetFancoils = new Array(this.validGRP.length);
                this.targetAirsystems = new Array(this.validGRP.length);
                this.targetTanks = new Array(this.validGRP.length);


                for (let i = 0; i < this.validGRP.length; i++) {

                    //seleziono le zone connesse al gruppo


                    let id = this.validGRP[i].id;


                    //prendo gli HYS che hanno questo gruppo (id)

                    this.targetHydronicSystems[i] = _.sortBy(_.filter(this.HYS, x => x.CFG_IdxGRP == id), 'id');

                    this.targetRooms[i] = _.sortBy(_.filter(this.RMS, x => x.CFG_IdxGRP == id), 'id');

                    this.targetFancoils[i] = _.sortBy(_.filter(this.FNC, x => x.CFG_IdxGRP == id), 'id');

                    this.targetAirsystems[i] = _.sortBy(_.filter(this.ARS, x => x.CFG_IdxGRP == id), 'id');

                    this.targetTanks[i] = _.sortBy(_.filter(this.TNK, x => x.CFG_IdxGRP == id), 'id');


                    // console.log(this.GRP[i].PAR_Season)
                    var grp = this.validGRP[i]
                    //vecchia versione (probabilmente errata, comprensione avuta da Andrea 27/3/2020)
                    //this.seasonSelectedOption[grp.id] = (grp.PAR_IdxSCH != -1) ? -1 : grp.PAR_Season;
                    this.seasonSelectedOption[grp.id] = grp.PAR_Season;
                    // console.warn(`attualmente per il gruppo ${grp.id} la modalità vale ${grp.PAR_Season}`);
                    this.preSelectionOption[grp.id] = this.seasonSelectedOption[i]


                    this.preUserMode[i] = this.userMode[i] = (grp.PAR_Season == 0 || grp.PAR_Season == 1) ? 0 : grp.PAR_Season;
                    //console.log(`grp name ${grp.CFG_Name} season ${grp.PAR_Season} automode style ${grp.PAR_AutoModeStyle}`);

                   let canProceed = (this.MCU.CFG_N_TRG > 0 ? (this.TRG.length > 0) : true );

                    if(this.userMode[i] == 2 && (!this.canChangeSeason(grp.id) || this.is4Pipe(grp.id)) && !this.essentialUpdate[i] && canProceed){
                        this.essentialUpdate[i] = true;
                        console.warn(`has this system triggers? ${this.MCU.CFG_N_TRG}`);
                        console.warn(`trigger array length is ${this.TRG.length}`);
                        console.warn(` this.userMode[${i}] vale ${this.userMode[i]} for grp id ${grp.id} is 4Pipe ? ${this.is4Pipe(grp.id)} cannotChange season ${!this.canChangeSeason(grp.id)}`);
                        console.warn(`fixed new auto value GRP_PAR_Season breaking change for group ${grp.id}`);
                        this.onChangeMode(grp, 3, i,this.getRMSAndMCZDualAndVariableSchedulesInUse(SchedulationType.DualVariable) );

                    }
                    this.aacState[i] = grp.PAR_AAC == 1
                    this.preSelectionAacState[i] = grp.PAR_AAC == 1
                }

                this.validSeasonSCH = this.SCH.filter(sch => sch.CFG_Type == 1 && sch.CFG_Valid)
            }


        });
    }


    canChangeSeason(grpId: number): boolean {
        return this.supportService.canChangeSeason(grpId, this.GRP, this.HYS, this.TNK, this.FNC,this.TRG);
    }

    groupHasRoomSensors(grpId:number):boolean {
        return this.supportService.groupHasRoomSensors(grpId,this.RMS);
    }

    is4Pipe(grpId: number) {
        return this.supportService.is4Pipe(grpId, this.HYS);
    }


    ionViewDidEnter(): void {
        if (this.schReturnId != -1) {
            let grp = this.validGRP[this.grpReturnId]
            grp.PAR_IdxSCH = this.schReturnId
            this.store.dispatch(new ChangeTableMultipleItems([
                {table: 'GRP', id: grp.id, values: {PAR_Season: -1, PAR_IdxSCH: grp.PAR_IdxSCH}}
            ]));
        }

        for (let i = 0; i < this.validGRP.length; i++) {
            if (!this.canChangeSeason(this.validGRP[i].id) && (this.validGRP[i].PAR_Season == 0 || this.validGRP[i].PAR_Season == 1)) {
                this.store.dispatch(new ChangeTableValues('GRP', this.validGRP[i].id, {PAR_AAC: 0}));
            }
        }
    }


    onChangeSeason = (grp: GRP, season: number, grpIndex): void => {
        // console.log('onChangeSeason')
        // switch(season){
        //     case 0:
        //     case 1:
        //     case 2:
        //         grp.PAR_IdxSCH = -1;
        //         grp.PAR_Season = season;
        //         this.store.dispatch(new ChangeTableMultipleItems([
        //             { table: 'GRP', id: grp.id, values: { PAR_Season: season, PAR_IdxSCH: grp.PAR_IdxSCH}}
        //         ]));
        //         break;
        //     default: //-1
        //         if (grp.PAR_IdxSCH == -1){
        //             if(this.defaultSCH) grp.PAR_IdxSCH = this.defaultSCH.id;
        //             grp.PAR_Season = season;
        //             this.store.dispatch(new ChangeTableMultipleItems([
        //                 { table: 'GRP', id: grp.id, values: { PAR_Season: season, PAR_IdxSCH: grp.PAR_IdxSCH }}
        //             ]));
        //         }
        // }
        grp.PAR_IdxSCH = -1;
        grp.PAR_Season = season;
        this.store.dispatch(new ChangeTableMultipleItems([
            {table: 'GRP', id: grp.id, values: {PAR_Season: season, PAR_IdxSCH: grp.PAR_IdxSCH}}
        ]));
        this.preSelectionOption[grpIndex] = this.seasonSelectedOption[grpIndex]
    }

    /*
     * Show the menu
     */
    openMenu = ($event, grp: GRP): void => {
        if ($event) {
            $event.stopPropagation();
        }
        this.showMenu(grp.CFG_Name, [
            {
                text: 'RENAME',
                handler: () => this.rename('GRP', grp.id, 'RENAME', 'RENAME_TEXT', this.validGRP)
            },
            {
                text: 'AAC_DETAIL',
                handler: () => this.goEditAAC(grp.id)
            }
        ]);
    }
    revertSeason = (grpIndex): void => {
        this.seasonSelectedOption[grpIndex] = this.preSelectionOption[grpIndex]
    }

    changeSeason = (grp: GRP, season: number, index: number, $event?): void => {

        this.confirmPopup($event, 'ALERT', 'CONFIRM_SEASON_MODE', 'CONFIRM',
            () => this.onChangeSeason(grp, season, index),
            () => this.revertSeason(index))
    }

    changeAutoModeStyle = (grp: GRP, autoModeStyle: number, previousAutoModeStyle: number): void => {

        // autoModeStyle: 0: deadband /  1: h/c diff / 2: Dual setpoint
        const schedulationTypeToCheck = autoModeStyle === 2 ? SchedulationType.Variable : SchedulationType.DualVariable;
        const unassignSchedulesWarning = this.getRMSAndMCZDualAndVariableSchedulesInUse(schedulationTypeToCheck, false);

        if (unassignSchedulesWarning.warningPresent) {

            grp.PAR_AutoModeStyle = autoModeStyle;
            this.store.dispatch(new ChangeTableValues('GRP', grp.id, {PAR_AutoModeStyle: autoModeStyle}));

            this.confirmPopup(null, 'ALERT', unassignSchedulesWarning.warningText, 'CONFIRM',
                () => this.applyChangeAutoModeStyle(grp, autoModeStyle, unassignSchedulesWarning),
                () => this.revertChangeAutoModeStyle(grp, previousAutoModeStyle))
        } else {

            this.applyChangeAutoModeStyle(grp, autoModeStyle, unassignSchedulesWarning);
        }
    }

    revertChangeAutoModeStyle(grp, previousAutoModeStyle) {

        grp.PAR_AutoModeStyle = previousAutoModeStyle;
        this.store.dispatch(new ChangeTableValues('GRP', grp.id, {PAR_AutoModeStyle: previousAutoModeStyle}));
    }

    applyChangeAutoModeStyle(grp: GRP, autoModeStyle: number, unassignSchedulesWarning: UnassignSchedulesWarning) {

        this.unassignSchedule(unassignSchedulesWarning); // un-assign DualVariable and Variable schedules if necessary

        let requests: ChangeValuesRequest[] = [];
        let spMode = this.MCU?.TEC_SPMode;
        let rmsRequests = [];

        if (autoModeStyle == AUTO_MODE_STYLE.DEADBAND || autoModeStyle == AUTO_MODE_STYLE.SETPOINT_DIFF) {

            //devo unificare i setpoint massimi e minimi e i setpoit H con quelli C
            spMode = true;
            requests = this.supportService.unifySetpoints(this.MCZ, this.RMS, this.SCH);
        }

        if (autoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT) {

            spMode = false;
            rmsRequests = this.supportService.adjustSetpointsForDualSetpointMode(this.MCU, this.RMS, grp);
        }


        this.store.dispatch(new ChangeTableMultipleItems([
            {table: 'GRP', id: grp.id, values: {PAR_AutoModeStyle: autoModeStyle}},
            {table: 'MCU', id: 0, values: {TEC_SPMode: spMode}}, ...rmsRequests, ...requests
        ]));
    }

    changeDiffModeSeasonRef = (grp: GRP, diffModeSeasonRef: number): void => {

        this.store.dispatch(new ChangeTableMultipleItems([
            {table: 'GRP', id: grp.id, values: {PAR_DiffModeSeasonRef: diffModeSeasonRef}}
        ]));

    }

    getRMSAndMCZDualAndVariableSchedulesInUse(schedulationTypeToCheck: SchedulationType, textAddedToExistingText = true) {

        // Filter rms array
        const rmsArrayWithSchedules = this.RMS.filter(({PAR_IdxSCH_H, PAR_IdxSCH_C}) => {
            return _.some(this.SCH, ({id, CFG_Type, CFG_Valid}) => {
                return (id === PAR_IdxSCH_H || id === PAR_IdxSCH_C) && CFG_Type === schedulationTypeToCheck && CFG_Valid;
            });
        });

        // Filter mcz array
        const mczArrayWithSchedules = this.MCZ.filter(({PAR_IdxSCH_H, PAR_IdxSCH_C}) => {
            return _.some(this.SCH, ({id, CFG_Type, CFG_Valid}) => {
                return (id === PAR_IdxSCH_H || id === PAR_IdxSCH_C) && CFG_Type === schedulationTypeToCheck && CFG_Valid;
            });
        });

        const warningPresent = rmsArrayWithSchedules.length > 0 || mczArrayWithSchedules.length > 0;

        let warningText = '';

        if (warningPresent) {

            const CHANGEOVER_SCHEDULES_WARNING_1 = textAddedToExistingText ? 'CHANGEOVER_SCHEDULES_WARNING_1' : 'CHANGEOVER_SCHEDULES_WARNING_1_ALT';
            const CHANGEOVER_WARNING_VARIABLE = schedulationTypeToCheck === SchedulationType.Variable ? 'CHANGEOVER_WARNING_VARIABLE' : 'CHANGEOVER_WARNING_DUAL_VARIABLE';
            warningText += `${this.translate.instant(CHANGEOVER_SCHEDULES_WARNING_1)} ${this.translate.instant(CHANGEOVER_WARNING_VARIABLE)} ${this.translate.instant('CHANGEOVER_SCHEDULES_WARNING_2')} ${this.translate.instant(CHANGEOVER_WARNING_VARIABLE)} ${this.translate.instant('CHANGEOVER_SCHEDULES_WARNING_3')}`;
        }

        return new UnassignSchedulesWarning(schedulationTypeToCheck, warningPresent, rmsArrayWithSchedules, mczArrayWithSchedules, warningText);
    }


    changeMode = (grp: GRP, mode: number, index: number, $event?): void => {

        console.log(`chiamata changeMode grp ${grp} mode ${mode} index ${index}`);

        if (!grp.RTU_OnSC) {

            if (mode != 2) {

                // If there are DualVariable schedules -> notify the user that they will be unassigned
                const unassignSchedulesWarning = this.getRMSAndMCZDualAndVariableSchedulesInUse(SchedulationType.DualVariable);
                const message = this.translate.instant('CONFIRM_SEASON_MODE') + unassignSchedulesWarning.warningText;

                this.confirmPopup($event, 'ALERT', message, 'CONFIRM',
                    () => this.onChangeMode(grp, mode, index, unassignSchedulesWarning),
                    () => this.revertMode(grp, index))
            }

            // ora mi trovo con setpoint differenziati ma dovrò metterli unificati
            else if (mode >= 2 && !this.MCU.TEC_SPMode && (grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.SETPOINT_DIFF || grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.DEADBAND)) {

                // If there are DualVariable schedules -> notify the user that they will be unassigned
                const unassignSchedulesWarning = this.getRMSAndMCZDualAndVariableSchedulesInUse(SchedulationType.DualVariable);
                const message = this.translate.instant('CONFIRM_SEASON_AUTO_MODE') + unassignSchedulesWarning.warningText;

                this.confirmPopup($event, 'ALERT', message, 'CONFIRM',
                    () => this.onChangeMode(grp, mode, index, unassignSchedulesWarning),
                    () => this.revertMode(grp, index));
            }

            // ora mi trovo con setpoint differenziati e vanno bene così
            else if (mode >= 2 && !this.MCU.TEC_SPMode && grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT) {

                // If there are Variable schedules -> notify the user that they will be unassigned
                const unassignSchedulesWarning = this.getRMSAndMCZDualAndVariableSchedulesInUse(SchedulationType.Variable);
                const message = this.translate.instant('CONFIRM_SEASON_MODE') + unassignSchedulesWarning.warningText;

                this.confirmPopup($event, 'ALERT', message, 'CONFIRM',
                    () => this.onChangeMode(grp, mode, index, unassignSchedulesWarning),
                    () => this.revertMode(grp, index))
            }

            // ora mi trovo con setpoint comuni
            else if (mode >= 2 && this.MCU.TEC_SPMode) {

                // If there are DualVariable schedules -> notify the user that they will be unassigned
                const unassignSchedulesWarning = this.getRMSAndMCZDualAndVariableSchedulesInUse(SchedulationType.DualVariable);
                const message = this.translate.instant('CONFIRM_SEASON_MODE') + unassignSchedulesWarning.warningText;

                this.confirmPopup($event, 'ALERT', message, 'CONFIRM',
                    () => this.onChangeMode(grp, mode, index, unassignSchedulesWarning),
                    () => this.revertMode(grp, index))
            }
        }
    }

    revertMode = (grp: GRP, modeIndex: number): void => {

        this.userMode[modeIndex] = this.preUserMode[modeIndex];

        if (this.prevScheduleId !== -1) {

            // ripristino il valore della schedulazione
            let values = {};
            values['PAR_IdxSCH'] = this.prevScheduleId;

            this.store.dispatch(new ChangeTableValues('GRP', modeIndex, values));
        }
    }

    onChangeMode = (grp: GRP, mode: number, index: number, unassignSchedulesWarning: UnassignSchedulesWarning): void => {

        console.log(`changeMode: modalità ${mode} per il gruppo ${index}`);
        this.unassignSchedule(unassignSchedulesWarning); // un-assign DualVariable and Variable schedules if necessary

        switch (mode) {

            case 0:

                this.schMode[index] = false;
                grp.PAR_Season = mode;

                this.store.dispatch(new ChangeTableMultipleItems([{table: 'GRP', id: grp.id, values: {PAR_Season: mode}}, {table: 'GRP', id: grp.id, values: {PAR_AutoModeStyle: 0}}]));
                break;

            case 2:
            case 3:

                let requests: ChangeValuesRequest[] = [];

                grp.PAR_Season = mode;
                this.schMode[index] = false;

                this.store.dispatch(new ChangeTableMultipleItems([{table: 'GRP', id: grp.id, values: {PAR_Season: mode}}]));

                // devo unificare se ho dead band e h/c diffs
                // devo unificare i setpoint massimi e minimi e i setpoint H con quelli C
                if (grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.DEADBAND || grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.SETPOINT_DIFF) {

                    requests = this.supportService.unifySetpoints(this.MCZ, this.RMS, this.SCH);

                    //devo imposto setpoint unificati
                    this.store.dispatch(new ChangeTableMultipleItems([{table: 'MCU', id: 0, values: {TEC_SPMode: true}}]));
                }

                if (grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT) {

                    //devo imposto setpoint differentiate
                    this.store.dispatch(new ChangeTableMultipleItems([{table: 'MCU', id: 0, values: {TEC_SPMode: false}}]));
                }

                this.store.dispatch(new ChangeTableMultipleItems(requests));
                break;

            default: // -1

                if (grp.PAR_IdxSCH === -1 || !this.SCH[grp.PAR_IdxSCH].CFG_Valid) {

                    grp.PAR_IdxSCH = this.defaultSCH.id;
                }

                grp.PAR_Season = -1;

                this.store.dispatch(new ChangeTableMultipleItems([{table: 'GRP', id: grp.id, values: {PAR_Season: -1, PAR_IdxSCH: grp.PAR_IdxSCH}}, {table: 'GRP', id: grp.id, values: {PAR_AutoModeStyle: 0}}]));
        }

        this.preUserMode[index] = this.userMode[index]
    }

    // Un-assign Variable schedules when switching to Auto + Dual setpoint, and unassign DualVariable schedules when changing from Auto + Dual setpoint to anything else
    unassignSchedule(unassignSchedulesWarning: UnassignSchedulesWarning) {

        const unassignSchedule = (tableName: string, rmsOrMczArray: RMS[] | MCZ[]) => {

            if (unassignSchedulesWarning.warningPresent) {

                for (const rmsOrMcz of rmsOrMczArray) {

                    const values = {};
                    const scheduleHeating = this.SCH[rmsOrMcz.PAR_IdxSCH_H];
                    const scheduleCooling = this.SCH[rmsOrMcz.PAR_IdxSCH_C];

                    if (scheduleHeating?.CFG_Type === unassignSchedulesWarning.schedulationTypeToCheck) {

                        values['PAR_IdxSCH_H'] = -1;
                        values['PAR_SchedOnH'] = false;
                    }

                    if (scheduleCooling?.CFG_Type === unassignSchedulesWarning.schedulationTypeToCheck) {

                        values['PAR_IdxSCH_C'] = -1;
                        values['PAR_SchedOnC'] = false;
                    }

                    this.store.dispatch(new ChangeTableValues(tableName, rmsOrMcz.id, values));
                }
            }
        }

        unassignSchedule('RMS', unassignSchedulesWarning.rmsArrayWithSchedules);
        unassignSchedule('MCZ', unassignSchedulesWarning.mczArrayWithSchedules);
    }


    sch(grp?: GRP): SCH {
        let sch = null;
        let _userMode = (grp.PAR_Season == 0 || grp.PAR_Season == 1) ? 0 : grp.PAR_Season;
        try {
            // if(this.season == 0)
            //   console.log(this.RMS[this.id].PAR_IdxSCH_H)
            // else
            //   console.log(this.RMS[this.id].PAR_IdxSCH_C)
            if (_userMode == -1)
                sch = this.SCH[grp.PAR_IdxSCH]
            if (!sch) this.schLoaded = false;
            else
                this.schLoaded = true;

            return sch;
            //  this.currentSch;
        } catch (ex) {
            return null;
        }
    }


    id(x, i) {
        return x * 100 + i
    }


    /*
     * Open the popup for creating a schedule
     */
    // openNewSchedulationPopup = (grpId): void => {
    //nota di marx86@gmail.com del 10/9/2020
    //questa cosa è stata rimossa prima del porting dal template della pagina season
    //lascio qui per scrupolo... non si sa mai
    /* let popup = new NewSchedulation(this.app, {
         fromSeason: true,
         title: this.translate.instant('NEW_SCHEDULE_PROFILE'),
         message: this.translate.instant('NEW_SCHEDULE_SEASON_TEXT'),
         buttons: [
             {
                 text: this.translate.instant('CANCEL')
             },
             {
                 text: this.translate.instant('SAVE_GO'),
                 handler: data => {
                     if (!data.name) {
                         data.name = this.translate.instant('UNNAMED');
                     }
                     this.createNewScheduleAndGo(grpId, data.name, data.schedulationType);
                 }
             }
         ]
     });
     popup.present();*/
    // }

    /**
     * Create a new schedule profile
     */
    /*createNewScheduleAndGo = (grpId:number, name: string, schedulationType: SchedulationType): void => {
        let id: number = null;
        for (let sch of this.SCH) {
            if (sch.id != 0 && !sch.CFG_Valid) {
                id = sch.id;
                break;
            }
        }
        let sch: SCH = new SCH();
        sch.CFG_Name = name;
        sch.CFG_Valid = true;
        sch.CFG_Type = schedulationType;
        sch.PAR_ApplyTo = 255;
        sch.PAR_Schedule = [0, 0, 0, 0, 0, 0, 0];
        sch.RTU_On = false;
        if (id == null) {
            // Create a new item
            this.schReturnId = this.SCH.length;
            this.grpReturnId = grpId;
            sch.id = this.schReturnId;
            this.store.dispatch(new ChangeTableMultipleItems([
                { table: 'SCH', id: this.schReturnId , values: sch },
                { table: 'MCU', id: 0, values: { CFG_N_SCH: this.SCH.length + 1 } }
            ]));
        } else {
            // Update an existing (but deleted) item
            sch.id = id;
            this.store.dispatch(new ChangeTableValues('SCH', id, sch));
        }
        this.showSchedule(sch)
    }*/

    /**
     * Show a single profile
     */


    /**
     * Write Season PAR_ON changes
     */
    onChangeAAC(grp, i) {
        // console.log('change lanciato da bottone conferma')
        //grp.PAR_AAC = (this.aacState[i])?1:0;
        grp.PAR_AAC = (this.futureAacState[i]) ? 1 : 0;

        this.aacState[i] = this.futureAacState[i];
        //this.preSelectionAacState[i] = this.aacState[i]

        // console.log(`grp.PAR_AAC ${grp.PAR_AAC}`)
        this.store.dispatch(new ChangeTableValues('GRP', grp.id, {PAR_AAC: grp.PAR_AAC}));
        setTimeout(() => {
            this.reverting = false;
            this.loadCallback = true;
        }, 300);
    }

    changeAAC(grp: GRP, i, $event?) {
        //console.log(`reverting: ${this.reverting}`)
        if (this.reverting) {
            $event.stopPropagation();
            //this.aacState[i] = this.preSelectionAacState[i];
            // console.log(`changeAAC reverting==false branch  this.aacState[i] ${this.aacState[i]}`)

        } else {

            this.loadCallback = false;
            this.reverting = true;
            //this.aacState[i]=$event.detail.checked;
            //this.futureAacState[i]=$event.detail.checked;
            this.futureAacState[i] = !this.aacState[i];

            //this.preSelectionAacState[i]=!$event.detail.checked;
            this.preSelectionAacState[i] = this.aacState[i];
            // console.log(`$event.detail.checked ${$event.detail.checked}`)
            // console.log(`  this.futureAacState[i] ${this.futureAacState[i]}`)
            // console.log(`  this.preSelectionAacState[i] ${this.preSelectionAacState[i]}`)


            this.confirmPopup($event, 'ALERT', 'CONFIRM_AAC_CHANGE', 'CONFIRM',
                () => this.onChangeAAC(grp, i),
                () => this.revertAAC(grp, i))

        }

    }

    revertAAC(grp, i) {

        grp.PAR_AAC = (this.preSelectionAacState[i]) ? 1 : 0;

        this.aacState[i] = this.preSelectionAacState[i];
        this.store.dispatch(new ChangeTableValues('GRP', grp.id, {PAR_AAC: grp.PAR_AAC}));

        // console.log(`revertion completed, this.aacState[i] ${this.aacState[i]} `)


        setTimeout(() => {
            this.reverting = false;
            this.loadCallback = true;
        }, 300);
    }

    /**
     * update item sch style
     */
    schStyle = (grp: GRP): string => {
        return (grp.RTU_SeasonRef) ? 'thermal-cooling' : 'thermal-heating'
    }

    goEditAAC(id) {
        this.router.navigate(['/auto-adaptative-comfort'], {queryParams: {id: id}});

    }

    zones(rms: RMS): ZON[] {
        return _.filter(this.ZON, x => x.CFG_IdxRMS == rms.id);
    }

    fancoils(rms: RMS): FNC[] {
        return _.filter(this.FNC, x => x.CFG_IdxRMS.indexOf(rms.id) != -1)
    }

    is4PipeOr4PipeLike() {
        return (this.MCU.RTU_Flags[FlagsGroup.SEA] == FlagsSEA.DISABLED);
    }

    isHPlusCSystem(grpid: number) {

        let rmsGrp = [];

        rmsGrp = _.filter(this.RMS, x => x.CFG_IdxGRP == grpid);


        for (var rms of rmsGrp) {

            // var trovato = this.isHPlusCRooms(rms)
            // if(trovato)
            //     console.log("H + C Room", trovato)
            if (this.isHPlusCRooms(rms, true) || this.isHPlusCRooms(rms, false)) {
                return true
            }
        }
        return false
    }


    showCoolingDiff() {
        for (var rms of this.RMS) {
            // var trovato = this.isHPlusCRooms(rms)
            // if(trovato)
            //     console.log("H + C Room", trovato)
            if (this.isHPlusCRooms(rms, true)) {
                return true
            }
        }
        return false
    }


    showHeatingDiff() {

        for (var rms of this.RMS) {
            // var trovato = this.isHPlusCRooms(rms)
            // if(trovato)
            //     console.log("H + C Room", trovato)
            if (this.isHPlusCRooms(rms, false)) {
                return true
            }
        }
        return false

    }


    //parametrizzata per stagione

    isHPlusCRooms(rms: RMS, whenCooling: boolean) {

        if (!whenCooling)
            console.log(`%crmsName ${rms.CFG_Name} idx ${rms.id}`, 'font-size:0.8rem;color:red');


        let rmsGroup = rms.CFG_IdxGRP;

        //diventa disponibile nella pagina del cambio stagione - per i sistemi due tubi che hanno questo metodo che restituisce true
        //ovvero se le zone collegate ad un rms possono fare H e C simultaneamente

        //gestine CfgDoElectricHeat, aggiunto il 1/3/2022

        var H = false, C = false
        var zones = this.zones(rms)
        var fncs = this.fancoils(rms)

        if (zones.length == 0 && fncs.length == 0) return false


        if (fncs.length == 1) {
            var fnc = fncs[0]
            let grp = this.GRP[fnc.CFG_IdxGRP];
            let modeBitArray = new BitArray(25).fromNumber(fnc.PAR_ModeFC);

            if (fnc.CFG_CfgDoElectricHeat != 0 && modeBitArray.get(1) && whenCooling) return true;

            if (((modeBitArray.get(6) && whenCooling) || modeBitArray.get(0)) && fnc.CFG_IdxHYS_H == -1 && rmsGroup != fnc.CFG_IdxGRP || ((modeBitArray.get(0) || (modeBitArray.get(6) && whenCooling)) && fnc.CFG_IdxHYS_H != -1 && fnc.CFG_IdxHYS_H != fnc.CFG_IdxHYS_C)) H = true

            if (((modeBitArray.get(7) && !whenCooling) || modeBitArray.get(1)) && fnc.CFG_IdxHYS_C == -1 && rmsGroup != fnc.CFG_IdxGRP || ((modeBitArray.get(1) || (modeBitArray.get(7) && !whenCooling)) && fnc.CFG_IdxHYS_C != -1 && fnc.CFG_IdxHYS_C != fnc.CFG_IdxHYS_H)) C = true


            if (H && C) {
                return true
            }
            if ((modeBitArray.get(0) || (modeBitArray.get(6) && whenCooling)) && fnc.CFG_IdxHYS_H != -1 && rmsGroup != fnc.CFG_IdxGRP)
                H = true;

            if ((modeBitArray.get(1) || (modeBitArray.get(7) && !whenCooling)) && fnc.CFG_IdxHYS_C != -1 && rmsGroup != fnc.CFG_IdxGRP)
                C = true;

        }


        if (fncs.length >= 2) {
            var fncsC = [], fncsH = [];
            for (var i = 0; i < fncs.length; i++) {
                var fnc = fncs[i]
                let modeBitArray = new BitArray(25).fromNumber(fnc.PAR_ModeFC);

                let hysid = (fnc.CFG_IdxHYS != -1) ? this.HYS[fnc.CFG_IdxHYS].id : -1;
                let grpid = (fnc.CFG_IdxGRP != -1) ? this.GRP[fnc.CFG_IdxGRP].id : -1;


                let fnc_hys_grp = {
                    fnc: fnc.id,
                    hys: hysid,
                    grp: grpid
                }


                if (fnc.CFG_CfgDoElectricHeat != 0 && modeBitArray.get(1) && whenCooling) return true;

                if (modeBitArray.get(0) || modeBitArray.get(6)) {
                    fncsH.push(fnc_hys_grp);
                }
                if (modeBitArray.get(1) || modeBitArray.get(7)) {
                    fncsC.push(fnc_hys_grp);
                }
            }


            for (var i = 0; i < fncsH.length; i++) {
                for (var j = 0; j < fncsC.length; j++) {
                    if (fncsH[i].hys != fncsC[j].hys && fncsH[i].grp != fncsC[j].grp || (fncsH[i].hys == fncsC[j].hys && fncsC[j].hys == -1 && fncsH[i].grp != fncsC[j].grp) || (fncsH[i].hys == fncsC[j].hys && fncsC[j].hys == -1 && fncsH[i].grp == fncsC[j].grp && fncsC[j].grp == -1)) {
                        return true
                    }


                }
            }

        }


        //RMS -> 2 ZON (H e C) con 2 impianti HYS ognuno dei 2 associati a 2 gruppi diversi

        if (((H || C) && zones.length >= 1) || zones.length >= 2) {
            var zonesC = [], zonesH = [];

            for (var i = 0; i < zones.length; i++) {
                var zon = zones[i]
                var hys = this.HYS[zon.CFG_IdxHYS]
                if (!hys.CFG_Is2P) return false;

                var grp = this.GRP[hys.CFG_IdxGRP]


                var zon_hys_grp = {
                    zon: zon.id,
                    hys: hys.id,
                    grp: grp.id
                }

                if (zon.PAR_Type == 1) { // H zon
                    zonesH.push(zon_hys_grp)
                } else if (zon.PAR_Type == 2) { // C zon
                    zonesC.push(zon_hys_grp)
                } else if (zon.PAR_Type == 3) { // C zon
                    zonesH.push(zon_hys_grp)
                    zonesC.push(zon_hys_grp)
                } //H + C zon
                // var hysSeason = this.GRP[hys.CFG_IdxGRP].RTU_SeasonRef
                // if(hysSeason == 0 && (zon.CFG_Type == 1 || zon.CFG_Type == 3)) return false // HYS e ZON associate
                // if(hysSeason == 1 && (zon.CFG_Type == 2 || zon.CFG_Type == 3)) return false // HYS e ZON associate
            }

            if ((zonesC.length > 0 && H) || (zonesH.length > 0 && C)) {
                return true
            }
            if ((zonesC.length == 0 && !C) || (zonesH.length == 0 && !H)) return false

            // console.log("H + C Room - zones", zonesC, zonesH, zones)
            for (var i = 0; i < zonesH.length; i++) {
                for (var j = 0; j < zonesC.length; j++) {
                    if (zonesH[i].zon != zonesC[j].zon && zonesH[i].hys != zonesC[j].hys && zonesH[i].grp != zonesC[j].grp) {
                        return true
                    }
                }
            }
        }

        return H && C

    }

    trackById(index, item) {

        return index; // index or item.id
    }

    showRemainingTimeTsRs(grp: GRP, property: string) {

        // Get the duration between currentTimeUtc and endingTimeUtc
        const remainingTime = this.getRemainingTime(grp, property);

        // Don't show the texts and remaining time if the remaining time is 0
        if (remainingTime.asSeconds() > 0) {

            switch (property) {

                case 'RTU_RemainTimeTS':

                    return grp.RTT_FsmSeason === GrpFsmSeason.SMC_AAC_ON || grp.RTT_FsmSeason === GrpFsmSeason.SMC_AAC_END || grp.RTT_FsmSeason === GrpFsmSeason.SMC_MIN_TIME_CR; // 5: AAC On | 6: Wait in ACC for the minimum period (only show in these cases for TS) | 12: Minimum period in a season mode for comfort range logic.

                case 'RTU_RemainTimeRS':

                    return grp.RTT_FsmSeason === GrpFsmSeason.SMC_MIN_TIME_AFTER_AAC; // 9: Wait in reference season mode for the minimum period after an AAC cycle (only show in this case for RS)
            }
        }
    }

    getRemainingTimeDescription(grp: GRP, property: string) {

        if (this.showRemainingTimeTsRs(grp, property)) {

            return this.translate.instant('CURRENT_REMAINING_TIME_TS_RS');
        }

        return "";
    }

    getRemainingTimeFormatted(grp: GRP, property: string) {

        // Don't show the remaining time based
        if (this.showRemainingTimeTsRs(grp, property)) {

            // Get the duration between currentTimeUtc and endingTimeUtc
            const remainingTime = this.getRemainingTime(grp, property);

            // Extract hours, minutes, and seconds from the duration
            const hours = Math.floor(remainingTime.asHours()).toString().padStart(2, '0');
            const minutes = remainingTime.minutes().toString().padStart(2, '0');
            const seconds = remainingTime.seconds().toString().padStart(2, '0');

            // Format the time as hh:mm:ss
            return `${hours}:${minutes}:${seconds}`;
        }

        return "";
    }

    getRemainingTime(grp: GRP, property: string) {

        const currentTimeUtc = moment().utc();

        // Add the number of seconds from this.grp.RemainTimeRS
        const endingTimeUtc = moment(currentTimeUtc).add(grp[property], 'seconds');

        // Get the duration between currentTimeUtc and endingTimeUtc
        return moment.duration(endingTimeUtc.diff(currentTimeUtc));
    }

    getMinTimeDescription(grp: GRP, getMinTimeDescriptionType: GetMinTimeDescriptionType) {

        const systemName = this.GRP.length > 1 ? `${this.translate.instant('CHANGE_AAC_THE_GROUP_1')} ${grp.CFG_Name}${this.translate.instant('CHANGE_AAC_THE_GROUP_2')}` : `${this.translate.instant('THIS_SYSTEM')}`;

        switch (getMinTimeDescriptionType) {

            case GetMinTimeDescriptionType.AutoTS:

                return `${systemName} ${this.translate.instant('CHANGE_AAC_MIN_TIME_TS_DESCRIPTION')}`;

            case GetMinTimeDescriptionType.ManualAacTs:

                return `${this.translate.instant('ONCE_ADAPTIVE_COMFORT_ENGAGES')}, ${systemName.toLowerCase()} ${this.translate.instant('CHANGE_AAC_MIN_TIME_TS_DESCRIPTION')}`;

            case GetMinTimeDescriptionType.ManualAacRs:

                return `${this.translate.instant('ONCE_ADAPTIVE_COMFORT_DISENGAGES')}, ${systemName.toLowerCase()} ${this.translate.instant('CHANGE_AAC_MIN_TIME_RS_DESCRIPTION')}`;
        }

    }

    getAacRequestsDescription(grp: GRP) {

        const orange = grp.PAR_Season ? this.translate.instant('AAC_CURRENT_REQUESTS_DESCRIPTION_ORANGE') : this.translate.instant('AAC_CURRENT_REQUESTS_DESCRIPTION_BLUE');
        const heating = grp.PAR_Season ? this.translate.instant('AAC_CURRENT_REQUESTS_DESCRIPTION_HEATING') : this.translate.instant('AAC_CURRENT_REQUESTS_DESCRIPTION_COOLING');

        return `${this.translate.instant('AAC_CURRENT_REQUESTS_DESCRIPTION_1')} ${orange} ${this.translate.instant('AAC_CURRENT_REQUESTS_DESCRIPTION_2')} ${heating}, ${this.translate.instant('AAC_CURRENT_REQUESTS_DESCRIPTION_3')}`;
    }

    getZonesVotingStatusDescription(grp: GRP) {

        const ZONES_VOTING_STATUS_DESCRIPTION_2 = grp.RTT_FsmSeason === GrpFsmSeason.SMC_NO_CHANGE ? 'ZONES_VOTING_STATUS_DESCRIPTION_2_CURRENT' : 'ZONES_VOTING_STATUS_DESCRIPTION_2_LAST';

        return `${this.translate.instant('ZONES_VOTING_STATUS_DESCRIPTION_1')} ${this.translate.instant(ZONES_VOTING_STATUS_DESCRIPTION_2)} ${this.translate.instant('ZONES_VOTING_STATUS_DESCRIPTION_3')}`;
    }
}
