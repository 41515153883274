<!--<p>______________________</p>
<p> radio {{radio}}</p>
<p>digitalData {{digitalData}}</p>
<p>auxVar {{auxVar}}</p>
<p>schedule {{schedule}}</p>
<p>large {{large}}</p>
<p>valueInteger {{valueInteger}}</p>
<p>valueDecimal {{valueDecimal}}</p>
<p>error {{error}}</p>-->
<!--<p>temperature vale {{temperature}}</p>-->

<!--<div>
    item.id:{{item.id}}
</div>-->

<div *ngIf="radio" [ngClass]="{'disabled-item cursorAuto':inDisabled}"
     class="minHeight counter item radio-counter {{itemClass}}">

    <div (click)="radioClick()" class="d-flex flex-row justify-content-between align-items-center w-100"
         *ngIf="!large && schedule == -1 && (digitalData==undefined || !digitalData || digitalData==[] || digitalData?.length==0)  && (!auxVar || auxVar==undefined)">
        <div class="d-flex flex-row justify-content-start align-items-center">
            <ion-radio
                    *ngIf="radio && ( !large && schedule == -1 && (digitalData==undefined || !digitalData || digitalData==[]) && (!auxVar || auxVar==undefined))"
                    slot="start" [ngClass]="{'radio-disabled':inDisabled && !changableSetpoint, 'hidden': radioHidden}" [checked]="radioChecked"></ion-radio>
            <div class="d-flex flex-row align-items-center small-counter-container"
                 [ngClass]="{ 'disabled cursorAuto':inDisabled}">

                <span *ngIf="icon" class="icon {{ icon }}"></span>
                <span class="icon fas fa-circle messana-on" *ngIf="status == 'on'"></span>
                <span class="icon fas fa-circle messana-off" *ngIf="status == 'off'"></span>
            </div>

            <div>
                <span [ngClass]="{'item-text-wrap-with-icon':icon && !radio,'item-text-wrap':!icon && ! radio}">
                    <!-- questo stava su riga qui sopra, modifca marx86 2/9/2019 class="{{ radio ? '' : (icon ? 'item-text-wrap-with-icon' : 'item-text-wrap') }}" -->
                    <span *ngIf="subLabel">eCO<sub>2</sub>&nbsp;</span> <span class="d-sm-block d-none"
                        [ngClass]="{'text-blue':colorLabel && radioChecked}">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>
                  <span *ngIf="subLabel">eCO<sub>2</sub>&nbsp;</span> <span class="d-block d-sm-none"
                                                                            [ngClass]="{'text-blue':colorLabel && radioChecked}">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>

                </span>
            </div>
        </div>

        <div [ngClass]="{'disabled-small-counter': inDisabled || disabledSP, 'cursorAuto': !changableSetpoint && (inDisabled || disabledSP)}"
             class="small-counter d-flex flex-row justify-content-center align-items-center">

            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>

            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <span *ngIf="!inputField">
                <div *ngIf="isInteger(step) && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }} {{ value }}{{ unit }}
                </div>

                <div *ngIf="!isInteger(step) && valueInternal == -0.5 && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }} -{{ valueInteger }}.{{ valueDecimal }}{{ unit }}
                </div>
                <div *ngIf="!isInteger(step) && valueInternal != -0.5 && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }} {{ valueInteger }}.{{ valueDecimal }}{{ unit }}
                </div>
            </span>

            <span *ngIf="inputField">
                 <div *ngIf="isInteger(step) && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }}
                     <ion-input class="text-center" ngDefaultControl [value]="value" [(ngModel)]="value"
                                (ionInput)="updateVal($event)" type="text" min="0" max="32767"></ion-input>
                     {{ unit }}
                </div>
                <div *ngIf="!isInteger(step) && valueInternal == -0.5 && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }} -<ion-input class="text-center" ngDefaultControl
                                                                     [value]="value" [(ngModel)]="value"
                                                                     (ionInput)="updateVal($event)" type="text" min="0"
                                                                     max="32767"></ion-input>
                    {{ unit }}
                </div>
                <div *ngIf="!isInteger(step) && valueInternal != -0.5 && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }}
                    <ion-input class="text-center" ngDefaultControl [value]="value" [(ngModel)]="value"
                               (ionInput)="updateVal($event)" type="text" min="0" max="32767"></ion-input>
                    {{ unit }}
                </div>
            </span>

            <!-- <div class="counter-increase unselectable" (click)="increase()">&#12297;</div>-->
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>
    </div>

    <!-- <span *ngIf="inDisabled">inDisabled vale true </span>
     <span *ngIf="radio">radio vale true </span>-->
    <!--<span *ngIf="large && schedule == -1 && !digitalData && !auxVar"> large && schedule == -1 && !digitalData && !auxVar </span>-->

    <ion-item [ngClass]="{'disabled cursorAuto':inDisabled}" class="schedule-counter-container w-100"
              *ngIf="schedule == 1 && (digitalData==undefined || !digitalData || digitalData==[])  && (!auxVar || auxVar==undefined)">
        <div class="d-flex flex-row justify-content-around align-items-center w-100">
            <div>
                <ion-label *ngIf="!label">Temp</ion-label>
                <ion-label *ngIf="label">{{label | translate}}</ion-label>
            </div>

            <div class="schedule-counter">
                <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i>
                </div>

                <div *ngIf="error" class="counter-value unselectable">
                    --.-
                </div>
                <div class="counter-value unselectable" *ngIf="!error"><span>{{valueInteger}}</span><span class="dot">.</span><span class="main-counter-decimal">{{valueDecimal}}</span></div>
                <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </ion-item>


    <ion-label [ngClass]="{'disabled cursorAuto':inDisabled}" class="small-counter-container"
               *ngIf="schedule == 0 && (digitalData==undefined || !digitalData || digitalData==[]) && (!auxVar || auxVar==undefined)">
      <span class="d-sm-block d-none">{{ label }}</span>
      <span class="d-block d-sm-none">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>
        <div [ngClass]="{'disabled-small-counter':inDisabled}" class="small-counter">
            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>
            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <div class="counter-value unselectable" *ngIf="!error">{{valueInteger}}<span class="dot">.</span><span class="main-counter-decimal">{{valueDecimal}}</span></div>
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>
    </ion-label>


    <ion-label [ngClass]="{'disabled cursorAuto':inDisabled}" class="small-counter-container"
               *ngIf="schedule == 2 && (digitalData==undefined || !digitalData || digitalData==[]) && (!auxVar || auxVar==undefined)">
        <span class="item-text-wrap d-sm-block d-none">{{ label }}</span>
      <span class="item-text-wrap d-block d-sm-none">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>
        <div [ngClass]="{'disabled-small-counter cursorAuto':inDisabled}" class="small-counter">
            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>
            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <div class="counter-value unselectable" *ngIf="!error">{{valueInteger}}
                <span class="dot">.</span>
                <span class="main-counter-decimal">{{valueDecimal}}</span>
                <span>{{unit}}</span>
            </div>
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>
    </ion-label>

    <!--<p *ngIf="digitalData!=undefined && digitalData.length>0">digital data {{digitalData | json}}</p>-->


    <ion-label [ngClass]="{'disabled cursorAuto':inDisabled}" class="small-counter-container"
               *ngIf="digitalData!=undefined && digitalData?.length>0">
        <span class="item-text-wrap d-sm-block d-none">{{ label | translate }}</span>
      <span class="item-text-wrap d-block d-sm-none">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>
        <div [ngClass]="{'disabled-small-counter cursorAuto':inDisabled}" class="small-counter">
            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>
            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <div class="counter-value unselectable" *ngIf="!error">{{stringValue | translate}}
            </div>
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>
    </ion-label>


    <!--<p  *ngIf="auxVar">aux var </p>-->

    <ion-label [ngClass]="{'disabled cursorAuto':inDisabled}" class="small-counter-container" *ngIf="auxVar">
        <span class="item-text-wrap d-dm-block d-none">{{ label }} </span>
        <span class="item-text-wrap d-block d-sm-none">{{ (mobileLabel != undefined ? mobileLabel : label) }} </span>
        <div [ngClass]="{'disabled-small-counter cursorAuto': (inDisabled || disabledSP)}" class="small-counter">
            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>
            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <div *ngIf="isInteger(step) && !error" class="counter-value unselectable">
                {{ differential ? '&plusmn;' : '' }} {{ value }}{{ unit }}
            </div>
            <div *ngIf="!isInteger(step) && valueInternal == -0.5 && !error" class="counter-value unselectable">
                {{ differential ? '&plusmn;' : '' }} -{{ valueInteger }}.{{ valueDecimal }}{{ unit }}
            </div>
            <div *ngIf="!isInteger(step) && valueInternal != -0.5 && !error" class="counter-value unselectable">
                {{ differential ? '&plusmn;' : '' }} {{ valueInteger }}.{{ valueDecimal }}{{ unit }}
            </div>
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>

    </ion-label>
</div>


<!-- componente duplicata, qui la uso come ion-item se non ho radio all'interno del counter -->


<ion-item *ngIf="!radio" [ngClass]="{'cursorAuto':inDisabled && !changableSetpoint, 'disabled-item':inDisabled,'on-off-counter':on || off,'dualSetPointCooling':dualSetPoint && staticSeason == 1,'dualSetPointHeating':dualSetPoint && staticSeason == 0}"
          class="counter item no-radio-counter {{itemClass}}" >
    <!-- setpoint with arrow zone - macro-zone -->

    <div *ngIf="large" class="main-counter-container d-flex flex-row justify-content-center align-items-center w-100"
         [ngClass]="{'disabled-main-counter' : inDisabled || rtu }">

        <div *ngIf="!rtu"
             class="setpointLeft main-counter-decrease unselectable heightEqualizer d-flex flex-row justify-content-center align-items-center"
             (click)="decrease()"><span class="icon-arrow-left"></span></div>

        <div class="main-counter-value unselectable" *ngIf="!rtu && !error">
            <div class="d-flex flex-row justify-content-center align-items-center heightEqualizer">
                <div class="heightEqualizer"><span class="main-counter-int">{{ valueInteger }}</span></div>
                <ng-container *ngIf="!noDotDisplay">
                    <div class="heightEqualizer"><span class="dot">.</span></div>
                    <div class="heightEqualizerDecimal positionRelative"><span
                            class="main-counter-decimal">{{ valueDecimal }}</span>
                        <!--<span class="main-counter-unit positionAbsolute">°</span>--></div>
                </ng-container>
            </div>
        </div>

        <div class="main-counter-value unselectable" *ngIf="!rtu && error">
            <div class="d-flex flex-row justify-content-center align-items-center heightEqualizer">
                <span class="main-counter-int">--.-</span>
            </div>
        </div>

        <div class="main-counter-value unselectable" *ngIf="rtu">

            <div *ngIf="!staticNull && staticValue>=-1000 && valueInteger!=0 && valueInteger!=-3277 && !error">
                <div [ngClass]="rtuClassColor ? rtuClassColor : ''" class="d-flex flex-row justify-content-center align-items-center heightEqualizer">
                    <div class="heightEqualizer"><span class="main-counter-int">{{ valueInteger }}</span></div>
                    <div class="heightEqualizer"><span class="dot">.</span></div>
                    <div class="heightEqualizerDecimal positionRelative"><span class="main-counter-decimal">{{ valueDecimal }}</span>
                        <!--<span class="main-counter-unit positionAbsolute">°</span>--></div>
                </div>

            </div>
            <div *ngIf="staticNull || valueInteger==0 || valueInteger==-3277 || error"><span class="main-counter-int">--.-<span
                    class="main-counter-unit"></span></span></div>

        </div>

        <div *ngIf="!rtu"
             class="setpointRight main-counter-increase unselectable heightEqualizer d-flex flex-row justify-content-center align-items-center"
             (click)="increase()"><span class="icon-arrow-right"></span></div>
    </div>
    <!-- END setpoint with arrow zone - macro-zone -->


    <!--<p *ngIf="!large && schedule == -1 && digitalData==[] && digitalData?.length==0 && !auxVar">!large && schedule == -1 && digitalData==[] && digitalData?.length==0 && !auxVar</p>-->
    <div class="d-flex flex-row justify-content-between align-items-center w-100"
         *ngIf="!large && schedule == -1 && (digitalData==undefined || !digitalData || digitalData==[] || digitalData?.length==0) && (!auxVar || auxVar==undefined)">

        <div class="d-flex flex-row justify-content-start align-items-center">
            <div *ngIf="on" class="d-flex flex-row justify-content-start align-items-center on {{customLightbulbIconClass}}"
                 [ngClass]="{ 'disabled cursorAuto': inDisabled}">
                <i class="icon fad fa-lightbulb"></i>
            </div>
            <div *ngIf="off" class="d-flex flex-row justify-content-start align-items-center off {{customLightbulbIconClass}}"
                 [ngClass]="{ 'disabled cursorAuto':inDisabled}">
                <i class="icon fad fa-lightbulb"></i>
            </div>

            <div class="d-flex flex-row align-items-center small-counter-container"
                 [ngClass]="{'cursorAuto':inDisabled && !changableSetpoint, 'disabled':inDisabled}">

                <span *ngIf="icon" class="icon {{ icon }}"></span>
                <span class="icon fas fa-circle messana-on" *ngIf="status == 'on'"></span>
                <span class="icon fas fa-circle messana-off" *ngIf="status == 'off'"></span>
            </div>

            <div>
                <span class="floatLeft" [ngClass]="{'item-text-wrap-with-icon':icon && !radio,'item-text-wrap':!icon && ! radio}">
                    <!-- questo stava su riga qui sopra, modifca marx86 2/9/2019 class="{{ radio ? '' : (icon ? 'item-text-wrap-with-icon' : 'item-text-wrap') }}" -->
                    <span class="floatLeft" *ngIf="subLabel">eCO<sub>2</sub>&nbsp;</span>&nbsp;<span class="floatLeft d-sm-block d-none" [ngClass]="{'text-blue':colorLabel && radioChecked}">{{ label }}</span>
                    <span class="floatLeft d-block d-sm-none" [ngClass]="{'text-blue':colorLabel && radioChecked}">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>
                    <span class="floatLeft" [innerHtml]="innerHtmlLabel" *ngIf="innerHtmlLabel"></span>
                </span>
            </div>
        </div>


        <div [ngClass]="{'disabled-small-counter': inDisabled || disabledSP, 'cursorAuto': !changableSetpoint && (inDisabled || disabledSP)}"
             class="small-counter d-flex flex-row justify-content-center align-items-center">

            <div *ngIf="!rtu" class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>

            <div *ngIf="!rtu && error" class="counter-value unselectable">
                --.-
            </div>

            <span *ngIf="!inputField">

                <ng-container *ngIf="rtu">

                    <div [ngClass]="{'rtu_as_aligned_right_label': rtu_as_aligned_right_label}" *ngIf="!staticNull" class="counter-value unselectable">{{ valueInteger }}.{{valueDecimal}}{{unit}}</div>
                    <div [ngClass]="{'rtu_as_aligned_right_label': rtu_as_aligned_right_label}" *ngIf="staticNull || error" class="counter-value unselectable">--.-</div>

                </ng-container>

                <ng-container *ngIf="!rtu">

                <div *ngIf="isInteger(step) && !error && !fad" class="counter-value unselectable">
                    <span [ngClass]="{'font-weight-bold': MCU.PAR_UM === 0 && showFahrenheitToCelsiusConversionBelow}">{{ differential ? '&plusmn;' : '' }} {{value}}{{unit}}<ng-container *ngIf="MCU.PAR_UM === 0 && showFahrenheitToCelsiusConversionBelow">F</ng-container></span>
                    <ng-container *ngIf="showFahrenheitToCelsiusConversionBelow">
                        <hr class="margin0">
                        <span class="celsius-sublabel-font-size">{{ differential ? '&plusmn;' : '' }} {{abstractConfigPage?.fahrenheitToCelsius(value)}}{{unit}}C</span>
                    </ng-container>
                </div>
                <div *ngIf="isInteger(step) && !error && fad" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }} {{ value < max ? value : 'OFF' }}{{ value < max ? unit : ''  }}
                </div>
                <div *ngIf="!isInteger(step) && valueInternal == -0.5 && !error" class="counter-value unselectable">
                    <span>{{ differential ? '&plusmn;' : '' }} -{{ valueInteger }}.{{valueDecimal}}{{unit}}</span>
                </div>
                <div *ngIf="!isInteger(step) && valueInternal != -0.5 && !error" class="counter-value unselectable">
                    <span [ngClass]="{'font-weight-bold': MCU.PAR_UM === 0 && showFahrenheitToCelsiusConversionBelow}">{{ differential ? '&plusmn;' : '' }} {{ valueInteger }}.{{ valueDecimal}}{{unit}}<ng-container *ngIf="MCU.PAR_UM === 0 && showFahrenheitToCelsiusConversionBelow">F</ng-container></span>
                    <ng-container *ngIf="showFahrenheitToCelsiusConversionBelow">
                        <hr class="margin0">
                        <span class="celsius-sublabel-font-size">{{ differential ? '&plusmn;' : '' }} {{abstractConfigPage?.fahrenheitToCelsius(valueInteger+'.'+valueDecimal)}}{{unit}}C</span>
                    </ng-container>
                </div>

                </ng-container>

            </span>

            <span *ngIf="inputField">
                 <div *ngIf="isInteger(step) && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }}
                     <ion-input class="text-center" ngDefaultControl [value]="value" [(ngModel)]="value"
                                (ionInput)="updateVal($event)" type="text" min="0" max="32767"></ion-input>
                     {{unit}}
                </div>
                <div *ngIf="!isInteger(step) && valueInternal == -0.5 && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }} -<ion-input class="text-center" ngDefaultControl
                                                                     [value]="value" [(ngModel)]="value"
                                                                     (ionInput)="updateVal($event)" type="text" min="0"
                                                                     max="32767"></ion-input>
                    {{unit}}
                </div>
                <div *ngIf="!isInteger(step) && valueInternal != -0.5 && !error" class="counter-value unselectable">
                    {{ differential ? '&plusmn;' : '' }}
                    <ion-input class="text-center" ngDefaultControl [value]="value" [(ngModel)]="value"
                               (ionInput)="updateVal($event)" type="text" min="0" max="32767"></ion-input>
                    {{unit}}
                </div>
            </span>

            <div *ngIf="!rtu" class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>
    </div>


    <ion-item [ngClass]="{'disabled cursorAuto':inDisabled}" class="schedule-counter-container w-100"
              *ngIf="schedule == 1 && (digitalData==undefined || !digitalData || digitalData==[]) && (!auxVar || auxVar==undefined)">
        <!--<p *ngIf="schedule == 1 && digitalData!=[] && digitalData?.length>0 && !auxVar"> schedule vale 11 e not auxVar</p>-->

        <div class="d-flex flex-row justify-content-around align-items-center w-100">
            <div>
                <ion-label *ngIf="!label">Temp</ion-label>
                <ion-label *ngIf="label">{{label | translate}}</ion-label>
            </div>
            <div class="schedule-counter">
                <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i>
                </div>

                <div *ngIf="error" class="counter-value unselectable">
                    --.-
                </div>
                <div class="counter-value unselectable" *ngIf="!error"><span>{{valueInteger}}</span><span class="dot">.</span><span class="main-counter-decimal">{{valueDecimal}}</span></div>
                <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </ion-item>


    <!--<p  *ngIf="schedule == 0 && digitalData!=[] && !auxVar">schedule {{schedule}}</p>-->
    <div [ngClass]="{'disabled cursorAuto':inDisabled}"
         class="d-flex flex-row justify-content-between align-items-center w-100 small-counter-container"
         *ngIf="schedule == 0 && (digitalData==undefined || !digitalData || digitalData==[]) && (!auxVar || auxVar==undefined)">
        <div class="d-flex flex-row justify-content-start align-items-center">
            <span class="d-sm-block d-none">{{ label }}</span>
            <span class="d-block d-sm-none">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>
        </div>
        <div [ngClass]="{'disabled-small-counter':inDisabled}" class="small-counter">
            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>
            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <div class="counter-value unselectable" *ngIf="!error">{{valueInteger}}<span class="dot">.</span><span class="main-counter-decimal">{{valueDecimal}}</span></div>
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>
    </div>

    <!--<p *ngIf="schedule == 2 && digitalData!=[] && !auxVar">schedule {{schedule}}</p>-->
    <div [ngClass]="{'disabled cursorAuto':inDisabled}"
         class="d-flex flex-row justify-content-between align-items-center w-100 small-counter-container"
         *ngIf="schedule == 2 && (digitalData==undefined || !digitalData || digitalData==[]) && (!auxVar || auxVar==undefined)">
        <div class="d-flex flex-row justify-content-start align-items-center">
            <span class="item-text-wrap w-100 d-sm-block d-none">{{ label }}</span>
          <span class="item-text-wrap w-100 d-block d-sm-none">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>
        </div>
        <div [ngClass]="{'disabled-small-counter cursorAuto':inDisabled}" class="small-counter">
            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>
            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <div class="counter-value unselectable" *ngIf="!error">{{valueInteger}}<span class="dot">.</span><span class="main-counter-decimal">{{valueDecimal}}</span><span>{{unit}}</span></div>
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>
    </div>


    <!--<p *ngIf="digitalData!=[] && digitalData?.length>0">digital data {{digitalData | json}}</p>-->

    <div [ngClass]="{'cursorAuto':inDisabled && !changableSetpoint, 'disabled':inDisabled}"
         class="d-flex flex-row justify-content-between align-items-center w-100 small-counter-container"
         *ngIf="digitalData!=undefined && digitalData?.length>0">
        <div class="d-flex flex-row justify-content-start align-items-center">
            <span class="item-text-wrap w-100 d-sm-block d-none">{{ label | translate }}</span>
          <span class="item-text-wrap w-100 d-bock d-sm-none">{{ (mobileLabel != undefined ? mobileLabel : label) }}</span>
        </div>
        <div [ngClass]="{'cursorAuto':inDisabled && !changableSetpoint, 'disabled-small-counter':inDisabled}" class="small-counter">
            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>
            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <div [innerHTML]="stringValue | translate" class="counter-value unselectable" *ngIf="!error"></div>
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>
    </div>


    <!--<p  *ngIf="auxVar">aux var </p>-->

    <div [ngClass]="{'cursorAuto':inDisabled && !changableSetpoint, 'disabled':inDisabled}"
         class="d-flex flex-row justify-content-between align-items-center w-100 small-counter-container"
         *ngIf="auxVar">
        <div class="d-flex flex-row justify-content-start align-items-center">
            <span class="item-text-wrap w-100 d-sm-block d-none">{{ label }} </span>
            <span class="item-text-wrap w-100 d-block d-sm-none">{{ (mobileLabel != undefined ? mobileLabel : label) }} </span>
        </div>
        <div [ngClass]="{'disabled-small-counter cursorAuto': (inDisabled || disabledSP)}" class="small-counter">
            <div class="counter-decrease unselectable" (click)="decrease()"><i class="fal fa-chevron-left"></i></div>
            <div *ngIf="error" class="counter-value unselectable">
                --.-
            </div>
            <div *ngIf="isInteger(step) && !error" class="counter-value unselectable">
                {{ differential ? '&plusmn;' : '' }} {{value}}{{unit}}
            </div>
            <div *ngIf="!isInteger(step) && valueInternal == -0.5 && !error" class="counter-value unselectable">
                {{ differential ? '&plusmn;' : '' }} -{{ valueInteger }}.{{ valueDecimal}}{{unit}}
            </div>
            <div *ngIf="!isInteger(step) && valueInternal != -0.5 && !error" class="counter-value unselectable">
                {{ differential ? '&plusmn;' : '' }} {{ valueInteger }}.{{ valueDecimal}}{{unit}}
            </div>
            <div class="counter-increase unselectable" (click)="increase()"><i class="fal fa-chevron-right"></i></div>
        </div>

    </div>
</ion-item>
