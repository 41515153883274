export enum AlignIconToRadioButton {

    none,
    androidAndWeb,
    iOS
}

export enum GrpFsmSeason {

    SMC_NO_CHANGE,          // 0: Finite state machine for season mode change: no change.

    SMC_WAIT_PRE,           // 1: Finite state machine for season mode change: wait the programmable delay before season mode change (for sub-system stop).

    SMC_WAIT_POST,          // 2: Finite state machine for season mode change: wait the programmable delay after season mode change (for valves commutation).

    SMC_WAIT_PRE_AAC,       // 3: Finite state machine for season mode change: wait the programmable delay before season mode change for an AAC request (for sub-system stop).

    SMC_WAIT_POST_AAC,      // 4: Finite state machine for season mode change: wait the programmable delay after season mode change for an AAC request (for valves commutation).

    SMC_AAC_ON,             // 5: Finite state machine for season mode change: systems on AAC.

    SMC_AAC_END,            // 6: Finite state machine for season mode change: end of AAC request: the systems stay in AAC for a minimum configurable time.

    SMC_WAIT_PRE_RET_AAC,   // 7: Finite state machine for season mode change: wait the programmable delay before season mode change for return from AAC (for sub-system stop)

    SMC_WAIT_POST_RET_AAC,  // 8: Finite state machine for season mode change: wait the programmable delay after season mode change for return from AAC (for valves commutation)

    SMC_MIN_TIME_AFTER_AAC, // 9: Finite state machine for season mode change: wait for minimum time in reference season after an AAC cycle.

    SMC_WAIT_PRE_CR,        // 10: Finite state machine for season mode change: wait the programmable delay before season mode change (for sub-system stop) in comfort range logic.

    SMC_WAIT_POST_CR,       // 11: Finite state machine for season mode change: wait the programmable delay after season mode change (for valves commutation) in comfort range logic.

    SMC_MIN_TIME_CR,        // 12: Finite state machine for season mode change: minimum period in a season mode for comfort range logic.
}

export enum HeatPumpModels {

    SpacePakSIM = 3,
    PhinxHeroPlus = 5,
    SpheraEvo2= 6,
    SolarThermalPlus = 7,
    Viessmann = 8,
}
