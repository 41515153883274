<ion-header>
    <ion-toolbar messana>
        <ion-title>{{ 'SEASON_CHANGEOVER' | translate }}</ion-title>
        <ion-buttons slot="start">
            <back-button-custom (click)="goBack()"></back-button-custom>
        </ion-buttons>
        <ion-buttons slot="end">
            <button class="button-spacer"></button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="outer-content season">
    <div *ngIf="!connected" class="spinner-cover">
        <div class="mac-spinner center cover">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
        </div>
    </div>
    <form>
        <ion-list *ngIf="connected">

            <div class="rounded-container"
                 *ngFor="let grp of validGRP;let i = index; let lastElem=last; trackBy: trackById">

                <ion-list>

                    <div *ngIf="validGRP">

                        <ion-list-header class="bg-header text-black ">

                            <div class="d-flex flex-row justify-content-between align-items-center w-100">
                                <div class="d-flex flex-row justify-content-start align-items-center">
                                    <div class="d-flex flex-row align-items-center text-black">
                                        <span [ngClass]="{'fa fa-cloud':!grp.RTU_OnSC,'icon-seasonchange fa-spin':grp.RTU_OnSC}"
                                              class=""></span>
                                    </div>
                                    <div class="d-flex flex-row align-items-center title text-black">

                                        {{ grp.CFG_Name || ('Group #' + grp.id) }}

                                    </div>
                                </div>

                                <div class="d-flex flex-row justify-content-end align-items-center">

                                    <ion-icon name="more" item-right (click)="openMenu($event, grp)"
                                              click-stop-propagation></ion-icon>
                                </div>

                            </div>


                        </ion-list-header>
                        <ion-radio-group [(ngModel)]="userMode[i]" [ngModelOptions]="{standalone: true}">
                            <!-- ******************* -->
                            <!-- MANUAL RADIO CHOICE -->
                            <!-- ******************* -->
                            <ion-item class="no-lines">
                                <ion-label class="label-radio "
                                           [ngClass]="{'text-blue':userMode[i]==0}"> {{ 'MANUAL' | translate }}
                                </ion-label>
                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                           (click)="changeMode(grp, 0, i)" [value]="0"></ion-radio>
                            </ion-item>
                            <!-- *************** -->
                            <!-- MANUAL SUBTITLE -->
                            <!-- *************** -->
                            <ion-item [ngClass]="{'cursorAuto':grp.RTU_OnSC}"
                                      class="item season-subtitle-manual bottomSeparator {{ grp.RTU_OnSC ? 'disabled':''}}"
                                      (click)="changeMode(grp, 0, i)">
                                <!-- *************** -->
                                <!-- 4PIPE SUBTITLE -->
                                <!-- *************** -->
                                <span *ngIf="is4Pipe(grp.id)" class="hc-description-thin"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'MANUAL_CHANGEOVER_SUBTITLE_4PIPE' | translate }}</span>
                                <!-- *************************************** -->
                                <!-- NOT 4PIPE && CAN CHANGE SEASON SUBTITLE -->
                                <!-- *************************************** -->
                                <span *ngIf="!is4Pipe(grp.id) && canChangeSeason(grp.id)" class="hc-description-thin"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'MANUAL_CHANGEOVER_SUBTITLE' | translate }}</span>
                                <!-- ****************************************** -->
                                <!-- NOT 4PIPE && CANNOT CHANGE SEASON SUBTITLE -->
                                <!-- ****************************************** -->
                                <span *ngIf="!is4Pipe(grp.id) && !canChangeSeason(grp.id)" class="hc-description-thin"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'MANUAL_CHANGEOVER_SUBTITLE_4PIPE' | translate }}</span>
                            </ion-item>


                            <!-- ********************************************* -->
                            <!-- AUTO RADIO CHOICE           GRP_PAR_Season 2  -->
                            <!--   system that change season                   -->
                            <!-- ********************************************* -->
                            <ion-item class="no-lines "
                                      *ngIf="MCU?.radiantSystem && (canChangeSeason(grp.id) && !is4Pipe(grp.id))">
                                <ion-label class="label-radio"
                                           [ngClass]="{'text-blue':userMode[i]==2}">{{ 'AUTO' | translate }}
                                </ion-label>
                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                           (click)="changeMode(grp, 2, i)" [value]="2"></ion-radio>
                            </ion-item>

                            <!-- ********************************************************** -->
                            <!-- AUTO RADIO CHOICE                         GRP_PAR_Season 3 -->
                            <!-- 4pipe or                                                   -->
                            <!-- 2pipe with dead band or hc setpoint diff or dual setpoint  -->
                            <!-- ********************************************************** -->
                            <ion-item class="no-lines" *ngIf="MCU?.radiantSystem && (!canChangeSeason(grp.id) || is4Pipe(grp.id))">
                                <ion-label class="label-radio"
                                           [ngClass]="{'text-blue':userMode[i]==3}">{{ 'AUTO' | translate }}
                                </ion-label>
                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                           (click)="changeMode(grp, 3, i)" [value]="3"></ion-radio>
                            </ion-item>


                            <!-- *********************************************************************************** -->
                            <!-- AUTO SUBTITLE                                                                       -->
                            <!-- auto subtitle 4pipe(deadband) or 2pipe that cannot change season - double terminals -->
                            <!--                          deadband or sp diff or dual setpoint                       -->
                            <!-- *********************************************************************************** -->
                            <ion-item *ngIf="MCU?.radiantSystem && (!canChangeSeason(grp.id) || is4Pipe(grp.id))"
                                      class="item season-subtitle-manual  bottomSeparator {{ grp.RTU_OnSC ? 'disabled':''}}">

                                <!-- ***************** -->
                                <!-- DEADBAND SUBTITLE -->
                                <!-- ***************** -->
                                <span *ngIf="grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.DEADBAND" class="hc-description-thin"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'AUTO_CHANGEOVER_4PIPE_SUBTITLE' | translate }}</span>
                                <!-- *********************************** -->
                                <!-- H/C SETPOINT DIFFERENTIALS SUBTITLE -->
                                <!-- *********************************** -->
                                <span *ngIf="grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.SETPOINT_DIFF" class="hc-description-thin"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'AUTO_SP_DIFF_SUBTITLE' | translate }}</span>
                                <!-- *********************************** -->
                                <!-- DUAL SETPOINT SUBTITLE -->
                                <!-- *********************************** -->
                                <span *ngIf="grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT" class="hc-description-thin"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'DUAL_SETPOINT_SUBTITLE' | translate }}</span>
                            </ion-item>

                            <!-- *********************************************************************************** -->
                            <!-- AUTO SUBTITLE                                                                       -->
                            <!-- auto subtitle 2pipe && can change season                                            -->
                            <!-- *********************************************************************************** -->
                            <ion-item *ngIf="MCU?.radiantSystem && !is4Pipe(grp.id) && canChangeSeason(grp.id)"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}"
                                      class="item season-subtitle-manual bottomSeparator  {{ grp.RTU_OnSC ? 'disabled':''}}"
                                      (click)="changeMode(grp, 2, i)">

                                <!-- ***************** -->
                                <!-- DEADBAND SUBTITLE -->
                                <!-- ***************** -->
                                <span class="hc-description-thin" *ngIf="grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.DEADBAND"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'AUTO_CHANGEOVER_SUBTITLE' | translate }}</span>

                                <!-- *********************************** -->
                                <!-- DUAL SETPOINT SUBTITLE -->
                                <!-- *********************************** -->
                                <span *ngIf="grp.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT" class="hc-description-thin"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'AUTO_CHANGEOVER_DUAL_SETPOINT_SUBTITLE' | translate }}</span>
                            </ion-item>

                            <!-- *********************************************************************************** -->
                            <!-- SCHEDULE RADIO CHOICE                                                               -->
                            <!-- can change season                                                                   -->
                            <!-- *********************************************************************************** -->
                            <ion-item [ngClass]="{'disabled-item': ios && (grp.RTU_OnSC || validSeasonSCH.length == 0)}" class="no-lines "
                                      *ngIf="MCU?.radiantSystem && canChangeSeason(grp.id)">
                                <ion-label class="label-radio"
                                           [ngClass]="{'text-blue':userMode[i]==-1}">{{ 'SCHEDULE' | translate }}
                                </ion-label>
                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}"
                                           [disabled]="grp.RTU_OnSC || validSeasonSCH.length == 0"
                                           (click)="changeMode(grp, -1, i)" [value]="-1"></ion-radio>
                            </ion-item>

                            <!-- *********************************************************************************** -->
                            <!-- SCHEDULE SUBTITLE                                                                   -->
                            <!-- can change season                                                                   -->
                            <!-- *********************************************************************************** -->
                            <ion-item *ngIf="MCU?.radiantSystem && canChangeSeason(grp.id)"
                                      [ngClass]="{'cursorAuto': grp.RTU_OnSC, 'disabled-item': ios && (grp.RTU_OnSC || validSeasonSCH.length == 0)}"
                                      class="item season-subtitle-manual bottomSeparator {{ grp.RTU_OnSC ? 'disabled':''}}"
                                      (click)="changeMode(grp, -1, i)"
                                      [disabled]="grp.RTU_OnSC || validSeasonSCH.length == 0">
                                <span class="hc-description-thin"
                                      [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{ 'SCHEDULE_CHANGEOVER_SUBTITLE' | translate }}</span>

                            </ion-item>
                        </ion-radio-group>

                        <div class="bottomLine"></div>

                        <!-- ******************* -->
                        <!-- MANUAL SETTINGS     -->
                        <!-- ******************* -->
                        <div *ngIf="userMode[i] == 0">

                            <collapse class="scheduleCollapse" [(ngModel)]="manualChangeoverSettingsShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="manualChangeoverSettingsShow" ngDefaultControl
                                      label="{{'MANUAL_CHANGEOVER' | translate}}"></collapse>

                            <ion-list *ngIf="manualChangeoverSettingsShow[i]" class="no-lines">

                                <!-- ************************************** -->
                                <!-- MANUAL HEATING / COOLING RADIO CHOICE  -->
                                <!-- ************************************** -->
                                <ion-radio-group [(ngModel)]="seasonSelectedOption[grp.id]"
                                                 [ngModelOptions]="{standalone: true}">

                                    <!-- ********************** -->
                                    <!-- MANUAL HEATING CHOICE  -->
                                    <!-- ********************** -->
                                    <ion-item>
                                        <ion-label class="label-radio"
                                                   [ngClass]="{'text-blue':seasonSelectedOption[grp.id]==0}">{{ 'HEATING_MODE' | translate }}
                                        </ion-label>
                                        <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                                   (click)="changeSeason(grp, 0, i)" [value]="0"></ion-radio>
                                    </ion-item>
                                    <!-- ********************** -->
                                    <!-- MANUAL COOLING CHOICE  -->
                                    <!-- ********************** -->
                                    <ion-item>
                                        <ion-label class="label-radio"
                                                   [ngClass]="{'text-blue':seasonSelectedOption[grp.id]==1}">{{ 'COOLING_MODE' | translate }}
                                        </ion-label>
                                        <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                                   (click)="changeSeason(grp, 1, i)" [value]="1"></ion-radio>
                                    </ion-item>
                                </ion-radio-group>

                                <!-- ******************************************************************* -->
                                <!-- when manual is selected auto adaptative comofrt option is available -->
                                <!-- MANUAL SETTINGS    auto adaptative switch                           -->
                                <!-- ******************************************************************* -->
                                <ng-container *ngIf="MCU?.radiantSystem && canChangeSeason(grp.id)">

                                    <ion-item class="no-lines">
                                        <ion-label class="label-radio">{{ 'AUTO_ADAPTATIVE_COMFORT_MODE' | translate }}</ion-label>
                                        <ion-toggle [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="{'toggle-checked':aacState[i],'cursorAuto':grp.RTU_OnSC}"
                                                    id="aacSeason" *ngIf="aacState" color="secondary"
                                                    [disabled]="!grp.CFG_CanAAC || grp.RTU_OnSC" [checked]="aacState[i]"
                                                    [ngModel]="aacState[i]" (click)="changeAAC(grp,i,$event)"></ion-toggle>
                                    </ion-item>

                                    <ng-container *ngIf="aacState[i]">

                                        <counter time table="GRP" [item]="grp" var="TEC_AacMinTimeTS" [MCU]="MCU"
                                                 label="{{'AacMinTimeTS' | translate}}" [attrs]="attrs" [whileChange]="onChange"></counter>

                                        <div class="collapse-info-counter info-counter-increased-margin padding-left-subtitle-no-icon no-lines">
                                            {{ getMinTimeDescription(grp, GetMinTimeDescriptionType.ManualAacTs) }} {{ getRemainingTimeDescription(grp, 'RTU_RemainTimeTS') }} <b>{{ getRemainingTimeFormatted(grp, 'RTU_RemainTimeTS') }}</b>
                                        </div>

                                        <counter time table="GRP" [item]="grp" var="TEC_AacMinTimeRS" [MCU]="MCU"
                                                 label="{{'AacMinTimeRS' | translate}}" [attrs]="attrs" [whileChange]="onChange"></counter>

                                        <div class="collapse-info-counter info-counter-increased-margin padding-left-subtitle-no-icon no-lines">
                                            {{ getMinTimeDescription(grp, GetMinTimeDescriptionType.ManualAacRs) }} {{ getRemainingTimeDescription(grp, 'RTU_RemainTimeRS') }} <b>{{ getRemainingTimeFormatted(grp, 'RTU_RemainTimeRS') }}</b>
                                        </div>

                                        <ng-container *ngIf="grp.RTT_FsmSeason === GrpFsmSeason.SMC_NO_CHANGE || grp.RTT_FsmSeason === GrpFsmSeason.SMC_WAIT_PRE_AAC || grp.RTT_FsmSeason === GrpFsmSeason.SMC_WAIT_POST_AAC || grp.RTT_FsmSeason === GrpFsmSeason.SMC_AAC_ON || grp.RTT_FsmSeason === GrpFsmSeason.SMC_AAC_END">

                                            <ion-item class="no-lines margin-top-1-rem">

                                                <ion-label class="label-radio flex-vertical-center">
                                                    <ng-container *ngIf="grp.PAR_Season">{{ 'AAC_CURRENT_REQUESTS_HEATING' | translate }}</ng-container>
                                                    <ng-container *ngIf="!grp.PAR_Season">{{ 'AAC_CURRENT_REQUESTS_COOLING' | translate }}</ng-container>
                                                </ion-label>

                                                <div class="aacThresholdBarContainer">
                                                    <div [style.width.%]="grp.RTU_ThresholdAAC" class="aacThresholdLine"></div>
                                                    <div [style.width.%]="grp.RTU_CurrentAAC" [ngClass]="{'heating-threshold-bg': grp.PAR_Season, 'cooling-threshold-bg': !grp.PAR_Season}" class="aacThresholdBarInternal"></div>
                                                </div>
                                            </ion-item>

                                            <div [innerHTML]="getAacRequestsDescription(grp)" class="collapse-info-counter margin0 padding-left-subtitle-no-icon no-lines"></div>

                                        </ng-container>

                                        <div class="bottomLineFixed"></div>

                                        <collapse class="scheduleCollapse" [(ngModel)]="zoneWeightsAAC[i]"
                                                  [itemClass]="'topLine cursorAuto zoneWeightsCollapseItem'" [ngModelOptions]="{standalone: true}"
                                                  name="showZoneWeightsAAC" ngDefaultControl
                                                  label="{{'ZONE_WEIGHT' | translate}}"></collapse>

                                        <ion-list *ngIf="zoneWeightsAAC[i]" class="no-lines adaptive">

                                            <aac-zone-weights [RMS]="RMS" [MCU]="MCU" [grp]="grp" [seasonPage]="this"></aac-zone-weights>

                                        </ion-list>

                                        <div class="bottomLineFixed"></div>

                                        <collapse class="scheduleCollapse" [(ngModel)]="aacSettingsShow[i]"
                                                  [itemClass]="'topLine cursorAuto zoneWeightsCollapseItem'" [ngModelOptions]="{standalone: true}"
                                                  name="aacSettingsShow" ngDefaultControl
                                                  label="{{'ADAPTIVE_COMFORT_SETTINGS' | translate}}"></collapse>

                                        <ion-list *ngIf="aacSettingsShow[i]" class="no-lines adaptive">

                                            <auto-adaptive-comfort [grp]="grp" [attrs]="attrs" [seasonPage]="this"></auto-adaptive-comfort>

                                        </ion-list>

                                    </ng-container>
                                </ng-container>
                            </ion-list>

                        </div>


                        <!-- ******************* -->
                        <!-- SCHEDULE SETTINGS     -->
                        <!-- ******************* -->
                        <div *ngIf="userMode[i] == -1">

                            <collapse class="scheduleCollapse" [(ngModel)]="scheduleSettingsShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="scheduleSettingsShow" ngDefaultControl
                                      label="{{'SCHEDULE_CHANGEOVER' | translate}}"></collapse>

                            <ion-list *ngIf="scheduleSettingsShow[i]" class="no-lines">

                                <schedule [usePopupIsOpenedCheck]="true"
                                          [ngClass]="{'narrow':sch(grp) && grp?.PAR_IdxSCH != -1}" [class]="schStyle(grp)"
                                          [disabled]="grp.RTU_OnSC" table="GRP" [item]="grp" [grpIdx]="i"
                                          idxVar="PAR_IdxSCH" [SCH]="SCH" noToggle="true"
                                          (seasonSchedChange)="onChangeSeasonSCH($event)"></schedule>

                                <!-- devo capire quali condizioni servono qui   serve testare che season sia uguale a 0 o 1 ? -->

                                <!-- <div class="d-flex flex-row justify-content-center align-items-center">
                                  <button-collapse buttonClass="schedule-messana-button" #schedPrev *ngIf="sch(grp) && grp?.PAR_IdxSCH != -1 && grp?.PAR_IdxSCH != null && grp?.PAR_IdxSCH != undefined" [(ngModel)]="schMode[i]"
                                              [disabled]="grp.RTU_OnSC" label="{{ 'SCH_PREVIEW' | translate }}"  [ngModelOptions]="{standalone: true}"

                                             ></button-collapse>
                                  </div> -->

                                <!-- inizio schedule  preview del collapse  -->
                                <!-- devo capire quali condizioni servono qui -->
                                <schedule-graph [currentDateTime]="currentDateTime"
                                                [config]="config"
                                                *ngIf="currentDateTime.dateTimeAsMoment && sch(grp) && !grp?.RTU_OnSC &&  grp?.PAR_IdxSCH != -1"

                                                [schedule]="sch(grp)" [id]="id(1,i)+'season'"></schedule-graph>
                            </ion-list>
                        </div>

                        <!-- ************************************************** -->
                        <!-- AUTO MODE SETTINGS for PAR_AutoModeStyle           -->
                        <!-- 2pipe that cannot change season - double terminals -->
                        <!-- deadband or sp diff or dual setpoint or 4pipe      -->
                        <!-- ************************************************** -->
                        <div *ngIf="userMode[i] == 3 && MCU?.radiantSystem && (is4Pipe(grp.id) || !canChangeSeason(grp.id))">

                            <collapse class="scheduleCollapse" [(ngModel)]="autoModeUserMode3SettingsShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="autoModeUserMode3SettingsShow" ngDefaultControl
                                      label="{{'AUTO_MODE_SETTINGS' | translate}}"></collapse>

                            <ion-list *ngIf="autoModeUserMode3SettingsShow[i]" class="no-lines">

                                <!-- **************************** -->
                                <!-- AUTO MODE STYLE RADIO CHOICE -->
                                <!-- no changeover                -->
                                <!-- **************************** -->
                                <ion-radio-group [(ngModel)]="grp.PAR_AutoModeStyle" [ngModelOptions]="{standalone: true}">
                                    <main class="flex flex-col justify-center items-center h-screen overflow-y-auto">
                                        <div style="width: auto; height: 100%; margin: 0 1.5rem; background: #fafafa;">
                                            <!-- Accordion Start -->
                                            <accordion [collapsing]="collapsing" [variableControlled]="true" [currentAutoModeStyle]="grp.PAR_AutoModeStyle">
                                                <accordion-item [expanded]="grp.PAR_AutoModeStyle === 0" [itemAutoModeStyle]="0">
                                                    <!-- **************************** -->
                                                    <!-- DEADBAND ___ AUTO MODE STYLE -->
                                                    <!-- **************************** -->
                                                    <ng-template accordionHeader let-toggle="toggle">
                                                        <div class=" d-flex flex-row ion-justify-content-start align-items-center">


                                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                                <ion-label class="clickable label-radio" (click)="changeAutoModeStyle(grp, 0,grp.PAR_AutoModeStyle)"
                                                                           [ngClass]="{'text-blue':grp.PAR_AutoModeStyle==0}">{{ 'DEADBAND_MODE' | translate }}
                                                                </ion-label>

                                                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                                                           (click)="changeAutoModeStyle(grp, 0,grp.PAR_AutoModeStyle)" [value]="0"></ion-radio>

                                                            </div>
                                                        </div>
                                                    </ng-template>

                                                    <ng-template accordionContent>
                                                        <!-- **************************** -->
                                                        <!-- DEADBAND ___ AUTO MODE STYLE -->
                                                        <!-- DEEP SETTINGS                -->
                                                        <!-- **************************** -->
                                                        <!-- *ngIf="grp.PAR_AutoModeStyle==0 && (grp.PAR_Season == 3 || grp.PAR_Season == 2)" -->
                                                        <div class="indentHC">
                                                            <counter [inDisabled]="grp.RTU_OnSC" [temperature]="true" table="GRP" [item]="grp"
                                                                     var="PAR_DiffUnderCR" [MCU]="MCU"
                                                                     label="{{ 'AUTO_CHANGEOVER_UNDER_DIFF' | translate }}" [attrs]="attrs"
                                                                     [whileChange]="onChange"></counter>
                                                            <counter [inDisabled]="grp.RTU_OnSC" [temperature]="true" table="GRP" [item]="grp"
                                                                     var="PAR_DiffOverCR" [MCU]="MCU"
                                                                     label="{{ 'AUTO_CHANGEOVER_OVER_DIFF' | translate }}" [attrs]="attrs"
                                                                     [whileChange]="onChange"></counter>
                                                        </div>
                                                    </ng-template>
                                                </accordion-item>
                                                <accordion-item [expanded]="grp.PAR_AutoModeStyle === 1" [itemAutoModeStyle]="1">
                                                    <!-- ****************************************** -->
                                                    <!-- SETPOINT DIFFERENTIALS ___ AUTO MODE STYLE -->
                                                    <!-- ****************************************** -->
                                                    <ng-template accordionHeader let-toggle="toggle">
                                                        <div class=" d-flex flex-row ion-justify-content-start align-items-center">

                                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                                <ion-label class="clickable label-radio" (click)="changeAutoModeStyle(grp, 1,grp.PAR_AutoModeStyle)"
                                                                           [ngClass]="{'text-blue':grp.PAR_AutoModeStyle==1}">{{ 'HC_SETPOINT_DIFF_MODE' | translate }}
                                                                </ion-label>
                                                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                                                           (click)="changeAutoModeStyle(grp, 1,grp.PAR_AutoModeStyle)" [value]="1"></ion-radio>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template accordionContent>
                                                        <!-- ****************************************** -->
                                                        <!-- SETPOINT DIFFERENTIALS ___ AUTO MODE STYLE -->
                                                        <!-- DEEP SETTINGS                              -->
                                                        <!-- ****************************************** -->
                                                        <!--  *ngIf="grp.PAR_AutoModeStyle==1 && (grp.PAR_Season == 3 || grp.PAR_Season == 2)" -->
                                                        <div class="indentHC">

                                                            <ion-item>
                                                                <div class="ionic-radio-icon margin-right-radio-icon" [ngClass]="{'radio-checked':grp.PAR_DiffModeSeasonRef==0,'cursorAuto disabled':grp.RTU_OnSC}">
                                                                    <div class="radio-inner"></div>
                                                                    <button (click)="changeDiffModeSeasonRef(grp, 0)" type="button"></button>
                                                                </div>
                                                                <ion-label (click)="changeDiffModeSeasonRef(grp, 0)" class="label-radio"
                                                                           [ngClass]="{'text-blue':grp.PAR_DiffModeSeasonRef == DIFF_MODE_SEASON_REF.HEATING}">{{ 'HEATING_SEASON_REF' | translate }}
                                                                </ion-label>


                                                            </ion-item>
                                                            <ion-item>
                                                                <div class="ionic-radio-icon" [ngClass]="{'radio-checked':grp.PAR_DiffModeSeasonRef==1,'cursorAuto disabled':grp.RTU_OnSC}">
                                                                    <div class="radio-inner"></div>
                                                                    <button (click)="changeDiffModeSeasonRef(grp, 1)" type="button"></button>
                                                                </div>
                                                                <ion-label (click)="changeDiffModeSeasonRef(grp, 1)" class="label-radio"
                                                                           [ngClass]="{'text-blue':grp.PAR_DiffModeSeasonRef == DIFF_MODE_SEASON_REF.COOLING}">{{ 'COOLING_SEASON_REF' | translate }}
                                                                </ion-label>


                                                            </ion-item>

                                                        </div>


                                                    </ng-template>
                                                </accordion-item>

                                                <accordion-item *ngIf="dualSetpointOptionPresent && groupHasRoomSensors(grp.id)" [expanded]="grp.PAR_AutoModeStyle === 2" [itemAutoModeStyle]="2">
                                                    <!-- ****************************************** -->
                                                    <!-- DUAL SETPOINTS      ___    AUTO MODE STYLE -->
                                                    <!-- ****************************************** -->
                                                    <ng-template accordionHeader let-toggle="toggle">
                                                        <div class=" d-flex flex-row ion-justify-content-start align-items-center">

                                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                                <ion-label class="clickable label-radio" (click)="changeAutoModeStyle(grp, 2,grp.PAR_AutoModeStyle)"
                                                                           [ngClass]="{'text-blue':grp.PAR_AutoModeStyle==2}">{{ 'DUAL_SETPOINTS_MODE' | translate }}
                                                                </ion-label>
                                                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                                                           (click)="changeAutoModeStyle(grp, 2,grp.PAR_AutoModeStyle)" [value]="2"></ion-radio>
                                                            </div>
                                                        </div>
                                                    </ng-template>

                                                    <ng-template accordionContent></ng-template>
                                                </accordion-item>
                                            </accordion>
                                            <!-- Accordion End -->

                                        </div>
                                    </main>
                                </ion-radio-group>
                            </ion-list>
                        </div>


                        <!-- ************************************************** -->
                        <!-- END AUTO MODE SETTINGS for PAR_AutoModeStyle       -->
                        <!-- ************************************************** -->


                        <!-- ************************************************** -->
                        <!-- AUTO MODE SETTINGS                                 -->
                        <!-- 2pipe that can change season                       -->
                        <!-- ************************************************** -->
                        <div *ngIf="userMode[i] == 2 && MCU?.radiantSystem">

                            <collapse class="scheduleCollapse" [(ngModel)]="autoModeUserMode2SettingsShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="autoModeUserMode2SettingsShow" ngDefaultControl
                                      label="{{ (canChangeSeason(grp.id) && !is4Pipe(grp.id) ? 'AUTO_CHANGEOVER' : 'AUTO_MODE_SETTINGS') | translate }}"></collapse>

                            <ion-list *ngIf="autoModeUserMode2SettingsShow[i]" class="no-lines">

                                <!-- **************************** -->
                                <!-- AUTO MODE STYLE RADIO CHOICE -->
                                <!-- changeover season            -->
                                <!-- **************************** -->
                                <ion-radio-group [ngModel]="grp.PAR_AutoModeStyle" [ngModelOptions]="{standalone: true}">
                                    <main class="flex flex-col justify-center items-center h-screen overflow-y-auto">
                                        <div style="width: auto; height: 100%; margin: 0 1.5rem; background: #fafafa;">

                                            <!-- Accordion Start -->
                                            <accordion [collapsing]="collapsing" [variableControlled]="true" [currentAutoModeStyle]="grp.PAR_AutoModeStyle">
                                                <accordion-item [expanded]="grp.PAR_AutoModeStyle === 0" [itemAutoModeStyle]="0">
                                                    <!-- **************************** -->
                                                    <!-- DEADBAND ___ AUTO MODE STYLE -->
                                                    <!-- **************************** -->
                                                    <ng-template accordionHeader let-toggle="toggle">
                                                        <div class="d-flex flex-row ion-justify-content-start align-items-center">


                                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                                <ion-label class="clickable label-radio" (click)="changeAutoModeStyle(grp, 0,grp.PAR_AutoModeStyle)"
                                                                           [ngClass]="{'text-blue':grp.PAR_AutoModeStyle==0}">{{ 'DEADBAND_MODE' | translate }}
                                                                </ion-label>

                                                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                                                           (click)="changeAutoModeStyle(grp, 0,grp.PAR_AutoModeStyle)" [value]="0"></ion-radio>

                                                            </div>
                                                        </div>
                                                    </ng-template>

                                                    <ng-template [ngIf]="true" accordionContent>
                                                        <!-- **************************** -->
                                                        <!-- DEADBAND ___ AUTO MODE STYLE -->
                                                        <!-- DEEP SETTINGS                -->
                                                        <!-- **************************** -->
                                                        <!-- *ngIf="grp.PAR_AutoModeStyle==0 && (grp.PAR_Season == 3 || grp.PAR_Season == 2)" -->
                                                        <div class="indentHC">
                                                            <counter [inDisabled]="grp.RTU_OnSC" [temperature]="true" table="GRP" [item]="grp"
                                                                     var="PAR_DiffUnderCR" [MCU]="MCU"
                                                                     label="{{ 'AUTO_CHANGEOVER_UNDER_DIFF' | translate }}" [attrs]="attrs"
                                                                     [whileChange]="onChange"></counter>
                                                            <counter [inDisabled]="grp.RTU_OnSC" [temperature]="true" table="GRP" [item]="grp"
                                                                     var="PAR_DiffOverCR" [MCU]="MCU"
                                                                     label="{{ 'AUTO_CHANGEOVER_OVER_DIFF' | translate }}" [attrs]="attrs"
                                                                     [whileChange]="onChange"></counter>
                                                        </div>
                                                    </ng-template>
                                                </accordion-item>

                                                <!-- ****************************************** -->
                                                <!-- DUAL SETPOINTS      ___    AUTO MODE STYLE -->
                                                <!-- ****************************************** -->
                                                <accordion-item *ngIf="dualSetpointOptionPresent && groupHasRoomSensors(grp.id)" [expanded]="grp.PAR_AutoModeStyle === 2" [itemAutoModeStyle]="2">
                                                    <ng-template accordionHeader let-toggle="toggle">
                                                        <div class=" d-flex flex-row ion-justify-content-start align-items-center">

                                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                                <ion-label class="clickable label-radio" (click)="changeAutoModeStyle(grp, 2,grp.PAR_AutoModeStyle)"
                                                                           [ngClass]="{'text-blue':grp.PAR_AutoModeStyle==2}">{{ 'DUAL_SETPOINTS_MODE' | translate }}
                                                                </ion-label>
                                                                <ion-radio [ngClass]="{'cursorAuto':grp.RTU_OnSC}" [disabled]="grp.RTU_OnSC"
                                                                           (click)="changeAutoModeStyle(grp, 2,grp.PAR_AutoModeStyle)" [value]="2"></ion-radio>
                                                            </div>
                                                        </div>
                                                    </ng-template>

                                                    <ng-template accordionContent></ng-template>
                                                </accordion-item>
                                            </accordion>
                                            <!-- Accordion End -->

                                        </div>
                                    </main>
                                </ion-radio-group>

                                <counter [itemClass]="'margin-top-1-rem'" table="GRP" [item]="grp" var="TEC_AacMinTimeTS" [MCU]="MCU" time
                                         label="{{'AacMinTimeTS' | translate}}" [attrs]="attrs" [whileChange]="onChange"></counter>

                                <div class="collapse-info-counter info-counter-increased-margin padding-left-subtitle-no-icon no-lines">
                                    {{ getMinTimeDescription(grp, GetMinTimeDescriptionType.AutoTS) }} {{ getRemainingTimeDescription(grp, 'RTU_RemainTimeTS') }} <b>{{ getRemainingTimeFormatted(grp, 'RTU_RemainTimeTS') }}</b>
                                </div>

                                <ion-item class="no-lines margin-top-1-rem">

                                    <ion-label *ngIf="grp.RTT_FsmSeason === GrpFsmSeason.SMC_NO_CHANGE" class="label-radio flex-vertical-center">{{'CURRENT_ZONE_VOTING_STATUS' | translate}}</ion-label>
                                    <ion-label *ngIf="grp.RTT_FsmSeason !== GrpFsmSeason.SMC_NO_CHANGE" class="label-radio flex-vertical-center">{{'LAST_ZONE_VOTING_STATUS' | translate}}</ion-label>

                                    <div [ngClass]="{'weight-bar-disabled': grp.RTT_FsmSeason !== GrpFsmSeason.SMC_NO_CHANGE}" class="aacThresholdBarContainer">
                                        <div [style.width.%]="grp.RTU_WeightUnder / (grp.RTU_WeightUnder + grp.RTU_WeightIn + grp.RTU_WeightOver) * 100" class="aacThresholdBarInternal heating-threshold-bg"></div>
                                        <div [style.width.%]="grp.RTU_WeightIn / (grp.RTU_WeightUnder + grp.RTU_WeightIn + grp.RTU_WeightOver) * 100" class="aacThresholdBarInternal neutral-threshold-bg"></div>
                                        <div [style.width.%]="grp.RTU_WeightOver / (grp.RTU_WeightUnder + grp.RTU_WeightIn + grp.RTU_WeightOver) * 100" class="aacThresholdBarInternal cooling-threshold-bg"></div>
                                    </div>

                                </ion-item>

                                <div [innerHTML]="getZonesVotingStatusDescription(grp)" class="collapse-info-counter margin0 padding-left-subtitle-no-icon no-lines"></div>

                                <div class="bottomLineFixed"></div>

                                <collapse class="scheduleCollapse" [(ngModel)]="zoneWeightAuto[i]"
                                          [itemClass]="'topLine cursorAuto zoneWeightsCollapseItem'" [ngModelOptions]="{standalone: true}"
                                          name="showZoneWeightsAuto" ngDefaultControl
                                          label="{{'ZONE_WEIGHT' | translate}}"></collapse>

                                <ion-list *ngIf="zoneWeightAuto[i]" class="no-lines adaptive">

                                    <aac-zone-weights [RMS]="RMS" [MCU]="MCU" [grp]="grp" [seasonPage]="this"></aac-zone-weights>

                                </ion-list>
                            </ion-list>

                            <!--<counter [inDisabled]="grp.RTU_OnSC" [temperature]="true" table="GRP" [item]="grp"
                                     var="PAR_DiffUnderCR" [MCU]="MCU"
                                     label="{{ 'AUTO_CHANGEOVER_UNDER_DIFF' | translate }}" [attrs]="attrs"
                                     [whileChange]="onChange"></counter>
                            <counter [inDisabled]="grp.RTU_OnSC" [temperature]="true" table="GRP" [item]="grp"
                                     var="PAR_DiffOverCR" [MCU]="MCU"
                                     label="{{ 'AUTO_CHANGEOVER_OVER_DIFF' | translate }}" [attrs]="attrs"
                                     [whileChange]="onChange"></counter>-->
                        </div>


                        <!-- two pipes   PAR_GRP_XXX_SPDiff_H e PAR_GRP_XXX_SPDiff_C differentials for rms with zones capable of simultaneous H/C-->
                        <!-- *nIf="isHPlusCSystem()" -->
                        <!--<div *ngIf="isHPlusCSystem(grp.id) && !grp.RTU_OnSC" class="bottomLine"></div>-->
                        <!-- if isHPlusCSystem-->
                        <!--<div *ngIf="isHPlusCSystem(grp.id) && !grp.RTU_OnSC">--><!-- if isHPlusCSystem-->
                        <!--<ion-item class="no-lines ">
                            <ion-label class="label-radio "><span *ngIf="is4PipeOr4PipeLike()">{{'SET_POINT' | translate}}</span><span *ngIf="!is4PipeOr4PipeLike()">{{'AUX' | translate}}</span> {{ 'HC_DIFF' | translate }}</ion-label>

                        </ion-item>


                        <div *ngIf="showHeatingDiff() && grp.RTU_SeasonExe == 0">
                            <counter [inDisabled]="false" [temperature]="true" table="GRP" [item]="grp" var="PAR_SPDiff_H" [MCU]="MCU" label="{{ 'C_DIFF' | translate }}" [attrs]="attrs" [whileChange]="onChange"></counter>


                            <ion-item class="no-lines season-subtitle-manual">
                                <span class="hc-description-thin" [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{'C_DIFF_SUBTITLE' | translate }}</span>
                                --><!-- C_DIFF_SUBTITLE -->
                        <!--</ion-item>
                    </div>-->


                        <!--<div *ngIf="showCoolingDiff() && grp.RTU_SeasonExe == 1">
                            <counter  [inDisabled]="false" [temperature]="true" table="GRP" [item]="grp" var="PAR_SPDiff_C" [MCU]="MCU" label="{{ 'H_DIFF' | translate }}" [attrs]="attrs" [whileChange]="onChange"></counter>


                            <ion-item class="no-lines season-subtitle-manual">
                                <span class="hc-description-thin" [ngClass]="{'cursorAuto':grp.RTU_OnSC}">{{'H_DIFF_SUBTITLE' | translate }}</span>
                                --><!-- H_DIFF_SUBTITLE -->
                        <!--</ion-item>
                     </div>

                </div>-->


                        <!-- related entities section-->
                        <div *ngIf="targetRooms[i]?.length>0" class=" bottomLine"></div>

                        <!-- inizio collapse related zones -->

                        <!-- se ho zone   -->
                        <div *ngIf="targetRooms[i]?.length>0" style="background-color: white">
                            <collapse class="scheduleCollapse " [(ngModel)]="relatedZONShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="showRelatedZones" ngDefaultControl label="{{'RELATED_ROOMS' | translate}}"
                                      [disabled]="targetRooms[i]==undefined || targetRooms[i]==[]"></collapse>
                            <ion-list *ngIf="relatedZONShow[i]" class="no-lines">

                                <related-entities class="w-100"

                                                  [show]="targetRooms[i]!=undefined || targetRooms[i]!=[]"
                                                  [entity]="targetRooms[i]"
                                                  [iconInput]="'fal fa-dot-circle icon'"

                                                  [showAll]="false"

                                                  [noLabel]="'NO_RELATED_ROOMS'"
                                                  [singleLabel]="'RELATED_ROOM'"
                                                  [pluralLabel]="'RELATED_ROOMS'"

                                ></related-entities>


                            </ion-list>
                        </div>

                        <div *ngIf="targetFancoils[i]?.length>0" class=" bottomLine"></div>
                        <!-- icon-fancoil -->

                        <div *ngIf="targetFancoils[i]?.length>0" style="background-color: white">
                            <collapse class="scheduleCollapse " [(ngModel)]="relatedFNCShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="showRelatedFancoils" ngDefaultControl
                                      label="{{'RELATED_FANCOILS' | translate}}"
                                      [disabled]="targetFancoils[i]==undefined || targetFancoils[i]==[]"></collapse>
                            <ion-list *ngIf="relatedFNCShow[i]" class="no-lines">

                                <related-entities class="w-100"

                                                  [show]="targetFancoils[i]!=undefined || targetFancoils[i]!=[]"
                                                  [entity]="targetFancoils[i]"
                                                  [iconInput]="'icon-fancoil icon'"

                                                  [showAll]="false"

                                                  [noLabel]="'NO_RELATED_FANCOIL'"
                                                  [singleLabel]="'RELATED_FANCOIL'"
                                                  [pluralLabel]="'RELATED_FANCOILS'"

                                ></related-entities>


                            </ion-list>
                        </div>

                        <div *ngIf="targetHydronicSystems[i]?.length>0" class=" bottomLine"></div>
                        <!-- se ho hys-->


                        <div *ngIf="targetHydronicSystems[i]?.length>0" style="background-color: white">
                            <collapse class="scheduleCollapse " [(ngModel)]="relatedHYSShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="showRelatedHydronicSystems" ngDefaultControl
                                      label="{{'RELATED_HYDRONICSYSTEMS' | translate}}"
                                      [disabled]="targetHydronicSystems[i]==undefined || targetHydronicSystems[i]==[]"></collapse>
                            <ion-list *ngIf="relatedHYSShow[i]" class="no-lines">

                                <related-entities class="w-100"

                                                  [show]="targetHydronicSystems[i]!=undefined || targetHydronicSystems[i]!=[]"
                                                  [entity]="targetHydronicSystems[i]"
                                                  [iconInput]="'icon-hydronic-system icon'"

                                                  [showAll]="false"

                                                  [noLabel]="'NO_RELATED_HYDRONICSYSTEM'"
                                                  [singleLabel]="'RELATED_HYDRONICSYSTEM'"
                                                  [pluralLabel]="'RELATED_HYDRONICSYSTEMS'"

                                ></related-entities>


                            </ion-list>
                        </div>

                        <!-- se ho ars-->
                        <div *ngIf="targetAirsystems[i]?.length>0" class=" bottomLine"></div>

                        <div *ngIf="targetAirsystems[i]?.length>0" style="background-color: white">
                            <collapse class="scheduleCollapse " [(ngModel)]="relatedARSShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="showRelatedAirSystems" ngDefaultControl
                                      label="{{'RELATED_ARSS' | translate}}"
                                      [disabled]="targetAirsystems[i]==undefined || targetAirsystems[i]==[]"></collapse>
                            <ion-list *ngIf="relatedARSShow[i]" class="no-lines">

                                <related-entities class="w-100"

                                                  [show]="targetAirsystems[i]!=undefined || targetAirsystems[i]!=[]"
                                                  [entity]="targetAirsystems[i]"
                                                  [iconInput]="'icon-atu icon'"

                                                  [showAll]="false"

                                                  [noLabel]="'NO_RELATED_ARS'"
                                                  [singleLabel]="'RELATED_ARS'"
                                                  [pluralLabel]="'RELATED_ARSS'"

                                ></related-entities>


                            </ion-list>
                        </div>

                        <!-- se ho tnk-->
                        <div *ngIf="targetTanks[i]?.length>0" class=" bottomLine"></div>

                        <div *ngIf="targetTanks[i]?.length>0" style="background-color: white">
                            <collapse class="scheduleCollapse " [(ngModel)]="relatedTNKShow[i]"
                                      [itemClass]="'topLine cursorAuto'" [ngModelOptions]="{standalone: true}"
                                      name="showRelatedTanks" ngDefaultControl label="{{'RELATED_TANKS' | translate}}"
                                      [disabled]="targetTanks[i]==undefined || targetTanks[i]==[]"></collapse>
                            <ion-list *ngIf="relatedTNKShow[i]" class="no-lines">

                                <related-entities class="w-100"

                                                  [show]="targetTanks[i]!=undefined || targetTanks[i]!=[]"
                                                  [entity]="targetTanks[i]"
                                                  [iconInput]="'icon-tank icon'"

                                                  [showAll]="false"

                                                  [noLabel]="'NO_RELATED_TANK'"
                                                  [singleLabel]="'RELATED_TANK'"
                                                  [pluralLabel]="'RELATED_TANKS'"

                                ></related-entities>


                            </ion-list>
                        </div>


                        <!-- end of related entities section-->


                    </div><!-- closing group-->
                    <div *ngIf="!lastElem" class="bottomLine"></div>
                    <div *ngIf="lastElem" class="bottomPadding"></div>

                </ion-list>

            </div><!-- rounded container -->

        </ion-list>
    </form>
</ion-content>
