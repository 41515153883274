import {Component, Input, OnInit} from '@angular/core';
import {Platform} from "@ionic/angular";

@Component({
  selector: 'back-button-custom',
  templateUrl: './back-button-custom.component.html',
  styleUrls: ['./back-button-custom.component.scss'],
})
export class BackButtonCustomComponent implements OnInit {

  @Input() clickMethod:()=>void;
  @Input() disabled:boolean;

  applyClickMethod(){
    if(!this.disabled){
      this.clickMethod();
    }
  }
  constructor() {

  }

  ngOnInit() {}

}
