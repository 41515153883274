import {Injectable} from '@angular/core';

import {HttpStatus} from '../models/http-status';
import {NetworkWay} from '../models/http-status';

import {HttpClient} from '@angular/common/http';
import {HTTP} from '@ionic-native/http/ngx';

import {Store} from '@ngrx/store';
import {Observable, of, from} from 'rxjs';
import {map, catchError, first, switchMap} from 'rxjs/operators';
import {getClassByName} from '../models/tables';
import {Tables, Table} from '../models/tables';
import {AppState} from './app-state.service';
import {ChangeValuesRequest, ChangeAttributesRequest} from '../actions/tables.actions';
import {Config, PlatformMode} from '../models/config';
import {SocketService} from '../services/socket.service';
import {NativeResp} from '../models/http';
import {fixedEncodeURI} from '../models/http';

import {handleError} from './support.service';
import {BluetoothService} from "./bluetooth.service";
import * as _ from 'lodash';
import {AbstractConfigPage} from "../pages/abstract-config-page";

@Injectable({
    providedIn: 'root'
})

export class TablesService {

    private config: Observable<Config>;
    private tables: Observable<Tables>;
    private httpStatus: Observable<HttpStatus>;
    private cache: Tables = new Tables();
    private modality: NetworkWay;
    private baseUrl: string;
    private loadExcludedKeys: boolean;
    private _platform: PlatformMode;
    private configInstance: Config;

    constructor(private http: HttpClient,
                protected store: Store<AppState>,
                protected socketService: SocketService,
                private nativeHttp: HTTP,
                private bluetoothService: BluetoothService
    ) {

        this.httpStatus = store.select('httpStatus') as Observable<HttpStatus>;
        this.httpStatus.subscribe(status => {
            //console.log(`tableService aggiornamento di modality: ${status.modality}`);
            this.modality = status.modality;
        })
        this.config = store.select('config') as Observable<Config>;
        this.config.subscribe(data => {
            this.configInstance = data;
            this.baseUrl = data.baseUrl;
            this._platform = data.platform;

        })
        //this.store.dispatch(new LoadConfig);
        // Create a cache for the loaded tables
        this.tables = store.select('tables') as Observable<Tables>;
        this.tables.subscribe(data => {
            for (let key in data) {
                if (key != 'error' && data[key].length) {
                    this.cache[key] = data[key] || this.cache[key];
                }
            }
        })
    }

    /**
     * Get all the instances of a given table
     * Return an array of instances
     */
    get(table: string, force: boolean = false): Observable<Table[]> {

        // AbstractConfigPage now has a state that persists through the app, and has the current state of the Tables
        if (AbstractConfigPage.isCentralizedTableInitialized(table)) {

            return of(AbstractConfigPage.getCentralizedTableData(table));
        }

        //console.log(`%cTABLES-SERVICE.GET(${table}) force ${force}`,'font-size:1.1rem;color:SeaGreen;')
        // Return the table from the cache if it is already loaded
        let data: Table[] = this.cache[table];
        // console.log(this.cache[table]);

        if (data != undefined) {

            if (data[table] != undefined) {

                if (data[table].id != null && data[table].length && !force) {

                    // console.log(`%cPRENDO ${table} DA CACHE`,'font-size:1.2rem;color:Maroon;');
                    //console.log(data);

                    return of(data);
                }
            }
        }

        // Load the table using the API
        let klass = getClassByName(table);

        // if(table=='MCU'){
        //     console.error(`NON sto prendendo MCU da cache, faccio una chiamata a api/table/${table}`);
        //}

        //se remote
        // console.log(`%cprima del test: modality ${this.modality} platform vale ${this._platform}`,'font-size:1.2rem;color:red;');

        return this.httpStatus.pipe(first(), switchMap((http) => {

            return this.config.pipe(first(), switchMap((config) => {

                if (http.modality === NetworkWay.BLUETOOTH) {

                    return from(this.bluetoothService.getDataViaBluetooth(table)).pipe(
                        switchMap((tableArray) => {

                            for (let i = 0; i < tableArray.length; i++) {

                                data[i] = Object.assign(new klass(), tableArray[i]);
                            }

                            if (table == 'MCU' && data[0]['TEC_SPMode'] != null) {

                                if (data[0]['TEC_SPMode'] != undefined) {

                                    data[0]['TEC_SPMode'] = getSpModeMCU(data);
                                }
                            }

                            // Sometimes the Bluetooth returns an empty Array of tables (if there are manh requests in queue) -> hence the (tableArray.length)
                            if (data.length) {

                                AbstractConfigPage.setCentralizedTableAsInitialized(table);
                            }

                            return of(data);
                        })
                    );

                } else if (http.modality == NetworkWay.Remote || config.platform == PlatformMode.Browser) {
                    //console.warn(`tableService.get modality: ${http.modality} url ${config.baseUrl + 'api/table/' + table}`);
                    return this.http.get(fixedEncodeURI(config.baseUrl + 'api/table/' + table + (this.loadExcludedKeys ? '?loadExcludedKeys=1' : ''))).pipe(
                        // tap(data=>{console.log(`prendo table da api/table/${table}, vale `); console.log(data)}),
                        map(res => {
                            // console.log(`tableService.get(${table},${force}) - url: ${config.baseUrl + 'api/table/' + table + (this.loadExcludedKeys ? '?loadExcludedKeys=1' : '')}`);
                            //console.warn(`tableService.get Response => ${res as HttpResponse<Table[]>}`);

                            for (let i in res['content']) {

                                // console.log(`field ${i}`);
                                data[i] = (Object.assign(new klass(), res['content'][i]));
                            }

                            if (table == 'MCU') {
                                let _url = config.baseUrl + 'api/table/' + table + (this.loadExcludedKeys ? '?loadExcludedKeys=1' : '');
                                //console.log(`tramite chiamata ad api ${_url}`);
                                //console.log('restituisco ');
                                if (data[0]['TEC_SPMode'] != undefined) {

                                    if (data[0]['TEC_SPMode'] != undefined) {

                                        data[0]['TEC_SPMode'] = getSpModeMCU(data);
                                    }
                                }
                            }

                            //console.log(`%cRecuperata table ${table} tramite API portale`,'font-size:0.5rem;color:black');
                            //console.log(data);

                            AbstractConfigPage.setCentralizedTableAsInitialized(table);
                            return data as Table[];
                        }),

                        catchError(handleError)
                    );
                }

                //se local uso nativeHttp
                else if ((http.modality == NetworkWay.LAN || http.modality == NetworkWay.DISCOVERY) && config.platform == PlatformMode.App) {

                    let url = config.baseUrl + 'api/table/' + table + (this.loadExcludedKeys ? '?loadExcludedKeys=1' : '');
                    // console.warn(`tableService.get modality: ${http.modality} url ${url}`);

                    if (config.baseUrl !== null && config.baseUrl !== undefined) {
                        let host = '/';

                        if (!config.baseUrl) {
                            host = '/';

                        } else if (config.baseUrl.substring(0, 4) == 'http') {
                            let index = config.baseUrl.indexOf('/', 8); // index of first '/' after 'http://' or 'https://'
                            host = config.baseUrl.substring(0, index);

                        } else {
                            host = '/';

                        }
                        let header = 'Authorization';
                        let value = 'Bearer ' + (config.currentUser) ? config.currentUser.token : null;

                        //console.log(host);

                    } else {/*console.log('baseUrl undefined');*/
                    }

                    //console.log(`%cAddress.of.GET(${url})`,'font-size:0.9rem;color:SeaGreen;')
                    return from(this.nativeHttp.get(fixedEncodeURI(url), {}, {'Authorization': (config.currentUser) ? 'Bearer ' + config.currentUser.token : null})).pipe(
                        map(
                            httpResp => {
                                //console.log(httpResp);
                                let res = JSON.parse((httpResp as NativeResp).data);
                                for (let i in res['content']) {
                                    // console.log(`field ${i}`);
                                    data[i] = (Object.assign(new klass(), res['content'][i]));
                                }
                                if (table == 'MCU') {
                                    let _url = config.baseUrl + 'api/table/' + table + (this.loadExcludedKeys ? '?loadExcludedKeys=1' : '');
                                    //console.log(`tramite chiamata ad api ${_url}`);
                                    //console.log('restituisco ');
                                    if (data[0]['TEC_SPMode'] != undefined) {

                                        data[0]['TEC_SPMode'] = getSpModeMCU(data);
                                    }
                                }
                                // console.log(`%cRecuperata table ${table} tramite chiamata diretta a ${config.baseUrl}`,'font-size:0.5rem;color:black');
                                // console.log(data);

                                AbstractConfigPage.setCentralizedTableAsInitialized(table);
                                return data as Table[];
                            }),

                        catchError(handleError));
                }
            }));
        }));

        function getSpModeMCU(data) {

            let TEC_SPMode = false;

            if (data[0]['TEC_SPMode'] == "true") {

                TEC_SPMode = true;
            }

            if (data[0]['TEC_SPMode'] == "1") {

                TEC_SPMode = true
            }

            if (data[0]['TEC_SPMode'] === true) {

                TEC_SPMode = true
            }

            return TEC_SPMode;
        }
    }

    /**
     * Set a field
     */
    set(table: string, id: number, values: any) {

        // -------------------------------------------------------------------------------------------------------------
        // --- Method to write a value on Redis via socketIO or BLE ----------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        const writeValue = (values) => {

            if (this.modality === NetworkWay.BLUETOOTH) {

                this.bluetoothService.writeDataViaBluetooth('tableSetItemValues', {
                    table: table,
                    id: id,
                    values: values
                });
            } else {

                this.socketService.emit('tableSetItemValues', {table: table, id: id, values: values});
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- Change a Key - Value of Redis  --------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        writeValue(values);

        // -------------------------------------------------------------------------------------------------------------
        // --- Add the same Key with a TTL and a "_LCK" at the end (if required - for example in ENR model 5) ----------
        // -------------------------------------------------------------------------------------------------------------

        // If a specific key of a table need a "_LCK" key too, you can add it inside the "getValuesWith_LCK" function
        const valuesWith_LCK = this.getValuesWith_LCK(table, values);

        // If a key needs to have a key with "_LCK" too, I call "writeValue" again to update Redis
        if (valuesWith_LCK) {

            this.setEnqueue(table,id,values);
            writeValue(valuesWith_LCK);
        }

        return of([null]);
    }

    /**
     * Set a field
     * note of 27/6/2023 mbortolus
     * tableSetItemAttributes not found in web.js of mcu-backend, I found just setAttributes
     *
     */
    setAtt(table: string, values: any) {
        this.socketService.emit('tableSetItemAttributes', {table: table, values: values});
        return of([null]);
    }


    setEnqueue(table: string,id:number, values: any) {
      console.log('setEnqueue');
      console.log(`table ${table} id:${id} values:`);
      console.log(values);
    this.socketService.emit('setEnqueue', {table: table,id:id, values: values});
    return of([null]);
  }
    /**
     * Set values of multiple items
     */
    setMultipleItems(requests: ChangeValuesRequest[]) {

        //console.error('setMultipleItems requests vale')
        //console.log(requests);

        if (this.modality === NetworkWay.BLUETOOTH) {

            this.bluetoothService.writeDataViaBluetooth('setValues', requests);
        } else {

            this.socketService.emit('setValues', requests);
        }

        return of([null]);
    }

    /**
     * Set values of multiple items
     */
    setMultipleAttributes(requests: ChangeAttributesRequest[]) {

        if (this.modality === NetworkWay.BLUETOOTH) {

            this.bluetoothService.writeDataViaBluetooth('setAttributes', requests);
        } else {

            this.socketService.emit('setAttributes', requests);
        }

        return of([null]);
    }

    /**
     * Clean the cache
     */
    cleanup() {
        //console.error('CHIAMATA LA TABLESERVICE.CLEANUP!, quindi la cache di tableservice ora vale (vuota magari?)');
        this.cache = new Tables();
        AbstractConfigPage.resetCentralizedTableData();
        AbstractConfigPage.resetCentralizedAttributesData();
        //console.log(this.cache);
        return of([null]);
    }

    // If you add a key here for a table, the app will also send to Redis another value as "TEC_ENR_000_TargetTempH_LCK" (for example). In the backend, if it finds a key with "_LCK", it will update / create that value with a TTL of 150
    getValuesWith_LCK(table, values) {

        const redisKey = Object.keys(values).shift();

        // If you want to create a "_LCK" key of the original Redis property, write it here in this format: "TABLE_KEY"
        switch (table + '_' + redisKey) {

            // Phoenix (ENR model 5) Main Page
            case 'ENR_TEC_TargetTempH':
            case 'ENR_TEC_TargetTempC':
            case 'ENR_TEC_TargetTempDHW':

                // Phoenix Controls Parameters
            case 'ENR_TEC_ManualControl':
            case 'ENR_TEC_AutoStart':
            case 'ENR_TEC_FanCtrlComInvBoard':
            case 'ENR_TEC_CoolingMode':
            case 'ENR_TEC_UnitCommMode':
            case 'ENR_TEC_UnitAddress':
            case 'ENR_TEC_EVIEnabled':
            case 'ENR_TEC_HotwaterEnable':
            case 'ENR_TEC_UM':
            case 'ENR_TEC_SilenceMode':
            case 'ENR_TEC_ElectricHeaterEnergyStage':
            case 'ENR_TEC_ThreeWayValvePolarity':
            case 'ENR_TEC_RunningStateCode':
            case 'ENR_TEC_AirConditionerTempChoice':
            case 'ENR_TEC_HydraulicModule':
            case 'ENR_TEC_PumpType':
            case 'ENR_TEC_ForcedTime':
            case 'ENR_TEC_RemoteFunction':
            case 'ENR_TEC_WeatherCompensation':
            case 'ENR_TEC_ShutdownAmbientTemp':
            case 'ENR_TEC_AntifreezeTempWrite':
            case 'ENR_TEC_AntifreezeTempDiff':
            case 'ENR_TEC_ExhaustTempProtectSetup':
            case 'ENR_TEC_LowVoltagePressureSensor':
            case 'ENR_TEC_OutletWaterLowProtection':
            case 'ENR_TEC_SensorType':
            case 'ENR_TEC_AntifreezeMinTemp':
            case 'ENR_TEC_TempDiffALimitFreq':
            case 'ENR_TEC_TempDiffBLimitFreq':
            case 'ENR_TEC_MinAmbientTempOfCoolingMode':
            case 'ENR_TEC_StartDefrostSetpoint':
            case 'ENR_TEC_ExitDefrostSetpoint':
            case 'ENR_TEC_DefrostCycle':
            case 'ENR_TEC_MaxDefrostTime':
            case 'ENR_TEC_ElectricHeaterCtrl':
            case 'ENR_TEC_SlidingDefrostAmbTemp':
            case 'ENR_TEC_SlidingDefrostATDiff':
            case 'ENR_TEC_SlidingDefrostCTDiff':
            case 'ENR_TEC_SlidingDefrostMinCT':
            case 'ENR_TEC_DefrosFreq':
            case 'ENR_TEC_DefrostMinInletWaterTemp':
            case 'ENR_TEC_EEV1AdjustMode':
            case 'ENR_TEC_EEV1TargetSuperheatDegree':
            case 'ENR_TEC_EEV1InitialStep':
            case 'ENR_TEC_EEVMinStep':
            case 'ENR_TEC_CoolStep':
            case 'ENR_TEC_EVI1AdjustMode':
            case 'ENR_TEC_EVI1nitialStep':
            case 'ENR_TEC_EVITargetSuperheatDegree':
            case 'ENR_TEC_EVIMinStep':
            case 'ENR_TEC_DefrostStep':
            case 'ENR_TEC_CoolTargetSuperheat':
            case 'ENR_TEC_FanMotorType':
            case 'ENR_TEC_CoilTempMaxC':
            case 'ENR_TEC_CoilTempMinC':
            case 'ENR_TEC_CoilTempMaxH':
            case 'ENR_TEC_CoilTempMinH':
            case 'ENR_TEC_FanQuantity':
            case 'ENR_TEC_FanMotorSpeedC':
            case 'ENR_TEC_FanMotorSpeedMinH':
            case 'ENR_TEC_TimerMute':
            case 'ENR_TEC_ManualCtrlFanSpeed':
            case 'ENR_TEC_FanRateSpeed':
            case 'ENR_TEC_FanSpeedMaxC':
            case 'ENR_TEC_FanSpeedMaxH':
            case 'ENR_TEC_Power':
            case 'ENR_TEC_RunningMode':
            case 'ENR_TEC_RunningIntTime':
            case 'ENR_TEC_RunningDuration':
            case 'ENR_TEC_DHWPumpWorkMode':
            case 'ENR_TEC_StartWaterPumpOffMode':
            case 'ENR_TEC_WaterPumpTotalStopRunTime':
            case 'ENR_TEC_HotWaterSetpoint':
            // case 'ENR_TEC_TargetTempH':
            // case 'ENR_TEC_TargetTempC':
            case 'ENR_TEC_PowerOnReturnDiffH':
            case 'ENR_TEC_ConstantTempPowerOffTempDiffH':
            case 'ENR_TEC_SetpointMinC':
            case 'ENR_TEC_SetpointMaxC':
            case 'ENR_TEC_SetpointMinH':
            case 'ENR_TEC_SetpointMaxH':
            case 'ENR_TEC_ReturnDiffExitingHighTempMode':
            case 'ENR_TEC_CompONLowAT':
            case 'ENR_TEC_CompOFFLowAT':
            case 'ENR_TEC_LowATMaxCompAim':
            case 'ENR_TEC_CompONHighAT':
            case 'ENR_TEC_CompOFFHighAT':
            case 'ENR_TEC_HighATMaxCompAim':
            case 'ENR_TEC_ElectricHeaterFunctions':
            case 'ENR_TEC_PowerOnReturnDiffC':
            case 'ENR_TEC_ConstTempPowerOffTempDiffC':
            case 'ENR_TEC_HotWaterSetpointMin':
            case 'ENR_TEC_HotWaterSetpointMax':
            case 'ENR_TEC_AutoRestartAmbTempH':
            case 'ENR_TEC_AmbTempMainPump':
            case 'ENR_TEC_AmbTempHCircPump':
            case 'ENR_TEC_PowerOnReturnDiffTNK':
            case 'ENR_TEC_StandbyTempDiffTNK':
            case 'ENR_TEC_WaterHTempMax':
            case 'ENR_TEC_WaterHTempMaxUnderLow':
            case 'ENR_TEC_WaterHTempMaxUnderHigh':
            case 'ENR_TEC_ElectricHeaterStartAmbTemp':
            case 'ENR_TEC_OutletWaterTempMaxDiff':
            case 'ENR_TEC_AntiLegTargetTemp':
            case 'ENR_TEC_AntiLegDuration':
            case 'ENR_TEC_AntiLegStartHour':
            case 'ENR_TEC_AntiLegCycle':
            case 'ENR_TEC_AntiLegEnab':
            case 'ENR_TEC_CompManualFreq':
            case 'ENR_TEC_CompMinFreq':
            case 'ENR_TEC_CompMaxFreq':
            case 'ENR_TEC_ComModelSel':
            case 'ENR_TEC_CompMinFreqLowAmbTempC':
            case 'ENR_TEC_CompFreqCtrlMode':
            case 'ENR_TEC_FreqResonanceP1':
            case 'ENR_TEC_FreqResonanceP2':
            case 'ENR_TEC_FreqResonanceP3':
            case 'ENR_TEC_DefrostingSuctionPressure2':
            case 'ENR_TEC_AmbientTempOfStartSlidingDefrosting':
            case 'ENR_TEC_SuctionTempOfStartSlidingDefrosting':
            case 'ENR_TEC_AmbientTempOfStopSlidingDefrosting':
            case 'ENR_TEC_MinInletWaterTempOfDefrosting':
            case 'ENR_TEC_SuctionPressureOfForcedDefrosting':
            case 'ENR_TEC_HeatingOperationTimeBeforeForcedDefrosting':
            case 'ENR_TEC_FanMotorPowerRatioToExtendDefrostingCycle':
            case 'ENR_TEC_MaxFanMotorPowerToEnterForcedDefrosting':
            case 'ENR_TEC_DistributorTubeTempOfExitDefrosting':

                return {[redisKey + '_LCK']: 1}; // -> I don't need to set a value for the "_LCK" key: 1 is enough to check if it exists
        }

        return false;
    }
}
