import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from './app-state.service';
import {
    TablesActions,
    ChangeValuesRequest,
    ChangeTableValues,
    ChangeTableMultipleItems
} from '../actions/tables.actions';
import {Config, DEFAULT_LANGUAGE, EnvironmentType, Season} from '../models/config';
import {
  MCU,
  HYS,
  GRP,
  MXV,
  ODS,
  ATU,
  ENR,
  TNK,
  TMR,
  DHW,
  FLS,
  MCZ,
  RMS,
  ZON,
  FNC,
  EXP,
  GEN,
  SCH, AUTO_MODE_STYLE, TRG
} from '../models/tables';
import {FNC_TYPE} from '../models/tables';
import {PlatformMode} from '../models/config';
import * as moment from "moment";
import {SchedulationType} from '../models/config';
import {fixedEncodeURI} from '../models/http';
import {NativeResp} from '../models/http';
import {of, interval, Subscription, from, Observable, throwError, timer} from 'rxjs';
import {concatMap, timeout, catchError, delay, switchMap, debounceTime, map, startWith, first, takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {HTTP} from '@ionic-native/http/ngx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpStatus, NetworkWay} from '../models/http-status';
import {DateTime} from "luxon";
import {BitArray} from '../commons/bitarray';
import {Geolocation} from '@capacitor/geolocation';
import {BluetoothService} from "./bluetooth.service";
import {Platform, ToastController} from '@ionic/angular';
import {AbstractConfigPage} from "../pages/abstract-config-page";
import {ZeroconfDevice} from "../models/zeroconfDevice";
import {RMS_Extended} from '../models/tables-extended';
import {TimeoutConfig} from '../actions/config.actions';
import {HttpCancelService} from './http-cancel.service';
import {SocketService} from './socket.service';
import {CheckRemoteConnectionState} from '../actions/socket.actions';
import {PopupService} from './popup.service';
import {Socket} from '../models/socket';
import {TranslateService} from '@ngx-translate/core';
import {HttpStatusService} from './http-status.service';
import {Router} from '@angular/router';
import {User} from '../models/user';

export function handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    // console.error(errMsg); // log to console instead
    return throwError(error);
}

export interface action_response {
    code: number;
    content: any;
}

@Injectable({
    providedIn: 'root'
})
export class SupportService {


    private conSub : Subscription;
    private afterConnectionTimeoutSub:Subscription;
    private configSubscription: Subscription;
    private socketSubscription: Subscription;
    private configObservable: Observable<Config>;
    private socketObservable: Observable<Socket>;
    private httpStatusObservable: Observable<HttpStatus>;
    private config: Config;
    private latitude: number;
    private longitude: number;
    private static onResumeSubscription: Subscription;

    constructor(protected store: Store<AppState>,
                private nativeHttp: HTTP,
                private http: HttpClient,
                private platform: Platform,
                private bluetoothService: BluetoothService,
    ) {

        // -------------------------------------------------------------------------------------------------------------
        // --- CONSTRUCTOR ---------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        this.configObservable = store.select('config') as Observable<Config>;
        this.socketObservable = store.select('socket') as Observable<Socket>;
        this.httpStatusObservable = this.store.select('httpStatus') as Observable<HttpStatus>;

        this.configSubscription = this.configObservable.subscribe(data => {
            this.config = data;
        });


    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CLASS METHODS -----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    isBrowser() {
        return this.config.platform == PlatformMode.Browser;
    }

    isApp() {
        return this.config.platform == PlatformMode.App;
    }


    isBrowserOrRemote(modality) {
        return modality == NetworkWay.Remote || this.config.platform == PlatformMode.Browser;
    }

    isLanAndApp(modality) {
        return (modality == NetworkWay.LAN || modality == NetworkWay.DISCOVERY) && this.config.platform == PlatformMode.App;
    }

    superHttpPost(modality, url, body, header) {

        let encUrl = fixedEncodeURI(url);

        if (modality == undefined) {

            throwError('modality is undefined');
        }

        const hKeys = Object.keys(header);
        let obj = {};

        if (hKeys.length > 0) {

            obj = {[hKeys[0]]: header[hKeys[0]]};
        }

        if (this.isBrowserOrRemote(modality)) {

            //console.log('isBrowserOrRemote');
            return this.http.post(encUrl, body, obj);
        }

        if (this.isLanAndApp(modality)) {

            let he = {"Content-Type": "application/json"};
            this.nativeHttp.setDataSerializer('json');

            return from(this.nativeHttp.post(encUrl, body, he)).pipe(
                map(httpResp => {

                    let data = JSON.parse((httpResp as NativeResp).data);
                    return data;
                }),
                catchError(handleError)
            );
        }
    }

    async getLocation() {
        try {
            // if(this.config.isIos){
            if (this.isApp()) {
                const position = await Geolocation.getCurrentPosition();
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
            } else {
                this.latitude = -1;
                this.longitude = -1;
            }
        } catch (error) {
            // console.log(error);
            this.latitude = -1;
            this.longitude = -1;
        }


        if (this.isApp())
            return [this.latitude, this.longitude];
        else
            return [-1, -1];
    }

    getUserDateTime() {
        let now = DateTime.local();
        // console.log(`current user date time: ${now}`);
        return now;
    }


    getPublicIp() {
        let url = "https://api.ipify.org/?format=json";
        let encUrl = fixedEncodeURI(url);

        // console.log(`getPublicIp url ${url}, enc ${encUrl}`);

        if (this.isBrowser())
            return of(undefined);

        if (this.isApp())
            return from(this.nativeHttp.get(encUrl, {}, {})).pipe(
                map(httpResp => {
                    // console.log('httpResp vale:');
                    // console.log(httpResp);
                    let data = JSON.parse((httpResp as NativeResp).data);
                    return data;
                }),
                catchError(handleError));

    }

    //aggiungo modality
    superHttpGet(modality, url) {
        let encUrl = fixedEncodeURI(url);

        if (modality === NetworkWay.BLUETOOTH) {

            let bluetoothAPI;

            if (encUrl.includes('api')) {

                bluetoothAPI = encUrl.slice(encUrl.lastIndexOf("api"));
                console.log('BLE-API', bluetoothAPI);
            }

            if (encUrl.includes('sqlite')) {

                bluetoothAPI = encUrl.slice(encUrl.lastIndexOf("sqlite"));
                console.log('BLE-SQLITE', bluetoothAPI);
            }

            // console.log(bluetoothUUID); // sqlite/alldatabases
            return from(this.bluetoothService.getDataViaBluetooth(bluetoothAPI, true));
        }

        if (this.isBrowserOrRemote(modality)) {

            return this.http.get(encUrl);
        }

        if (this.isLanAndApp(modality)) {

            return from(this.nativeHttp.get(encUrl, {}, {'Authorization': (this.config.currentUser) ? 'Bearer ' + this.config.currentUser.token : null})).pipe(
                map(httpResp => {

                    let data = JSON.parse((httpResp as NativeResp).data);
                    return data;
                }),
                catchError(handleError));
        }
    }


    extractDateParams(stringDate) {
        let y = stringDate.getFullYear();
        let month = stringDate.getMonth() + 1;
        let d = stringDate.getDate();
        let h = stringDate.getHours();
        let m = stringDate.getMinutes();
        let s = stringDate.getSeconds();
        return {
            'year': parseInt(y),
            'month': parseInt(month),
            'day': parseInt(d),
            'hours': parseInt(h),
            'minutes': parseInt(m),
            'seconds': parseInt(s)
        };
    }

    resetCurrentDateTime() {

        AbstractConfigPage.resetCurrentDateTime();
    }

    // Get Date from HTTP and return a string
    async updateCurrentDateTime() {

        const syncData = async (data) => {

            if (data) {

                const {year, month, day, hours, minutes, seconds} = this.config.formatDate(data[1]);
                const currentDateTime = moment(`${day}/${month}/${year} ${hours}:${minutes}:${seconds}`, 'DD/M/YY HH:mm:ss');

                AbstractConfigPage.setCurrentDateTime(currentDateTime, this);

            } else {

                await timer(1000).toPromise();
                await this.updateCurrentDateTime();
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- HTTP call to the mini-pc for DATE (only the first time and when onResume) -------------------------------
        // -------------------------------------------------------------------------------------------------------------

        //const httpStatusObservable = this.store.select('httpStatus') as Observable<HttpStatus>;
        const httpStatus = await this.httpStatusObservable.pipe(first()).toPromise();
        const modality = httpStatus.modality;

        // -------------------------------------------------------------------------------------------------------------

        let date;
        const url = this.config.baseUrl + 'api/action/date';
        const encUrl = fixedEncodeURI(url);

        // -------------------------------------------------------------------------------------------------------------
        // --- GET DATE ------------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        try {

            if (modality === NetworkWay.BLUETOOTH) {

                const bleDate = await this.bluetoothService.getDataViaBluetooth('api/action/date', true);
                date = bleDate.data;

            } else if (this.isLanAndApp(modality)) {

                const httpResponse = await this.nativeHttp.get(encUrl, {}, {});
                date = JSON.parse(httpResponse.data);
            }

            // -------------------------------------------------------------------------------------------------------------

            else if (this.isBrowserOrRemote(modality) || !modality) {

                date = await this.http.get(encUrl).toPromise();
            }

            await syncData(date);

        } catch (e) {

            console.log(e);
            await timer(1000).toPromise();
            await this.updateCurrentDateTime();
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    checkValidMcu(address): Observable<ZeroconfDevice> {

        let url = 'http://' + address + '/api/action/date';
        let encUrl = fixedEncodeURI(url);

        return from(this.nativeHttp.get(encUrl, {}, {})).pipe(timeout(5500),
            catchError(error => throwError(new Error(`no device found with address ${address}`))),

            switchMap(dateHttpresp => {

                // console.log(`device con ip ${address} ha risposto`)
                // console.log(dateHttpresp);

                let netStaturl = 'http://' + address + '/api/action/network_status';
                // console.log(`ora chiamo ${netStaturl}`);
                let encUrlNetStat = fixedEncodeURI(netStaturl);

                //headers: {[key: string]: string}
                return from(this.nativeHttp.get(encUrlNetStat, {}, {})).pipe(
                    map(httpResp => {

                        let data = JSON.parse((httpResp as NativeResp).data);
                        // console.log(`map di ${netStaturl}`);
                        // console.log(data);
                        if (data.code == 1) {

                            let version = parseInt(data.content['version_id']);


                            let gateway = data.content['gateway'];
                            let ifconfig = data.content['ip'];
                            let hostname = data.content['hostname'].replace(/(\r\n|\n|\r)/gm, "");
                            // console.log(`deb version vale ${version},gateway:${gateway},ip:${ifconfig},hostname:${hostname}`);

                            /*  let ret:deviceData = new deviceData();
                              ret.address=address;
                              ret.hostname=hostname; */
                            return new ZeroconfDevice(address, hostname, null);

                        } else throwError(new Error(`data.code != 1`));
                    }),
                    catchError(error => {

                        // console.log(error);
                        return throwError(new Error(`device found with ip ${address}, but error while retrieving hostname`));


                    }));
            })
        );


    }

    checkHydronicSystem(gAtu: ATU[]): boolean {

        if (gAtu == null || gAtu.length == 0) {
            return false
        }
        for (var atu of gAtu) {
            if (atu != undefined) {
                if (atu.CFG_IdxHYS != -1) {


                    return true
                }

            }

        }

        return false
    }


    checkRadiantSystem(gRms: RMS[], gZon: ZON[], gFnc: FNC[], gHys: HYS[], gGrp: GRP[]): boolean {

        /* console.log(gRms);
         console.log(gZon);
         console.log(gFnc);
         console.log(gHys);
         console.log(gGrp);*/

        if (gRms == null || gRms.length == 0) {
            return false
        }

        for (var rms of gRms) {

            if (!this.noRadiant(rms, gZon, gFnc, gHys, gGrp, null)) {


                return true
            }
        }


        return false
    }


    zones(rms: RMS, gZon: ZON[]): ZON[] {
        if (gZon != undefined)
            return _.filter(gZon, x => x.CFG_IdxRMS == rms.id);
        else return [];
    }

    fancoils(rms: RMS, gFnc: FNC[]): FNC[] {
        return _.filter(gFnc, x => x.CFG_IdxRMS.indexOf(rms.id) != -1)
    }

    noRadiant(rms: RMS, gZon: ZON[], gFnc: FNC[], gHys: HYS[], gGrp: GRP[], mcz: MCZ): boolean {

        const seasonExe: Season = (mcz != null && gGrp[mcz.CFG_IdxGRP]) ? gGrp[mcz.CFG_IdxGRP].RTU_SeasonExe : (rms != null && gGrp[rms.CFG_IdxGRP] ? gGrp[rms.CFG_IdxGRP].RTU_SeasonExe : -1);

        const zones = (rms != null) ? this.zones(rms, gZon) : gZon;
        const fncs = (rms != null) ? this.fancoils(rms, gFnc) : gFnc;

        if (zones.length == 0 && fncs.length == 0) {

            return true;
        }

        //controllo le zone
        if (gHys) {

            for (let i = 0; i < zones.length; i++) {

                const zon = zones[i]
                const hys = gHys[zon.CFG_IdxHYS]

                const hysSeason = hys && gGrp ? gGrp[hys.CFG_IdxGRP]?.RTU_SeasonExe : null;

                // If there is a zone, I always show it in Dual setpoint (either it will have heating, cooling, or both)
                if ((rms && this.isAutoModeStyleDualSetpointFromRms(gGrp, rms)) || (mcz && this.isAutoModeStyleDualSetpointFromMcz(gGrp, mcz))) {

                    return false;
                }

                if (hysSeason == 0 && (zon.PAR_Type == 1 || zon.PAR_Type == 3)) {

                    // HYS e ZON associate
                    return false;
                }

                if (hysSeason == 1 && (zon.PAR_Type == 2 || zon.PAR_Type == 3)) {

                    // HYS e ZON associate
                    return false;
                }
            }
        }

        // controllo i fancoil
        for (let i = 0; i < fncs.length; i++) {

            let fnc = fncs[i]

            let modeBitArray = new BitArray(25).fromNumber(fnc.PAR_ModeFC);

            // If there is a fancoil, I always show it in Dual setpoint (either it will have heating, cooling, or both)
            if ((rms && this.isAutoModeStyleDualSetpointFromRms(gGrp, rms)) || (mcz && this.isAutoModeStyleDualSetpointFromMcz(gGrp, mcz))) {

                return false;
            }

            if (seasonExe == 0 && modeBitArray.get(0) || seasonExe == 1 && modeBitArray.get(1)) {

                return false;
            }

            let hysH = fnc.CFG_IdxHYS_H != -1 ? gHys[fnc.CFG_IdxHYS_H] : -1;
            let hysC = fnc.CFG_IdxHYS_C != -1 ? gHys[fnc.CFG_IdxHYS_C] : -1;
            let hysSeasonH = hysH != -1 ? gGrp[((hysH) as HYS).CFG_IdxGRP].RTU_SeasonExe : -1;
            let hysSeasonC = hysC != -1 ? gGrp[((hysC) as HYS).CFG_IdxGRP].RTU_SeasonExe : -1;

            if (hysSeasonH == 0 && modeBitArray.get(0) || hysSeasonC == 1 && modeBitArray.get(1)) {

                return false;
            }
        }

        return true;
    }

    adjustSetpointsForDualSetpointMode(MCU: MCU, RMS: RMS[], grp: GRP) {
        let rmsRequests = [];
        let rmsGrp = [];
        rmsGrp = _.filter(RMS, x => x.CFG_IdxGRP == grp.id);
        for (var rms of rmsGrp) {
            if (rms.PAR_SetTempH >= rms.PAR_SetTempC) {
                if (MCU.PAR_UM == 0)
                    rms.PAR_SetTempC = rms.PAR_SetTempH + 2;
                if (MCU.PAR_UM == 1)
                    rms.PAR_SetTempC = rms.PAR_SetTempH + 1;
                rmsRequests.push({table: 'RMS', id: rms.id, values: {PAR_SetTempC: rms.PAR_SetTempC}});
            }
        }
        return rmsRequests;
    }

    unifySetpoints(MCZ: MCZ[], RMS: RMS[], SCH: SCH[]) {

        const requests: ChangeValuesRequest[] = [];

        // devo unificare massimi e minimi per ogni MCZ
        const validMCZ = _.sortBy(MCZ.filter(mcz => mcz.id == 0 || mcz.CFG_Valid), 'CFG_ViewOrder');

        for (let i = 0; i < validMCZ.length; i++) {

            validMCZ[i].TEC_SetTempMinC = validMCZ[i].TEC_SetTempMinH;
            validMCZ[i].TEC_SetTempMaxC = validMCZ[i].TEC_SetTempMaxH;

            requests.push({table: 'MCZ', id: validMCZ[i].id,
                values: {TEC_SetTempMinC: validMCZ[i].TEC_SetTempMinC, TEC_SetTempMaxC: validMCZ[i].TEC_SetTempMaxC}});
        }

        // copy H setpoints and schedule On/Off to C
        for (let i = 0; i < RMS.length; i++) {

            const rms = RMS[i];

            rms.PAR_SetTempC = rms.PAR_SetTempH;
            rms.PAR_IdxSCH_C = rms.PAR_IdxSCH_H;
            rms.PAR_SchedOnC = rms.PAR_SchedOnH;

            requests.push({table: 'RMS', id: rms.id,
                values: {PAR_SetTempC: rms.PAR_SetTempC, PAR_IdxSCH_C: rms.PAR_IdxSCH_C, PAR_SchedOnC: rms.PAR_SchedOnC}
            });
        }

        // apply H/C Season on all Variable Schedule
        for (let i = 0; i < SCH.length; i++) {

            const sch = SCH[i];

            if (sch.CFG_Type === SchedulationType.Variable) {

                sch.PAR_Season = 3;
                requests.push({table: 'SCH', id: sch.id, values: {PAR_Season: sch.PAR_Season}});
            }
        }

        return [...requests];
    }


    public in_array(needle, arr) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === needle) {
                return true;
            }
        }
        return false;
    }

    /**
     * The list of fancoils of this macrozone
     */
    public getFNCbyMCZ(FNC: FNC[], RMS: RMS[], mcz: MCZ): FNC[] {
        if (mcz.id == 0) {
            return FNC;
        } else {
            let mczFancoils = [];
            let mczFancoilsIdx = [];
            let rmss = RMS.filter(zone => zone.CFG_IdxMCZ == mcz.id);

            for (let j = 0; j < rmss.length; j++) {
                for (let k = 0; k < FNC.length; k++) {
                    if (this.in_array(rmss[j].id, FNC[k].CFG_IdxRMS)) {
                        if (!this.in_array(FNC[k].id, mczFancoilsIdx)) {
                            mczFancoilsIdx.push(FNC[k].id);
                        }
                    }
                }
            }
            //console.log(`mcz ${mcz.id} array is`)
            //console.log(mczFancoilsIdx);

            for (let k = 0; k < mczFancoilsIdx.length; k++) {
                mczFancoils.push(FNC[mczFancoilsIdx[k]]);
            }
            return [...mczFancoils];

        }

    }

    public groupHasRoomSensors(grpId: number, RMS:RMS[]){
      let foundRMSs = _.filter(RMS,rms => rms.CFG_IdxGRP == grpId);
      return foundRMSs.length > 0;
    }

    public availFNCWithElectricHeater(grpId:number,rmsId:number, isHC:boolean, GRP:GRP[],FNC:FNC[]){
        let seasonExe = GRP[grpId].RTU_SeasonExe;
        let fanCoils = _.filter(FNC, fnc => _.includes(fnc.CFG_IdxRMS,rmsId));
        let foundElectricHeater = false;
        for( let _fnc of fanCoils){
          foundElectricHeater = foundElectricHeater || _fnc.CFG_CfgDoElectricHeat != 0;
        }
        return foundElectricHeater && seasonExe == 1 && isHC;
    }

    public canChangeSeason(grpId: number, GRP: GRP[], HYS: HYS[], TNK: TNK[], FNC: FNC[], TRG?: TRG[]): boolean {

        // Init "canChange" with the presence of the seasonal dvv, so if it's present, the group can change season
        let canChange = GRP[grpId].CFG_IdxDVV_Exe != -1;

        if (TRG) {

            const grpSearch = 'GRP_' + grpId.toString().padStart(3, '0') + '_SeasonExe';
            const grpFound: TRG[] = _.filter(TRG, trg => trg.CFG_Expr.includes(grpSearch));

            if (grpFound.length > 0) {

                canChange = true;
            }
        }

        const tnkGrp: TNK[] = _.filter(TNK, tnk => tnk.CFG_IdxGRP == grpId);

        for (let i = 0; i < tnkGrp.length; i++) {

            canChange = canChange || tnkGrp[i].CFG_CanHC == 3;
        }

        // 2 Pipe
        const hysGrpNoTnk: HYS[] = _.filter(HYS, hys => hys.CFG_IdxGRP == grpId && hys.CFG_Is2P && (hys.CFG_Type == 0 || hys.CFG_Type == 1 || hys.CFG_Type == 3));

        if (hysGrpNoTnk.length > 0) {

            canChange = canChange || GRP[grpId].CFG_SeasonMode == 1;
        }

        // 4 Pipe
        const hysGrp4Pipe: HYS[] = _.filter(HYS, hys => hys.CFG_IdxGRP === grpId && !hys.CFG_Is2P);

        if (hysGrp4Pipe.length > 0) {

            canChange = canChange || GRP[grpId].CFG_SeasonMode == 1;
        }

        // fan coil senza hys
        const fncNoHys: FNC[] = _.filter(FNC, fnc => {

            return ((fnc.CFG_IdxGRP == grpId) && (fnc.CFG_IdxHYS_H == -1) && (fnc.CFG_IdxHYS_C == -1))
        });

        for (const fnc of fncNoHys) {

            const modeBitArray = new BitArray(25).fromNumber(fnc.PAR_ModeFC);
            const heatingMode = modeBitArray.get(0) || modeBitArray.get(2);
            const coolingMode = modeBitArray.get(1) || modeBitArray.get(3);

            if (heatingMode && coolingMode) {

                canChange = true;
            }
        }

        return canChange;
    }


    public is4Pipe(grpId: number, HYS: HYS[]) {

        let hysGrp = _.filter(HYS, h => h.CFG_IdxGRP == grpId);

        for (let idxHYS = 0; idxHYS < hysGrp.length; idxHYS++) {
            let hys = hysGrp[idxHYS];
            if (hys.CFG_Is2P)
                return false;

        }
        return hysGrp.length != 0;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // custom properties of "onChange" of the counter. There must be a minimum gap between "setpoint-heating" and "setpoint-cooling" values of these counters
    // I make sure to automatically respect these constraints by automatically (for example) increase the PAR_SetTempC if PAR_SetTempH has been increased too much relative to it
    // -----------------------------------------------------------------------------------------------------------------

    public onChangeDualSetpoints = (MCU: MCU, table: string, id: number, property: string, value: any, rmsOrMcz: RMS_Extended | MCZ, isAutoModeStyle) => {

        if (isAutoModeStyle) {

            const minDifference = MCU.PAR_UM === 0 ? 2 : 1; // fahrenheit or celsius
            this.store.dispatch(new ChangeTableValues(table, id, {[property]: value}));

            switch (property) {

                case 'PAR_SetTempH':

                    if (value > rmsOrMcz.PAR_SetTempC - minDifference) {

                        const values = {PAR_SetTempC: value + minDifference};
                        this.store.dispatch(new ChangeTableValues(table, id, values));
                        return true;
                    }

                    break;

                case 'PAR_SetTempC':

                    if (value < rmsOrMcz.PAR_SetTempH + minDifference) {

                        const values = {PAR_SetTempH: value - minDifference};
                        this.store.dispatch(new ChangeTableValues(table, id, values));
                        return true;
                    }

                    break;
            }
        }

        else {

            this.store.dispatch(new ChangeTableValues(table, id, {[property]: value}));
        }
    }

    isAutoModeStyleDualSetpointFromRms(gGrp: GRP[], rms: RMS) {

        return gGrp[rms.CFG_IdxGRP]?.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT && gGrp[rms.CFG_IdxGRP]?.PAR_Season != 0 && gGrp[rms.CFG_IdxGRP]?.PAR_Season != 1 && gGrp[rms.CFG_IdxGRP]?.PAR_Season != -1;
    }

    isAutoModeStyleDualSetpointFromMcz(gGrp: GRP[], mcz: MCZ) {

        return gGrp[mcz?.CFG_IdxGRP]?.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT && gGrp[mcz?.CFG_IdxGRP]?.PAR_Season != 0 && gGrp[mcz?.CFG_IdxGRP]?.PAR_Season != 1 && gGrp[mcz?.CFG_IdxGRP]?.PAR_Season != -1;
    }

  refresh(config:Config){

    return this.httpStatusObservable.pipe(first(), switchMap((status: HttpStatus) => {

      const currentUser = config.currentUser;
      let body = {"username": currentUser.username.toLowerCase().trim()}
      let url = config.portalUrl + "/portal/refresh";
      let _headers = new HttpHeaders({'Content-Type': 'application/json'});

      let duration = 5000;
      // console.log(`%cRefresh supportService.superHttpPost mod ${status.modality}, url ${url} body`,'font-size:1.2rem;color:gold;')
      // console.log(body)

      return this.superHttpPost(status.modality, url, body, {
        headers: _headers,
        withCredentials: false
      }).pipe(

        //timeout(duration)
      )
    }));


}

}
