import { Injectable } from '@angular/core';
import {MenuAction} from "../components/abstract-base-component";
import {AlertController, ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private toast;

  constructor(private alertController: AlertController, private toastCtrl: ToastController, private translate: TranslateService) {

  }

  // -------------------------------------------------------------------------------------------------------------------

  async showCustomButtonsWarning(title: string, message: string, actions: MenuAction[]) {

    let buttons = [];
    let defaultAction: MenuAction | null = null;

    for (let action of actions) {

      if (action.disabled != true) {

        buttons.push({
          text: this.translate.instant(action.text),
          handler: () => {
            let navTransition = alert.dismiss();
            navTransition.then(() => {
              action.handler();
            });
          },
          icon: action.icon,
          role: action.role,
          cssClass: action["cssClass"]
        });

        // Trova l'azione predefinita (contiene 'ok' o 'yes')
        if (!defaultAction && action.text.toLowerCase().match(/ok|yes/)) {
          defaultAction = action;
        }

      }

    }

    const alert = await this.alertController.create({
      cssClass: 'alert-yes-no',
      header: this.translate.instant(title),
      message: this.translate.instant(message),
      buttons: buttons,
      backdropDismiss: false
    });

    // Gestisci l'evento keydown
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && defaultAction) {
        event.preventDefault();
        defaultAction.handler();
        alert.dismiss();
      }
    };


    // Aggiungi l'event listener prima di presentare l'alert
    document.addEventListener('keydown', handleKeydown);

    console.warn('displaying popup');
    await alert.present();

    // Attendi la chiusura dell'alert
    await alert.onDidDismiss();

    // Rimuovi l'event listener dopo la chiusura dell'alert
    document.removeEventListener('keydown', handleKeydown);

  }

  // -------------------------------------------------------------------------------------------------------------------

  async presentToast(toastMessage: string, time = 2000) {

    if (this.toast) {

      this.toast.dismiss();
    }

    this.toast = await this.toastCtrl.create({
      message: this.translate.instant(toastMessage),
      position: 'bottom',
      duration: time,
    });

    await this.toast.present();
  }

  async showErrorMessage(err,title?){
    console.log(`showErrorMessage`);
    const alert = await this.alertController.create({
      cssClass: 'alert-yes-no',
      header: (title ? this.translate.instant(title) : this.translate.instant('AUTH_ERROR_TITLE')),
      //message: this.translate.instant('LOGIN_ERROR_SUBTITLE'),
      message:this.translate.instant(err.error.message),
      buttons: ['OK']
    });
    await alert.present();
  }
}
