<ion-item class="no-lines padding-left-aac-weight">
    <ion-label [innerHTML]="seasonPage.translate.instant('ZONE_LIST_WEIGHTS_DESCRIPTION')" class="label-radio fontSizeSubtitle text-justify"></ion-label>
</ion-item>

<div class="rounded-container rounded-container-aac">
    <ng-container *ngFor="let rms of filteredRMS; let firstCounter = first; let lastCounter = last;">

        <div *ngIf="firstCounter" class="emptyMarginTop"></div>

        <counter [label]="rms.CFG_Name" table="RMS" [item]="rms" var="TEC_AacWeight" [attrs]="seasonPage.attrs" [inputField]="true" [MCU]="MCU" [whileChange]="seasonPage.onChange" itemClass="no-lines counter-ion-item-aac"></counter>

        <div *ngIf="lastCounter" class="emptyMarginBottom"></div>

    </ng-container>
</div>
